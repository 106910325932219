import create from "zustand";
import createVanilla from "zustand/vanilla";

interface DockStateState {
  dockState: Record<string, DockState | null>;
  setDockState: (dockId: string, state: DockState | null) => void;
}

export const dockStateStore = createVanilla<DockStateState>(
  (set, getState) => ({
    dockState: {},
    setDockState: (dockId, state) => {
      set({
        dockState: { ...getState().dockState, [dockId]: state },
      });
    },
  })
);
export const useDockStateStore = create(dockStateStore);
