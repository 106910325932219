import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  Text,
  Link as ChakraLink,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import HoverLink from "../../components/HoverLink";
import Link from "../../components/Link";
import AuthChangeRedirector from "../../components/AuthChangeRedirector";
import MqttController from "./MqttController";
import { useOrgStore } from "../../state/organizations";
import { useUserStore } from "../../state/user";

export default function Main() {
  const {
    isOpen: showMenu,
    onOpen: openMenu,
    onClose: closeMenu,
  } = useDisclosure();
  const fetchOrg = useOrgStore((state) => state.fetchOrg);

  const userAttributes = useUserStore((state) => state.attributes);

  useEffect(() => {
    // Load organization
    fetchOrg();
  }, []);

  return (
    <>
      <AuthChangeRedirector />
      <MqttController />
      <Drawer isOpen={showMenu} placement={"left"} onClose={closeMenu}>
        <DrawerOverlay />
        <DrawerContent bg={"steel.800"}>
          <DrawerCloseButton />
          <DrawerHeader>Fliteworks Manage</DrawerHeader>
          <DrawerBody p={0}>
            <HoverLink to={"/map"} onClick={closeMenu}>
              Map
            </HoverLink>
            <HoverLink to={"/account"} onClick={closeMenu}>
              Account
            </HoverLink>
          </DrawerBody>
          <DrawerFooter justifyContent={"center"}>
            <Text fontSize={"xs"}>
              Copyright &copy; 2023{" "}
              <ChakraLink href={"https://fliteworks.com"}>
                Fliteworks
              </ChakraLink>
            </Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Flex boxShadow={"lg"} zIndex={999}>
        <Center p={2}>
          <IconButton
            aria-label={"Open Navigation Menu"}
            onClick={openMenu}
            icon={<HamburgerIcon />}
            variant={"ghost"}
          />
        </Center>
        <Center p={2}>
          <Link to={"/"} reloadDocument>
            <Image h={4} src={"/assets/fliteworks_text_dark.svg"} />
          </Link>
        </Center>
        <Box flex={1} />
        {/*<HoverLink to={"/support"} buttonProps={{ p: 4, h: "full" }}>*/}
        {/*  Support*/}
        {/*</HoverLink>*/}
        <Center>
          <Text>
            Hello, <b>{userAttributes.email}</b>
          </Text>
        </Center>
        <HoverLink to={"/logout"} buttonProps={{ p: 4, h: "full" }}>
          Log Out
        </HoverLink>
      </Flex>
      <Flex flex={1} overflow={"hidden"} zIndex={0}>
        <Outlet />
      </Flex>
    </>
  );
}
