import create from "zustand";
import { createTableSyncStore } from "./common";

interface DocksFilter {
  parentSite?: string;
}

interface DocksState {
  docks: Record<string, Dock> | null;
  setDocks: (docks: Dock[]) => void;
  updateDocks: (docks: Dock[]) => void;
  fetchDocks: (filter?: DocksFilter, update?: boolean) => Promise<void>;
  fetchDock: (dockId: string) => Promise<void>;
}

function customSingleFetchResultMutator(result: any) {
  const dock = result.dock;
  if (dock) {
    dock.streamUrl = result.streamUrl;
  }
  console.log(dock);
  return dock;
}

export const docksStore = createTableSyncStore<DocksState>(
  "dock",
  true,
  "dock",
  undefined,
  customSingleFetchResultMutator
);
export const useDocksStore = create(docksStore);
