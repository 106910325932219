import {
  Box,
  Button,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTaskInstancesStore } from "../../../../state/taskInstances";
import { useDataObjectsStore } from "../../../../state/dataObjects";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { useMissionsStore } from "../../../../state/missions";
import { timestampToReadableString } from "../../../../common/helpers";

export default function TaskInstancePanel() {
  const params = useParams();
  const siteId = params.siteId;
  const taskId = params.taskId;
  const taskInstanceId = params.taskInstanceId;
  const shortcuts = useNavigationShortcuts();

  const taskInstances = useTaskInstancesStore((state) => state.taskInstances);
  const [missions, fetchMission] = useMissionsStore((state) => [
    state.missions,
    state.fetchMission,
  ]);
  const [dataObjects, fetchDataObjects] = useDataObjectsStore((state) => [
    state.dataObjects,
    state.fetchDataObjects,
  ]);

  const [missionIds, setMissionIds] = useState<string[]>([]);

  useEffect(() => {
    if (taskInstanceId && taskInstances?.[taskInstanceId]) {
      setMissionIds(taskInstances[taskInstanceId].componentMissions || []);
    }
  }, [taskInstanceId]);

  useEffect(() => {
    for (let missionId of missionIds) {
      fetchMission(missionId);
      fetchDataObjects({ parentMission: missionId }, true);
    }
  }, [missionIds]);

  return (
    <Tabs
      as={Flex}
      colorScheme={"white"}
      variant={"enclosed"}
      flexDirection={"column"}
      overflow={"hidden"}
      height={"full"}
    >
      <TabList>
        <Tab>Info</Tab>
        <Flex flex={1} justifyContent={"flex-end"} alignItems={"center"}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => shortcuts.selectSite(siteId, "missions", true)}
            mr={1}
          >
            <ChevronLeftIcon /> Back
          </Button>
        </Flex>
      </TabList>
      <TabPanels overflow={"auto"} height={"full"} pb={12}>
        <TabPanel>
          <Box m={4}>
            <Heading as={"h1"} fontSize={"xl"} mb={4}>
              Task Instance: {params.taskInstanceId}
            </Heading>
            {taskInstanceId && taskInstances?.[taskInstanceId] && (
              <>
                <Box>
                  <Text>
                    <b>Task Instance Status:</b>
                  </Text>
                  <Box p={4}>
                    <Text>
                      {taskInstances[taskInstanceId].taskInstanceStatus}
                    </Text>
                  </Box>
                  <Text fontSize={"sm"} ml={4}>
                    Updated{" "}
                    {new Date(
                      taskInstances[taskInstanceId].statusTimestamp
                    ).toLocaleString()}
                  </Text>
                </Box>
                <Box my={4}>
                  <Text>
                    <b>Missions:</b>
                  </Text>
                  <Box
                    maxH={200}
                    overflowY={"auto"}
                    my={4}
                    borderWidth={1}
                    borderColor={"whiteAlpha.500"}
                  >
                    {missionIds.map((missionId) => (
                      <Text
                        fontSize={"sm"}
                        _hover={{ backgroundColor: "whiteAlpha.300" }}
                        cursor={"pointer"}
                        onClick={() =>
                          shortcuts.selectMission(siteId, missionId)
                        }
                        p={1}
                      >
                        {missions?.[missionId]?.startTime
                          ? timestampToReadableString(
                              missions?.[missionId]?.startTime || 0
                            )
                          : missionId}
                      </Text>
                    ))}
                  </Box>
                </Box>
                <Box my={4}>
                  <Text>
                    <b>Data Objects:</b>
                  </Text>
                  <Box
                    maxH={200}
                    overflowY={"auto"}
                    my={4}
                    borderWidth={1}
                    borderColor={"whiteAlpha.500"}
                  >
                    {dataObjects &&
                      Object.values(dataObjects)
                        .filter((dataObject) =>
                          missionIds.includes(dataObject.parentMission)
                        )
                        .map((dataObject) => (
                          <Text
                            fontSize={"sm"}
                            _hover={{ backgroundColor: "whiteAlpha.300" }}
                            cursor={"pointer"}
                            onClick={() =>
                              shortcuts.selectDataObject(
                                siteId,
                                dataObject.parentMission,
                                dataObject.dataObjectId
                              )
                            }
                            p={1}
                          >
                            {dataObject.uri.split("/").slice(-2).join("/")}
                          </Text>
                        ))}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
