import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

interface MapErrorBoundaryProps {
  children: ReactJSXElement | ReactJSXElement[];
}

export function MapErrorBoundary(props: MapErrorBoundaryProps) {
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <pre>Failed to load map: {error.stack}</pre>
      )}
    >
      {props.children}
    </ErrorBoundary>
  );
}
