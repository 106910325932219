import React, { useEffect, useState } from "react";
import GeoJSON from "geojson";
import { Layer, Source } from "react-map-gl";
import WaypointsVisualizationLayer from "./WaypointsVisualizationLayer";

type NativeMissionEntry = [[number, number] | undefined, any];

interface ExecuteNativeMissionVisualizationLayerProps {
  mission: Mission;
  previousLocation?: [number, number];
}

export default function ExecuteNativeMissionVisualizationLayer(
  props: ExecuteNativeMissionVisualizationLayerProps
) {
  const mission = props.mission;
  const [nativeMissions, setNativeMissions] = useState<NativeMissionEntry[]>(
    []
  );

  useEffect(() => {
    if (mission?.missionPlan) {
      try {
        const missionPlan = JSON.parse(mission.missionPlan);
        let previousLocation: [number, number] | undefined =
          props.previousLocation;
        let newNativeMissions: NativeMissionEntry[] = [];

        for (let c of missionPlan.flight) {
          switch (c.command) {
            case "WAYPOINT":
              previousLocation = [c.coordinates.lng, c.coordinates.lat];
              break;
            case "FOLLOW_ROW":
              previousLocation = [c.finishPoint.lng, c.finishPoint.lat];
              break;
            case "EXECUTE_NATIVE_MISSION":
              const nativeMission = c.missionPlan;
              let lastCoordinate = previousLocation;
              for (let nc of nativeMission.flight) {
                if (nc.command === "WAYPOINT")
                  lastCoordinate = [nc.coordinates.lng, nc.coordinates.lat];
              }
              newNativeMissions.push([
                previousLocation,
                { missionPlan: JSON.stringify(nativeMission) },
              ]);
              break;
          }
        }

        setNativeMissions(newNativeMissions);
      } catch (e: any) {
        // Do nothing
      }
    } else {
      setNativeMissions([]);
    }
  }, [mission]);

  return (
    <>
      {nativeMissions.map((nm, i) => (
        <WaypointsVisualizationLayer
          key={i}
          mission={nm[1]}
          previousLocation={nm[0]}
        />
      ))}
    </>
  );
}
