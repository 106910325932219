import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import { Box, Flex, Icon, IconButton, useDisclosure } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { AssetBreadcrumb } from "./AssetBreadcrumb";
import { FaSolarPanel } from "react-icons/fa";
import Link from "../../../components/Link";

interface AssetPanelProps {
  showPanel: boolean;
  togglePanel: () => void;
}

export default function MapPanel(props: AssetPanelProps) {
  const panelRef = useRef<HTMLDivElement>(null);

  return (
    <Flex
      direction={"column"}
      ref={panelRef}
      w={"sm"}
      background={"steel.800"}
      boxShadow={"md"}
      zIndex={99}
      position={"relative"}
      mr={props.showPanel ? 0 : -(panelRef.current?.clientWidth || 0)}
      // transition={"all .5s ease"} // TODO: This is nice, but doesn't play well with mapbox resize due to animation delay.
    >
      <IconButton
        colorScheme={"panelToggleButton"}
        onClick={props.togglePanel}
        aria-label={`${props.showPanel ? "Close" : "Open"} Asset Command Panel`}
        icon={props.showPanel ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        position={"absolute"}
        left={-2}
        bottom={2}
        transform={"translateX(-100%)"}
        boxShadow={"lg"}
        _hover={{ boxShadow: "dark-lg" }}
      />

      <Flex
        w={"full"}
        overflow={"hidden"}
        background={"steel.900"}
        boxShadow={"md"}
        alignItems={"center"}
        p={2}
      >
        <Link to={"/map"}>
          <IconButton
            aria-label={"Go to Site Selection"}
            icon={<Icon as={FaSolarPanel} />}
            variant={"ghost"}
          />
        </Link>
        <ChevronRightIcon />
        <AssetBreadcrumb />
      </Flex>

      <Box overflow={"hidden"} flex={1}>
        <Outlet />
      </Box>
    </Flex>
  );
}
