import { extendTheme, StyleFunctionProps } from "@chakra-ui/react";
import { inputTheme } from "./Input";
import { buttonTheme } from "./Button";

export const FliteworksTheme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  colors: {
    silicon: {
      500: "#2637ec",
      600: "#1829d9",
      900: "#020228",
    },
    steel: {
      800: "#262629",
      900: "#19191e",
    },
    panelToggleButton: {
      600: "#19191e", // Same as steel.900
    },
    dangerAlpha: {
      100: "rgba(255, 0, 0, 0.1)",
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      "html, body": {
        margin: 0,
        padding: 0,
      },
      body: {
        backgroundColor: "silicon.900",
      },
    }),
  },
  components: {
    Input: inputTheme,
    Button: buttonTheme,
  },
});
