import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import EditSchedule from "../../../../../components/forms/EditSchedule";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { CONSTANTS } from "../../../../../common/constants";
import { useNavigationShortcuts } from "../../../../../common/navigation";
import { useParams } from "react-router-dom";
import { useTasksStore } from "../../../../../state/tasks";
import { useMapFeatureSelectStore } from "../../../../../state/mapFeatureSelect";
import GridScanForm from "./forms/GridScanForm";
import RaptormapsForm from "./forms/RaptormapsForm";
import { TASK_TYPE_LOOKUP } from "./SelectTaskType";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import SimpleTestForm from "./forms/SimpleTestForm";
import SpotCheckForm from "./forms/SpotCheckForm";

interface TaskFormProps {
  taskType: string;
  setTaskType: (newTaskType: string) => void;
}

export default function TaskForm(props: TaskFormProps) {
  const toast = useToast();
  const shortcuts = useNavigationShortcuts();
  const params = useParams();
  const siteId = params.siteId;

  const fetchTask = useTasksStore((state) => state.fetchTask);
  const endSelectingFeatures = useMapFeatureSelectStore(
    (state) => state.endSelectingFeatures
  );

  const [taskConfig, setTaskConfig] = useState<Record<string, string>>({});
  const [schedule, setTaskSchedule] = useState<Schedule>({
    scheduleType: "ONE_OFF",
    startTime: Math.round(new Date().setSeconds(0) / 1000 + 120), // 2 minutes into the future
  });
  const [working, setWorking] = useState(false);

  async function createTask() {
    setWorking(true);
    endSelectingFeatures();
    try {
      const response = await API.put(CONSTANTS.API_NAME, "/task", {
        body: {
          task: {
            parentSite: siteId,
            taskType: props.taskType,
            taskConfig: taskConfig,
            schedule: schedule,
          },
        },
      });
      console.log(response);
      await fetchTask(response.taskId);
      toast({
        description: "Task created.",
        status: "success",
        duration: 2000,
      });
      // shortcuts.selectTask(siteId, response.taskId);
      shortcuts.selectSite(siteId, "missions");
    } catch (e) {
      console.error(e);
    }
    setWorking(false);
  }

  return (
    <>
      <Flex px={4} pt={4}>
        <Heading as={"h1"} fontSize={"xl"} mb={4} flex={1}>
          Create {TASK_TYPE_LOOKUP.get(props.taskType)?.name} Task
        </Heading>
        <Button
          size={"sm"}
          variant={"outline"}
          onClick={() => props.setTaskType("")}
        >
          <ChevronLeftIcon /> Back
        </Button>
      </Flex>
      <Box px={8} pb={12} overflow={"auto"}>
        {props.taskType === "GRID_SCAN" ? (
          <>
            <GridScanForm
              creating={true}
              taskConfig={taskConfig}
              setTaskConfig={setTaskConfig}
              schedule={schedule}
              setTaskSchedule={setTaskSchedule}
              siteId={siteId || ""}
            />
          </>
        ) : props.taskType == "RAPTORMAPS_STANDARD" ? (
          <RaptormapsForm
            siteId={siteId || ""}
            taskConfig={taskConfig}
            setTaskConfig={setTaskConfig}
            schedule={schedule}
            setTaskSchedule={setTaskSchedule}
            creating={true}
          />
        ) : props.taskType === "SIMPLE_TEST" ? (
          <SimpleTestForm
            schedule={schedule}
            setTaskSchedule={setTaskSchedule}
            taskConfig={taskConfig}
            setTaskConfig={setTaskConfig}
            creating={true}
          />
        ) : (
          props.taskType === "SPOT_CHECK" && (
            <SpotCheckForm
              siteId={siteId || ""}
              schedule={schedule}
              setTaskSchedule={setTaskSchedule}
              taskConfig={taskConfig}
              setTaskConfig={setTaskConfig}
              creating={true}
            />
          )
        )}

        <Divider my={4} />

        {working ? (
          <Spinner />
        ) : (
          <Box my={4}>
            <Button colorScheme={"silicon"} onClick={createTask}>
              Create Task
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}
