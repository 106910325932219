import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { API } from "aws-amplify";
import shallow from "zustand/shallow";
import { CONSTANTS } from "../../../../common/constants";
import { useSitesStore } from "../../../../state/sites";
import { useNavigationShortcuts } from "../../../../common/navigation";
import Editable from "../../../../components/forms/Editable";
import EditFeatures from "../../../../components/forms/EditFeatures";

export default function EditSite() {
  const toast = useToast();
  const shortcuts = useNavigationShortcuts();
  const params = useParams();
  const siteId = params.siteId;

  const [sites, fetchSite] = useSitesStore(
    (state) => [state.sites, state.fetchSite],
    shallow
  );

  const [working, setWorking] = useState(false);
  const [drawWorking, setDrawWorking] = useState(false);
  const {
    isOpen: alertOpen,
    onOpen: openAlert,
    onClose: closeAlert,
  } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement>(null);

  function updateSiteProperty(property: string): (value: any) => void {
    return async (value: any) => {
      if (!siteId) return;
      if (value === sites?.[siteId]?.[property as keyof Site]) return;
      try {
        await API.patch(CONSTANTS.API_NAME, `/site/${siteId}`, {
          body: { site: { [property]: value } },
        });
        await fetchSite(siteId);
        toast({
          description: "Updated site.",
          status: "success",
          duration: 2000,
        });
      } catch (e) {
        toast({
          title: "Failed to update site.",
          description: `${e}`,
          status: "error",
          duration: 2000,
        });
      }
    };
  }

  async function deleteSite() {
    if (!siteId) return;
    setWorking(true);
    try {
      await API.del(CONSTANTS.API_NAME, `/site/${siteId}`, {});
      await fetchSite(siteId);
      toast({
        description: "Site deleted.",
        status: "success",
        duration: 2000,
      });
      shortcuts.clearSelection();
    } catch (e) {
      toast({
        title: "Failed to delete site.",
        description: `${e}`,
        status: "error",
        duration: 2000,
      });
    }
    setWorking(false);
  }

  async function planMissions() {
    if (!siteId) return;
    await API.post(CONSTANTS.API_NAME, `/site/${siteId}/plan_missions`, {
      body: {},
    });
    toast({
      title: "Triggered Mission Planning",
      description: `For Site: ${
        (siteId && sites?.[siteId]?.siteName) || siteId
      }`,
      status: "success",
      duration: 2000,
    });
  }

  return (
    <Box m={4}>
      <Box my={4}>
        <Editable
          value={siteId || ""}
          label={"Site ID"}
          disabled={true}
          selectAll={true}
        />
      </Box>
      <Box my={4}>
        <Editable
          value={siteId && sites?.[siteId]?.siteName}
          onSubmit={updateSiteProperty("siteName")}
          label={"Site Name"}
        />
      </Box>
      <Box my={4}>
        <Editable
          value={siteId && sites?.[siteId]?.address}
          onSubmit={updateSiteProperty("address")}
          label={"Address"}
        />
      </Box>
      <Box my={4}>
        <Editable
          value={String(siteId && sites?.[siteId]?.panelTilt)}
          display={(v) => `${v || ""}\u00b0`}
          onSubmit={(v) => updateSiteProperty("panelTilt")(parseFloat(v))}
          label={"Panel Tilt (from horizontal)"}
          inputProps={{ type: "number" }}
        />
      </Box>
      <Box my={4}>
        <EditFeatures
          label={"Flight Boundary"}
          features={siteId && sites?.[siteId]?.flightBounds}
          onStartDraw={() => shortcuts.selectSite(siteId, "settings")}
          onSubmit={updateSiteProperty("flightBounds")}
          onWorking={setDrawWorking}
          disabled={working}
        />
      </Box>
      <Box my={4}>
        <EditFeatures
          label={"Scan Areas"}
          features={siteId && sites?.[siteId]?.scanAreas}
          featureType={"SCAN_AREA"}
          onStartDraw={() => shortcuts.selectSite(siteId, "settings")}
          onSubmit={updateSiteProperty("scanAreas")}
          onWorking={setDrawWorking}
          disabled={working}
        />
      </Box>
      <Box my={4}>
        <EditFeatures
          label={"Obstacles & No-Go Zones"}
          features={siteId && sites?.[siteId]?.obstacles}
          featureType={"OBSTACLE"}
          onStartDraw={() => shortcuts.selectSite(siteId, "settings")}
          onSubmit={updateSiteProperty("obstacles")}
          onWorking={setDrawWorking}
          disabled={working}
        />
      </Box>

      <Box background={"silicon.900"} borderRadius={"md"} p={4} my={4}>
        <Heading as={"h2"} fontSize={"lg"} mb={4}>
          Site Actions
        </Heading>
        <Box my={4}>
          <Button colorScheme={"silicon"} onClick={planMissions}>
            Force Trigger Mission Planner
          </Button>
        </Box>
      </Box>

      <Box background={"dangerAlpha.100"} borderRadius={"md"} p={4} my={4}>
        <Heading as={"h2"} fontSize={"lg"} mb={4}>
          Danger Zone
        </Heading>
        {working ? (
          <Spinner />
        ) : (
          <Flex>
            <Button
              colorScheme={"red"}
              onClick={openAlert}
              disabled={drawWorking}
            >
              Permanently Delete Site
            </Button>
          </Flex>
        )}
      </Box>

      <AlertDialog
        isOpen={alertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent background={"steel.800"}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Permanently Delete Site
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeAlert}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  closeAlert();
                  deleteSite();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
