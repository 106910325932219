import { Box, Checkbox, Collapse, Icon, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SelectFeatures from "../../../../../../components/forms/SelectFeatures";
import { useSitesStore } from "../../../../../../state/sites";
import Editable from "../../../../../../components/forms/Editable";
import GridScanForm from "./GridScanForm";
import { useOrgStore } from "../../../../../../state/organizations";
import EditSchedule from "../../../../../../components/forms/EditSchedule";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";

interface RaptormapsFormProps {
  siteId: string;
  taskConfig: Record<string, string> | null;
  setTaskConfig: (newTaskConfig: Record<string, string>) => void;
  schedule: Schedule;
  setTaskSchedule: (newSchedule: Schedule) => void;
  creating?: boolean;
}

export default function RaptormapsForm(props: RaptormapsFormProps) {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { org } = useOrgStore();
  const { sites } = useSitesStore();

  function saveConfigProperty(property: string): (value: string) => void {
    return (value) => {
      props.setTaskConfig({ ...props.taskConfig, [property]: value });
    };
  }

  function saveConfigFlag(property: string): (e: any) => void {
    return (e) => {
      if (e.target.checked) {
        props.setTaskConfig({
          ...props.taskConfig,
          [property]: "true",
        });
      } else {
        const newTaskConfig = { ...props.taskConfig };
        delete newTaskConfig[property];
        props.setTaskConfig(newTaskConfig);
      }
    };
  }

  useEffect(() => {
    // Set initial task config
    if (props.creating) {
      props.setTaskConfig({
        // Per raptormaps standard
        frontOverlap: "0.7",
        sideOverlap: "0.2",
        scanHeading: "0",
        scanAltitude: "41",
        scanSpeed: "5",
        transitSpeed: "15", // 33mph
        maxMissionDuration: "1200",
        panelTilt: sites?.[props.siteId]?.panelTilt.toString() || "0",
        raptormapsApiKey: org?.orgConfig?.["raptormapsApiKey"] || "",
        raptormapsOrgId: org?.orgConfig?.["raptormapsOrgId"] || "",
        raptormapsOrderId: "",
        raptormapsPipeline: "om",
        raptormapsSiteName: sites?.[props.siteId]?.siteName || "",
      });
    }
  }, []);

  return (
    <>
      <Box my={4}>
        <SelectFeatures
          label={"Target Area"}
          features={sites?.[props.siteId]?.flightBounds || ""}
          selectedFeatureIds={props.taskConfig?.targetFeatureIds}
          onSubmit={saveConfigProperty("targetFeatureIds")}
        />
      </Box>
      <Box my={4}>
        <Editable
          label={"RaptorMaps Order ID"}
          value={props.taskConfig?.raptormapsOrderId || ""}
          type={"input"}
          onSubmit={saveConfigProperty("raptormapsOrderId")}
        />
      </Box>
      <Box my={4}>
        <EditSchedule value={props.schedule} onSubmit={props.setTaskSchedule} />
      </Box>

      <Text onClick={() => setShowAdvanced(!showAdvanced)} cursor={"pointer"}>
        <Icon
          as={showAdvanced ? FaCaretDown : FaCaretRight}
          verticalAlign={"middle"}
        />{" "}
        <b>Advanced</b>
      </Text>
      <Collapse in={showAdvanced}>
        <Box my={4}>
          <Editable
            label={"Panel Tilt"}
            value={props.taskConfig?.panelTilt || ""}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("panelTilt")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"RaptorMaps API Key"}
            value={props.taskConfig?.raptormapsApiKey || ""}
            type={"input"}
            onSubmit={saveConfigProperty("raptormapsApiKey")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"RaptorMaps Org ID"}
            value={props.taskConfig?.raptormapsOrgId || ""}
            type={"input"}
            onSubmit={saveConfigProperty("raptormapsOrgId")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"RaptorMaps Site Name"}
            value={props.taskConfig?.raptormapsSiteName || ""}
            type={"input"}
            onSubmit={saveConfigProperty("raptormapsSiteName")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"RaptorMaps Pipeline"}
            value={props.taskConfig?.raptormapsPipeline || ""}
            type={"input"}
            onSubmit={saveConfigProperty("raptormapsPipeline")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Front Overlap (%)"}
            value={
              (props.taskConfig?.frontOverlap !== undefined &&
                String(parseFloat(props.taskConfig.frontOverlap) * 100)) ||
              "0"
            }
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={(v) =>
              saveConfigProperty("frontOverlap")(String(parseFloat(v) / 100))
            }
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Side Overlap (%)"}
            value={
              (props.taskConfig?.frontOverlap !== undefined &&
                String(parseFloat(props.taskConfig.sideOverlap) * 100)) ||
              "0"
            }
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={(v) =>
              saveConfigProperty("sideOverlap")(String(parseFloat(v) / 100))
            }
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Scan Heading (degrees)"}
            value={props.taskConfig?.scanHeading || "0"}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("scanHeading")}
          />
        </Box>
        {/* TODO: Abstract this away as a function of GSD. */}
        <Box my={4}>
          <Editable
            label={"Scan Altitude (meters)"}
            value={props.taskConfig?.scanAltitude || "0"}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("scanAltitude")}
          />
        </Box>
        {/* TODO: Abstract this away as a function of GSD. */}
        <Box my={4}>
          <Editable
            label={"Scan Speed (m/s)"}
            value={props.taskConfig?.scanSpeed || "0"}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("scanSpeed")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Transit Speed (m/s)"}
            value={props.taskConfig?.transitSpeed || "0"}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("transitSpeed")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Max Mission Duration"}
            value={props.taskConfig?.panelTilt || ""}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("maxMissionDuration")}
          />
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.useSimulation)}
            onChange={saveConfigFlag("useSimulation")}
          >
            Use Simulation
          </Checkbox>
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.skipProcessing)}
            onChange={saveConfigFlag("skipProcessing")}
          >
            Don't Process
          </Checkbox>
        </Box>
      </Collapse>
    </>
  );
}
