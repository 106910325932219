import GeoJSON from "geojson";
import create from "zustand";
import createVanilla from "zustand/vanilla";

interface MapFeatureSelectState {
  isSelectingFeatures: boolean;
  isMultiSelect: boolean;
  features: GeoJSON.FeatureCollection | null;
  selectedFeatureIds: Set<string>;
  setMultiSelect: (isMultiSelect: boolean) => void;
  startSelectingFeatures: () => void;
  endSelectingFeatures: () => void;
  setFeatures: (featureData: GeoJSON.FeatureCollection | null) => void;
  setSelectedFeatureIds: (selectedFeatureIds: Set<string>) => void;
  selectFeature: (featureId: string) => void;
  unselectFeature: (featureId: string) => void;
}

export const mapFeatureSelectStore = createVanilla<MapFeatureSelectState>(
  (set, getState) => ({
    isSelectingFeatures: false,
    isMultiSelect: false,
    features: null,
    selectedFeatureIds: new Set<string>(),
    setMultiSelect: (isMultiSelect) => set({ isMultiSelect }),
    startSelectingFeatures: () => set({ isSelectingFeatures: true }),
    endSelectingFeatures: () =>
      set({
        isSelectingFeatures: false,
        selectedFeatureIds: new Set<string>(),
      }),
    setFeatures: (features) => set({ features }),
    setSelectedFeatureIds: (selectedFeatureIds) => set({ selectedFeatureIds }),
    selectFeature: (featureId) => {
      const selectedFeatureIds = getState().selectedFeatureIds.add(featureId);
      set({ selectedFeatureIds });
    },
    unselectFeature: (featureId) => {
      const selectedFeatureIds = getState().selectedFeatureIds;
      selectedFeatureIds.delete(featureId);
      set({ selectedFeatureIds });
    },
  })
);
export const useMapFeatureSelectStore = create(mapFeatureSelectStore);
