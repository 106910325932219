import { useMissionsStore } from "../../../../state/missions";
import shallow from "zustand/shallow";
import { Box, Button, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { useParams } from "react-router-dom";
import {
  CheckCircleIcon,
  QuestionIcon,
  SpinnerIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { timestampToReadableString } from "../../../../common/helpers";

interface MissionAgendaItemProps {
  siteId: string | undefined;
  mission: Mission;
  hideTopEdge?: boolean;
  hideBottomEdge?: boolean;
}

function MissionAgendaItem(props: MissionAgendaItemProps) {
  const shortcuts = useNavigationShortcuts();
  const useSimulation = props.mission.missionPlan.includes("USE_SIMULATION");
  return (
    <Box
      _hover={{ backgroundColor: "whiteAlpha.300" }}
      cursor={"pointer"}
      onClick={() =>
        shortcuts.selectMission(props.siteId, props.mission.missionId)
      }
      py={2}
      position={"relative"}
    >
      {props.mission.missionStatus === "COMPLETE" ? (
        <CheckCircleIcon
          color={"green"}
          display={"inline-block"}
          mx={2}
          verticalAlign={"middle"}
          zIndex={100}
        />
      ) : props.mission.missionStatus === "PARTIAL" ? (
        <WarningIcon
          color={"orange"}
          display={"inline-block"}
          mx={2}
          verticalAlign={"middle"}
          zIndex={100}
        />
      ) : props.mission.missionStatus === "FAILED" ? (
        <WarningIcon
          color={"red.400"}
          display={"inline-block"}
          mx={2}
          verticalAlign={"middle"}
          zIndex={100}
        />
      ) : props.mission.missionStatus === "SCHEDULED" ? (
        <TimeIcon
          color={"orange"}
          display={"inline-block"}
          mx={2}
          verticalAlign={"middle"}
          zIndex={100}
        />
      ) : [
          "STARTING",
          "PREFLIGHT",
          "INFLIGHT",
          "POSTFLIGHT",
          "PROCESSING",
          "CLEANUP",
        ].includes(props.mission.missionStatus) ? (
        <Spinner
          w={"1em"}
          h={"1em"}
          speed={"1s"}
          emptyColor={"whiteAlpha.400"}
          color={"lightblue"}
          display={"inline-block"}
          mx={2}
          my={0}
          verticalAlign={"middle"}
          zIndex={100}
        />
      ) : (
        <QuestionIcon
          color={"gray"}
          display={"inline-block"}
          mx={2}
          verticalAlign={"middle"}
          zIndex={100}
        />
      )}
      {!props.hideTopEdge && (
        <Box
          borderColor={"whiteAlpha.500"}
          borderRightWidth={1}
          position={"absolute"}
          top={0}
          h={2}
          w={2}
          left={"0.5em"}
          zIndex={-10}
        />
      )}
      {!props.hideBottomEdge && (
        <Box
          borderColor={"whiteAlpha.500"}
          borderRightWidth={1}
          position={"absolute"}
          bottom={0}
          h={2}
          w={2}
          left={"0.5em"}
          zIndex={-10}
        />
      )}
      <Box display={"inline-block"} verticalAlign={"middle"}>
        <Text>
          {timestampToReadableString(props.mission.startTime)}
          {useSimulation && (
            <>
              {" "}
              <b>[sim]</b>
            </>
          )}
        </Text>
      </Box>
    </Box>
  );
}

export default function MissionAgenda() {
  const params = useParams();
  const siteId = params.siteId;
  const nowSeconds = new Date().getTime() / 1000;
  const [missions, fetchMissions] = useMissionsStore(
    (state) => [state.missions, state.fetchMissions],
    shallow
  );
  const [showPageBackSpinner, setShowPageBackSpinner] = useState(false);
  const [showPageForwardSpinner, setShowPageForwardSpinner] = useState(false);
  const [lastBackPage, setLastBackPage] = useState<number | null>(null);
  const [lastForwardPage, setLastForwardPage] = useState<number | null>(null);

  const pastMissions =
    missions &&
    Object.values(missions)
      .filter(
        (mission) =>
          mission &&
          mission.parentSite === siteId &&
          mission.startTime < nowSeconds &&
          ["COMPLETE", "PARTIAL", "FAILED"].includes(mission.missionStatus)
      )
      .sort((a, b) => (a && b ? a.startTime - b.startTime : 0));
  const currentMissions =
    missions &&
    Object.values(missions)
      .filter(
        (mission) =>
          mission &&
          mission.parentSite === siteId &&
          mission.startTime < nowSeconds &&
          !["COMPLETE", "PARTIAL", "FAILED"].includes(mission.missionStatus)
      )
      .sort((a, b) => (a && b ? a.startTime - b.startTime : 0));
  const upcomingMissions =
    missions &&
    Object.values(missions)
      .filter(
        (mission) =>
          mission &&
          mission.parentSite === siteId &&
          mission.startTime >= nowSeconds
      )
      .sort((a, b) => (a && b ? a.startTime - b.startTime : 0));
  const scroller = useRef<HTMLDivElement>(null);
  const divider = useRef<HTMLParagraphElement>(null);

  // Scroll to in-progress/upcoming missions
  // useLayoutEffect(() => {
  //   if (divider.current && scroller.current) {
  //     scroller.current.scrollTop = divider.current.offsetTop - 100;
  //   }
  // }, [divider.current, scroller.current]);

  // Scroll to bottom
  useLayoutEffect(() => {
    if (scroller.current) {
      scroller.current.scrollTop = scroller.current.scrollHeight;
    }
  });

  function pageBack() {
    setShowPageBackSpinner(true);
    const pageStart = lastBackPage ?? Math.round(Date.now() / 1000);
    const pageEnd = pageStart - 604_800; // Page back 1 week
    fetchMissions({ before: pageStart, after: pageEnd }).finally(() =>
      setShowPageBackSpinner(false)
    );
    setLastBackPage(pageEnd);
  }

  function pageForward() {
    setShowPageForwardSpinner(true);
    const pageStart = lastForwardPage ?? Math.round(Date.now() / 1000);
    const pageEnd = pageStart + 604_800; // Page back 1 week
    fetchMissions({ before: pageEnd, after: pageStart }).finally(() =>
      setShowPageForwardSpinner(false)
    );
    setLastForwardPage(pageEnd);
  }

  return missions !== null ? (
    <Box
      ref={scroller}
      overflow={"auto"}
      height={"full"}
      p={4}
      position={"relative"}
    >
      <Box my={4}>
        {lastBackPage != null && (
          <Text>
            Since {new Date(lastBackPage * 1000).toLocaleDateString()}
          </Text>
        )}
        {showPageBackSpinner ? (
          <Spinner />
        ) : (
          <Button onClick={pageBack}>Load More</Button>
        )}
      </Box>
      <Box>
        {pastMissions?.length ||
        currentMissions?.length ||
        upcomingMissions?.length ? (
          <>
            {pastMissions?.length ? (
              pastMissions
                .filter((mission) => mission)
                .map(
                  (mission) =>
                    mission && (
                      <MissionAgendaItem
                        key={mission.missionId}
                        siteId={siteId}
                        mission={mission}
                      />
                    )
                )
            ) : (
              <Text color={"whiteAlpha.600"} textAlign={"center"}>
                <i>No past missions.</i>
              </Text>
            )}
            {currentMissions?.length ? (
              <>
                <Flex ref={divider} py={4}>
                  <Box w={4} position={"relative"}>
                    <Box
                      borderTopWidth={1}
                      borderColor={"whiteAlpha.500"}
                      w={"full"}
                      position={"absolute"}
                      top={"50%"}
                      translateX={"-50%"}
                    />
                  </Box>
                  <Text mx={2}>
                    <b>In Progress</b>
                  </Text>
                  <Box flex={1} position={"relative"}>
                    <Box
                      borderTopWidth={1}
                      borderColor={"whiteAlpha.500"}
                      w={"full"}
                      position={"absolute"}
                      top={"50%"}
                      translateX={"-50%"}
                    />
                  </Box>
                </Flex>
                {currentMissions.map(
                  (mission) =>
                    mission && (
                      <MissionAgendaItem
                        key={mission.missionId}
                        siteId={siteId}
                        mission={mission}
                        hideTopEdge={true}
                        hideBottomEdge={true}
                      />
                    )
                )}
              </>
            ) : null}
            <Flex ref={divider} py={4}>
              <Box w={4} position={"relative"}>
                <Box
                  borderTopWidth={1}
                  borderColor={"whiteAlpha.500"}
                  w={"full"}
                  position={"absolute"}
                  top={"50%"}
                  translateX={"-50%"}
                />
              </Box>
              <Text mx={2}>
                <b>Upcoming</b>
              </Text>
              <Box flex={1} position={"relative"}>
                <Box
                  borderTopWidth={1}
                  borderColor={"whiteAlpha.500"}
                  w={"full"}
                  position={"absolute"}
                  top={"50%"}
                  translateX={"-50%"}
                />
              </Box>
            </Flex>
            {upcomingMissions?.length ? (
              upcomingMissions.map(
                (mission) =>
                  mission && (
                    <MissionAgendaItem
                      key={mission.missionId}
                      siteId={siteId}
                      mission={mission}
                    />
                  )
              )
            ) : (
              <Text color={"whiteAlpha.600"} textAlign={"center"}>
                <i>Nothing scheduled yet.</i>
              </Text>
            )}
          </>
        ) : (
          <Text color={"whiteAlpha.600"} textAlign={"center"} mt={4}>
            <i>Nothing scheduled yet.</i>
          </Text>
        )}
        <Box my={4}>
          {showPageForwardSpinner ? (
            <Spinner />
          ) : (
            <Button onClick={pageForward}>Load More</Button>
          )}
          {lastForwardPage != null && (
            <Text>
              Up to {new Date(lastForwardPage * 1000).toLocaleDateString()}
            </Text>
          )}
        </Box>
      </Box>
      <Box pt={"50%"} />
    </Box>
  ) : (
    <Center height={"full"}>
      <Spinner />
    </Center>
  );
}
