import { Box, Checkbox, Collapse, Icon, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SelectFeatures from "../../../../../../components/forms/SelectFeatures";
import { useSitesStore } from "../../../../../../state/sites";
import Editable from "../../../../../../components/forms/Editable";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import EditSchedule from "../../../../../../components/forms/EditSchedule";

interface GridScanFormProps {
  siteId: string;
  taskConfig: Record<string, string> | null;
  setTaskConfig: (newTaskConfig: Record<string, string>) => void;
  schedule: Schedule;
  setTaskSchedule: (newSchedule: Schedule) => void;
  creating?: boolean;
}

export default function GridScanForm(props: GridScanFormProps) {
  const sites = useSitesStore((state) => state.sites);
  const [showAdvanced, setShowAdvanced] = useState(false);

  function saveConfigProperty(property: string): (value: string) => void {
    return (value) => {
      props.setTaskConfig({ ...props.taskConfig, [property]: value });
    };
  }

  function saveConfigFlag(property: string): (e: any) => void {
    return (e) => {
      if (e.target.checked) {
        props.setTaskConfig({
          ...props.taskConfig,
          [property]: "true",
        });
      } else {
        const newTaskConfig = { ...props.taskConfig };
        delete newTaskConfig[property];
        props.setTaskConfig(newTaskConfig);
      }
    };
  }

  useEffect(() => {
    // Set initial task config
    if (props.creating) {
      props.setTaskConfig({
        frontOverlap: "0.8", // Used by DJI
        sideOverlap: "0.7", // Used by DJI
        scanHeading: "0",
        scanAltitude: "41", // ~135ft = 1.45 cm/px
        scanSpeed: "5", // 11.2mph, <12mph per RaptorMaps standard
        transitSpeed: "15", // 33mph
        panelTilt: "0",
        maxMissionDuration: "1200",
        useVisualCamera: "true",
        // useInfraredCamera is unset which means false
      });
    }
  }, []);

  useEffect(() => {
    console.log(props.taskConfig);
  }, [props.taskConfig]);

  return (
    <>
      <Box my={4}>
        <SelectFeatures
          label={"Target Areas"}
          features={sites?.[props.siteId]?.scanAreas || ""}
          selectedFeatureIds={props.taskConfig?.targetFeatureIds}
          isMultiSelect={true}
          onSubmit={saveConfigProperty("targetFeatureIds")}
        />
      </Box>
      <Box my={4}>
        <EditSchedule value={props.schedule} onSubmit={props.setTaskSchedule} />
      </Box>

      <Text onClick={() => setShowAdvanced(!showAdvanced)} cursor={"pointer"}>
        <Icon
          as={showAdvanced ? FaCaretDown : FaCaretRight}
          verticalAlign={"middle"}
        />{" "}
        <b>Advanced</b>
      </Text>
      <Collapse in={showAdvanced}>
        <Box my={4}>
          <Editable
            label={"Front Overlap (%)"}
            value={
              (props.taskConfig?.frontOverlap !== undefined &&
                String(parseFloat(props.taskConfig.frontOverlap) * 100)) ||
              "0"
            }
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={(v) =>
              saveConfigProperty("frontOverlap")(String(parseFloat(v) / 100))
            }
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Side Overlap (%)"}
            value={
              (props.taskConfig?.frontOverlap !== undefined &&
                String(parseFloat(props.taskConfig.sideOverlap) * 100)) ||
              "0"
            }
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={(v) =>
              saveConfigProperty("sideOverlap")(String(parseFloat(v) / 100))
            }
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Scan Heading (degrees)"}
            value={props.taskConfig?.scanHeading || "0"}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("scanHeading")}
          />
        </Box>
        {/* TODO: Abstract this away as a function of GSD. */}
        <Box my={4}>
          <Editable
            label={"Scan Altitude (meters)"}
            value={props.taskConfig?.scanAltitude || "0"}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("scanAltitude")}
          />
        </Box>
        {/* TODO: Abstract this away as a function of GSD. */}
        <Box my={4}>
          <Editable
            label={"Scan Speed (m/s)"}
            value={props.taskConfig?.scanSpeed || "0"}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("scanSpeed")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Transit Speed (m/s)"}
            value={props.taskConfig?.transitSpeed || "0"}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("transitSpeed")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Panel Tilt"}
            value={props.taskConfig?.panelTilt || ""}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("panelTilt")}
          />
        </Box>
        <Box my={4}>
          <Editable
            label={"Max Mission Duration"}
            value={props.taskConfig?.panelTilt || ""}
            type={"input"}
            inputProps={{ type: "number" }}
            onSubmit={saveConfigProperty("maxMissionDuration")}
          />
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.useVisualCamera)}
            onChange={saveConfigFlag("useVisualCamera")}
          >
            Use Visual Camera
          </Checkbox>
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.useInfraredCamera)}
            onChange={saveConfigFlag("useInfraredCamera")}
          >
            Use IR Camera
          </Checkbox>
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.useSimulation)}
            onChange={saveConfigFlag("useSimulation")}
          >
            Use Simulation
          </Checkbox>
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.processSensehawk)}
            onChange={saveConfigFlag("processSensehawk")}
          >
            Sensehawk Process
          </Checkbox>
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.processPix4D)}
            onChange={saveConfigFlag("processPix4D")}
          >
            Pix4D Process
          </Checkbox>
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.skipProcessing)}
            onChange={saveConfigFlag("skipProcessing")}
          >
            Don't Process
          </Checkbox>
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.skipGeofence)}
            onChange={saveConfigFlag("skipGeofence")}
          >
            Disable Geofence
          </Checkbox>
        </Box>
        <Box>
          <Checkbox
            isChecked={Boolean(props.taskConfig?.skipCheckArea)}
            onChange={saveConfigFlag("skipCheckArea")}
          >
            Disable Post-Takeoff Check
          </Checkbox>
        </Box>
      </Collapse>
    </>
  );
}
