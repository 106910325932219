import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { setValueFromEvent } from "../../../../../common/helpers";
import React, { useCallback, useState } from "react";
import { useNavigationShortcuts } from "../../../../../common/navigation";
import { useParams } from "react-router-dom";
import ImageWithFallback from "../../../components/ImageWithFallback";

interface SelectTaskTypeProps {
  onSelect: (taskType: string) => void;
}

export interface TaskTypeSpec {
  id: string;
  name: string;
  icon?: string;
}

export const TASK_TYPES: TaskTypeSpec[] = [
  {
    id: "GRID_SCAN",
    name: "Grid Scan",
    icon: "/assets/tasks/grid_scan.png",
  },
  {
    id: "RAPTORMAPS_STANDARD",
    name: "RaptorMaps",
    icon: "/assets/tasks/raptormaps.png",
  },
  {
    id: "SIMPLE_TEST",
    name: "Simple Test",
    icon: "/assets/tasks/simple_test.png",
  },
  {
    id: "SPOT_CHECK",
    name: "Spot Check",
    icon: "/assets/tasks/spot_check.png",
  },
];
export const TASK_TYPE_LOOKUP = new Map(TASK_TYPES.map((v) => [v.id, v]));

export default function SelectTaskType(props: SelectTaskTypeProps) {
  const params = useParams();
  const siteId = params.siteId;
  const shortcuts = useNavigationShortcuts();
  const [search, setSearch] = useState("");

  const filterTaskTypes = useCallback(
    (taskType: TaskTypeSpec) => {
      return taskType.name.toLowerCase().includes(search.toLowerCase());
    },
    [search]
  );

  return (
    <>
      <Flex px={4} pt={4}>
        <Heading as={"h1"} fontSize={"xl"} mb={4} flex={1}>
          Create Task
        </Heading>
        <Button
          size={"sm"}
          colorScheme={"red"}
          onClick={() => shortcuts.selectSite(siteId, "tasks", true)}
        >
          Cancel
        </Button>
      </Flex>
      <Flex flexDirection={"column"} overflow={"hidden"} flex={1}>
        <Box p={4}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.500" />}
            />
            <Input
              type="tel"
              placeholder="Search"
              onChange={setValueFromEvent(setSearch)}
            />
          </InputGroup>
        </Box>
        <Box overflow={"auto"} px={4} pb={12} flex={1}>
          <SimpleGrid columns={2} spacing={4}>
            {TASK_TYPES.filter(filterTaskTypes).map((taskType) => (
              <Box
                key={taskType.id}
                p={2}
                textAlign={"center"}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={"whiteAlpha.300"}
                bg={"whiteAlpha.200"}
                onClick={() => props.onSelect(taskType.id)}
                cursor={"pointer"}
                _hover={{ backgroundColor: "whiteAlpha.500" }}
              >
                <ImageWithFallback
                  src={taskType.icon}
                  w={"full"}
                  objectFit={"contain"}
                />
                <Text>
                  <b>{taskType.name}</b>
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      </Flex>
    </>
  );
}
