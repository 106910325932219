import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useUserStore } from "../../state/user";
import { useDronesStore } from "../../state/drones";
import { useSitesStore } from "../../state/sites";
import { useTasksStore } from "../../state/tasks";
import { useMissionsStore } from "../../state/missions";
import { useTaskInstancesStore } from "../../state/taskInstances";
import { useDataObjectsStore } from "../../state/dataObjects";
import { useDroneTelemetryStore } from "../../state/droneTelemetry";
import { useDocksStore } from "../../state/docks";
import { useDockStateStore } from "../../state/dockState";
import { handleMessage, MQTT, QOS } from "../../common/mqtt";

export default function MqttController() {
  const toast = useToast();
  const attributes = useUserStore((state) => state.attributes);
  const { drones, fetchDrone } = useDronesStore();
  const { docks, fetchDock } = useDocksStore();
  const { fetchSite } = useSitesStore();
  const { fetchTask } = useTasksStore();
  const { fetchMission } = useMissionsStore();
  const { fetchTaskInstance } = useTaskInstancesStore();
  const { fetchDataObject } = useDataObjectsStore();
  const { setDroneTelemetry } = useDroneTelemetryStore();
  const { setDockState } = useDockStateStore();
  const [retry, setRetry] = useState(0);

  const handleEventPayload = useCallback(
    (payload: string) => {
      const data = JSON.parse(payload);
      console.log("EVENT:", data);

      switch (data.event) {
        case "DRONE_CONTROLLER_CONNECTED": {
          // TODO: This does not update with drone properly, and drone should not be a dependency of the subscription.
          // TODO: Perhaps dispatch a local message that gets handled elsewhere?
          const droneName =
            drones?.[data.droneId || ""]?.droneName || data.droneId
              ? `Drone ${data.droneId}`
              : "UNKNOWN";
          toast({
            title: "Drone Controller Connected",
            description: `${droneName} is now online.`,
            status: "info",
            duration: 2000,
          });
          break;
        }
        case "DOCK_CONTROLLER_CONNECTED": {
          const dockName =
            docks?.[data.dockId || ""]?.dockName || data.dockName
              ? `Dock ${data.dockId}`
              : "UNKNOWN";
          toast({
            title: "Dock Controller Connected",
            description: `${dockName} is now online.`,
            status: "info",
            duration: 2000,
          });
          break;
        }
        case "PLANNER_STARTING": {
          // toast({
          //   title: "Planner Starting",
          //   description: `Planning missions for ${data.siteId}.`,
          //   status: "info",
          //   duration: 2000,
          // });
          break;
        }
        case "ASSET_CHANGED": {
          if (data.siteId) fetchSite(data.siteId);
          if (data.droneId) fetchDrone(data.droneId);
          if (data.dockId) fetchDock(data.dockId);
          if (data.taskId) fetchTask(data.taskId);
          if (data.missionId) fetchMission(data.missionId);
          if (data.taskInstanceId) {
            fetchTaskInstance(data.taskInstanceId);
          }
          if (data.dataObjectId) {
            fetchDataObject(data.dataObjectId);
          }
          break;
        }
        case "MISSION_STATUS_UPDATE": {
          fetchMission(data.missionId);
          toast({
            title: "Mission Status Update",
            description: `${data.missionId} is now ${data.status}.`,
            status: "info",
            duration: 2000,
          });
          break;
        }
        case "MISSION_CHECKPOINT": {
          fetchMission(data.missionId);
          toast({
            title: "Mission Checkpoint Request",
            description: `${data.missionId} is waiting at checkpoint ${data.checkpointName}.`,
            status: "info",
            duration: 2000,
          });
          break;
        }
        case "MISSION_CHECKPOINT_RESPONSE": {
          fetchMission(data.missionId);
          toast({
            title: "Mission Checkpoint Response",
            description: `Mission ${data.missionId} checkpoint ${
              data.checkpointName
            } was ${data.checkpointPassed ? "PASSED" : "ABORTED"} by ${
              data.authority
            }`,
            status: "info",
            duration: 2000,
          });
          break;
        }
        case "UPLOAD_COMPLETE": {
          // toast({
          //   title: "Upload Complete",
          //   description: `Data for Mission ${data.taskInstanceId} has finished uploading.`,
          //   status: "info",
          //   duration: 2000,
          // });
          break;
        }
        case "DATA_PROCESSOR_CONNECTED": {
          // toast({
          //   title: "Data Processor Connected",
          //   description: `About to begin processing Task Instance ${data.taskInstanceId}.`,
          //   status: "info",
          //   duration: 2000,
          // });
          break;
        }
        case "DATA_PRODUCT_READY": {
          toast({
            title: "Data Product is Ready",
            description: `Task Instance ${data.taskInstanceId} is ready to view.`,
            status: "info",
            duration: 2000,
          });
          break;
        }
        default:
          console.warn(`Unknown event type: ${data.event}`);
      }
    },
    [drones]
  );

  useEffect(() => {
    const orgId = attributes?.["custom:org_id"];
    if (orgId) {
      if (!MQTT) {
        // MQTT is not yet connected. Try again later.
        setTimeout(() => setRetry(retry + 1), 1_000);
      } else {
        const topic = `${orgId}/events`;
        MQTT.subscribe(
          topic,
          QOS.AtLeastOnce,
          handleMessage(({ payload }) => handleEventPayload(payload))
        );
        // .then(() => console.log("Subscribed to Events"))
        // .catch((e) => console.error("Could not subscribe to Events", e))
        return () => {
          MQTT?.unsubscribe(topic);
          // .then(() => console.log("Unsubscribed from Events"))
          // .catch((e) => console.log("Could not unsubscribe from Events", e))
        };
      }
    }
  }, [attributes, retry]);

  let lastTelemetryTimestamp = 0;

  function handleTelemetryPayload(droneId: string, payload: any) {
    const data = JSON.parse(payload);
    // console.log(data);
    if (data.timestamp <= lastTelemetryTimestamp) return; // Reject old telemetry
    setDroneTelemetry(droneId, data);
  }

  useEffect(() => {
    const orgId = attributes["custom:org_id"];
    if (orgId && drones) {
      if (!MQTT) {
        // MQTT is not yet connected. Try again later.
        setTimeout(() => setRetry(retry + 1), 1_000);
      } else {
        const subscriptions: string[] = [];
        for (let droneId of Object.keys(drones)) {
          const topic = `${orgId}/drones/${droneId}/telemetry`;
          subscriptions.push(topic);
          MQTT?.subscribe(
            topic,
            QOS.AtMostOnce,
            handleMessage(({ payload }) =>
              handleTelemetryPayload(droneId, payload)
            )
          );
          // .then(() => console.log(`Subscribed to Drone ${droneId} Telemetry`))
          // .catch((e) =>
          //   console.error(`Could not subscribe to Drone ${droneId} Telemetry`)
          // )
        }
        return () => {
          subscriptions.forEach(
            (topic) => MQTT?.unsubscribe(topic)
            // .then(() => console.log(`Unsubscribed from ${topic}`))
            // .catch((e) =>
            //   console.error(`Could not unsubscribe from ${topic}:`, e)
            // )
          );
        };
      }
    }
  }, [drones, attributes, retry]);

  function handleDockStatePayload(dockId: string, payload: any) {
    const data = JSON.parse(payload);
    // console.log(data);
    // Backwards compatibility: Interpret old dock state
    if (data) {
      if (data.drawerFullyOpen !== undefined) {
        data.bayFullyOpen = data.drawerFullyOpen;
      }
      if (data.drawerFullyClosed !== undefined) {
        data.bayFullyClosed = data.drawerFullyClosed;
      }
    }
    setDockState(dockId, data);
  }

  useEffect(() => {
    const orgId = attributes["custom:org_id"];
    if (orgId && docks) {
      if (!MQTT) {
        // MQTT is not yet connected. Try again later.
        setTimeout(() => setRetry(retry + 1), 1_000);
      } else {
        const subscriptions: string[] = [];
        for (let dockId of Object.keys(docks)) {
          const topic = `${orgId}/docks/${dockId}/state`;
          subscriptions.push(topic);
          MQTT?.subscribe(
            topic,
            QOS.AtMostOnce,
            handleMessage(({ payload }) =>
              handleDockStatePayload(dockId, payload)
            )
          );
          // .then(() => console.log(`Subscribed to Dock ${dockId} State`))
          // .catch((e) =>
          //   console.error(`Could not subscribe to Dock ${dockId} State`)
          // )
        }
        return () => {
          subscriptions.forEach(
            (topic) => MQTT?.unsubscribe(topic)
            // .then(() => console.log(`Unsubscribed from ${topic}`))
            // .catch((e) =>
            //   console.error(`Could not unsubscribe from ${topic}:`, e)
            // )
          );
        };
      }
    }
  }, [docks, attributes, retry]);

  return null;
}
