import React, { FormEvent, useState } from "react";
import {
  Button,
  Center,
  Container,
  Image,
  Input,
  Stack,
  Spinner,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { setValueFromEvent } from "../../common/helpers";
import { useUserStore } from "../../state/user";
import Loading from "../Loading";
import AuthChangeRedirector from "../../components/AuthChangeRedirector";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import PasswordInput from "../../components/forms/PasswordInput";
import Link from "../../components/Link";
import { CognitoUser } from "@aws-amplify/auth";
import CompletePassword from "./CompletePassword";

export default function Login() {
  const [user, userInitialized] = useUserStore((state) => [
    state.user,
    state.isInitialized,
  ]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [working, setWorking] = useState(false);
  const [incompleteUser, setIncompleteUser] = useState<CognitoUser | null>(
    null
  );
  const toast = useToast();

  async function login(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    toast.closeAll();
    setWorking(true);
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setIncompleteUser(user);
      }
    } catch (e: any) {
      toast({ description: e.message, status: "error" });
      setWorking(false);
    }
  }

  return (
    <>
      <AuthChangeRedirector />
      {userInitialized && !user ? (
        <Center flex={1}>
          <Container w={"sm"} maxW={"90%"} textAlign={"center"}>
            {incompleteUser ? (
              <CompletePassword user={incompleteUser} />
            ) : (
              <>
                <Image src={"/assets/fliteworks_dark.svg"} mb={8} />
                <form onSubmit={login}>
                  <Stack spacing={4}>
                    <Input
                      placeholder={"Email"}
                      type={"email"}
                      autoComplete={"username"}
                      onChange={setValueFromEvent(setEmail)}
                    />
                    <PasswordInput
                      placeholder={"Password"}
                      autoComplete={"current-password"}
                      onChange={setValueFromEvent(setPassword)}
                    />
                    {working ? (
                      <Center>
                        <Spinner />
                      </Center>
                    ) : (
                      <Button type={"submit"} colorScheme={"silicon"}>
                        Log In
                      </Button>
                    )}
                  </Stack>
                </form>
                <Box mt={4}>
                  <Link to={"/forgot-password"} color={"whiteAlpha.500"}>
                    Forgot Password
                  </Link>
                </Box>
              </>
            )}
          </Container>
        </Center>
      ) : (
        <Loading />
      )}
    </>
  );
}
