import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { CONSTANTS } from "../common/constants";
import React from "react";
import { Box, Button, Code } from "@chakra-ui/react";

export default function Debug() {
  const [response, setResponse] = useState();
  const [error, setError] = useState<Error>();

  function post() {
    API.post(CONSTANTS.API_NAME, "/debug", {})
      .then((r) => {
        setResponse(r);
        setError(undefined);
      })
      .catch((e: Error) => {
        setError(e);
      });
  }

  function get() {
    API.get(CONSTANTS.API_NAME, "/debug", {})
      .then((r) => {
        setResponse(r);
        setError(undefined);
      })
      .catch((e: Error) => {
        setError(e);
      });
  }

  return (
    <Box flex={1} bg={"white"} color={"black"} p={4} overflow={"auto"}>
      <Box>
        <Button m={4} colorScheme={"silicon"} onClick={get}>
          GET
        </Button>
        <Button m={4} colorScheme={"red"} onClick={post}>
          POST
        </Button>
      </Box>
      <Box>
        <Code
          color={error ? "red" : "inherit"}
          overflow={"hidden"}
          wordBreak={"break-all"}
        >
          {(error && JSON.stringify(error)) ||
            (response && JSON.stringify(response)) ||
            "No response yet."}
        </Code>
      </Box>
    </Box>
  );
}
