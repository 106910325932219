import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { API } from "aws-amplify";
import { CONSTANTS } from "../../../../common/constants";
import { useNavigationShortcuts } from "../../../../common/navigation";
import Editable from "../../../../components/forms/Editable";
import { useSitesStore } from "../../../../state/sites";
import SelectCoordinate from "../../../../components/forms/SelectCoordinate";
import { useDocksStore } from "../../../../state/docks";

export default function EditDock() {
  const toast = useToast();
  const shortcuts = useNavigationShortcuts();
  const params = useParams();
  const siteId = params.siteId;
  const dockId = params.dockId;

  const sites = useSitesStore((state) => state.sites);

  const [docks, fetchDock] = useDocksStore((state) => [
    state.docks,
    state.fetchDock,
  ]);

  const [working, setWorking] = useState(false);

  const {
    isOpen: alertOpen,
    onOpen: openAlert,
    onClose: closeAlert,
  } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  function updateDockProperty(property: string): (value: any) => void {
    return async (value) => {
      if (!dockId) return;
      if (value === docks?.[dockId]?.[property as keyof Dock]) return;
      try {
        await API.patch(CONSTANTS.API_NAME, `/dock/${dockId}`, {
          body: { dock: { [property]: value } },
        });
        await fetchDock(dockId);
        toast({
          description: "Updated dock.",
          status: "success",
          duration: 2000,
        });
        if (property === "parentSite") {
          shortcuts.selectDock(value, dockId);
        }
      } catch (e) {
        toast({
          title: "Failed to update dock.",
          description: `${e}`,
          status: "error",
          duration: 2000,
        });
      }
    };
  }

  // TODO: Possibly merge this into updateDockProperty?
  function updateHardwareReg(property: string): (value: string) => void {
    return async (value: string) => {
      if (!dockId) return;
      if (
        value ===
        docks?.[dockId]?.hardwareReg[property as keyof DockHardwareReg]
      )
        return;
      const hardwareReg = {
        ...docks?.[dockId]?.hardwareReg,
        [property]: value,
      };
      try {
        await API.patch(CONSTANTS.API_NAME, `/dock/${dockId}`, {
          body: { dock: { hardwareReg } },
        });
        await fetchDock(dockId);
        toast({
          description: "Updated dock.",
          status: "success",
          duration: 2000,
        });
      } catch (e) {
        toast({
          title: "Failed to update dock.",
          description: `${e}`,
          status: "error",
          duration: 2000,
        });
      }
    };
  }

  async function deleteDock() {
    if (!dockId) return;
    setWorking(true);
    try {
      await API.del(CONSTANTS.API_NAME, `/dock/${dockId}`, {});
      await fetchDock(dockId);
      toast({
        description: "Dock deleted.",
        status: "success",
        duration: 2000,
      });
      shortcuts.selectSite(siteId, "drones");
    } catch (e) {
      toast({
        title: "Failed to delete dock.",
        description: `${e}`,
        status: "error",
        duration: 2000,
      });
    }
    setWorking(false);
  }

  return (
    <Box px={4}>
      <Box my={4}>
        <Editable
          value={dockId || ""}
          label={"Dock ID"}
          disabled={true}
          selectAll={true}
        />
      </Box>
      {/*<Box my={4}>*/}
      {/*  <Editable*/}
      {/*    value={(droneId && drones?.[droneId]?.enabled.toString()) || "false"}*/}
      {/*    label={"Drone Enabled?"}*/}
      {/*    type={"select"}*/}
      {/*    selectOptions={{ true: "ENABLED", false: "DISABLED" }}*/}
      {/*    onSubmit={(v) => updateDroneProperty("parentSite")(v === "true")}*/}
      {/*  />*/}
      {/*</Box>*/}
      <Box my={4}>
        <Editable
          value={(dockId && docks?.[dockId]?.parentSite) || ""}
          label={"Assigned Site"}
          type={"select"}
          selectOptions={
            (sites &&
              Object.fromEntries(
                Object.values(sites).map((site) => [site.siteId, site.siteName])
              )) ||
            undefined
          }
          onSubmit={updateDockProperty("parentSite")}
        />
      </Box>
      <Box my={4}>
        <Editable
          value={(dockId && docks?.[dockId]?.dockName) || ""}
          onSubmit={updateDockProperty("dockName")}
          label={"Dock Name"}
        />
      </Box>
      <Grid my={4} templateColumns={"repeat(2, 1fr)"} gap={4}>
        <GridItem>
          <Editable
            value={(dockId && docks?.[dockId]?.hardwareReg.make) || ""}
            onSubmit={updateHardwareReg("make")}
            label={"Make"}
          />
        </GridItem>
        <GridItem>
          <Editable
            value={(dockId && docks?.[dockId]?.hardwareReg.model) || ""}
            onSubmit={updateHardwareReg("model")}
            label={"Model"}
          />
        </GridItem>
      </Grid>
      <Box my={4}>
        <Editable
          value={(dockId && docks?.[dockId]?.hardwareReg.serial) || ""}
          onSubmit={updateHardwareReg("serial")}
          label={"Serial Number"}
        />
      </Box>
      <Box my={4}>
        <SelectCoordinate
          label={"Pad Location"}
          coordinate={
            (dockId && docks?.[dockId]?.padLocation) || { lat: 0, lng: 0 }
          }
          setCoordinate={updateDockProperty("padLocation")}
        />
      </Box>
      <Box my={4}>
        <Editable
          value={(dockId && docks?.[dockId]?.currentDrone) || ""}
          onSubmit={updateDockProperty("currentDrone")}
          label={"Current Drone"}
        />
      </Box>
      <Box my={4}>
        <Editable
          value={(dockId && docks?.[dockId]?.dockStatus) || ""}
          onSubmit={updateDockProperty("status")}
          label={"Status"}
        />
      </Box>

      <Box background={"dangerAlpha.100"} borderRadius={"md"} p={4}>
        <Heading as={"h2"} fontSize={"lg"} mb={4}>
          Danger Zone
        </Heading>
        {working ? (
          <Spinner />
        ) : (
          <Flex>
            <Button colorScheme={"red"} onClick={openAlert}>
              Permanently Delete Dock
            </Button>
          </Flex>
        )}
      </Box>

      <AlertDialog
        isOpen={alertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent background={"steel.800"}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Permanently Delete Dock
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeAlert}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  closeAlert();
                  deleteDock();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
