import { useEffect } from "react";
import { useMap } from "react-map-gl";

interface MapResizeListenerProps {
  trigger: any;
}

export default function MapResizeListener(props: MapResizeListenerProps) {
  const map = useMap();
  useEffect(() => {
    requestAnimationFrame(() => map.current?.resize());
  }, [props.trigger]);

  return null;
}
