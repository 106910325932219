import { API } from "aws-amplify";
import createVanilla from "zustand/vanilla";
import { CONSTANTS } from "../common/constants";
import { capitalize, listToMap } from "../common/helpers";

export function createTableSyncStore<T>(
  objectName: string,
  useUpdateFetch: boolean,
  endpointName?: string,
  customFetchResultMutator?: (result: any) => any[],
  customSingleFetchResultMutator?: (result: any) => any
) {
  const pluralObject = objectName + "s";
  const capitalObject = capitalize(objectName);
  const capitalPluralObject = capitalObject + "s";
  const objectIdKey = objectName + "Id";

  const storeSpec = (set: any, getState: any): any => ({
    [pluralObject]: null,
    ["set" + capitalPluralObject]: (items: any) =>
      set({
        [pluralObject]: listToMap(items, objectIdKey),
      }),
    ["update" + capitalPluralObject]: (items: any) => {
      set({
        [pluralObject]: {
          ...getState()[pluralObject],
          ...listToMap(items, objectIdKey),
        },
      });
    },
    ["fetch" + capitalPluralObject]: async (
      filter?: Record<string, string>,
      update = useUpdateFetch
    ) => {
      const init = filter ? { queryStringParameters: filter } : {};
      const items = await API.get(
        CONSTANTS.API_NAME,
        `/${endpointName || objectName}`,
        init
      )
        .then((r) => {
          if (customFetchResultMutator) return customFetchResultMutator(r);
          else return r[pluralObject] || [];
        })
        .catch(() => []);
      if (update) {
        getState()["update" + capitalPluralObject](items);
      } else {
        getState()["set" + capitalPluralObject](items);
      }
    },
    ["fetch" + capitalObject]: async (objectId: string) => {
      const item = await API.get(
        CONSTANTS.API_NAME,
        `/${endpointName || objectName}/${objectId}`,
        {}
      )
        .then((r) => {
          if (customSingleFetchResultMutator) {
            return customSingleFetchResultMutator(r);
          } else return r[objectName];
        })
        .catch(() => undefined);
      if (!item) {
        const stateObjects = getState()[pluralObject];
        if (!stateObjects) return;
        delete stateObjects[objectId];
        set({ [pluralObject]: stateObjects });
      } else {
        set({
          [pluralObject]: {
            ...getState()[pluralObject],
            [objectId]: item,
          },
        });
      }
    },
  });

  return createVanilla<T>(storeSpec);
}
