import React from "react";
import { createBrowserRouter, Navigate, redirect } from "react-router-dom";
import Login from "../views/auth/Login";
import ForgotPassword from "../views/auth/ForgotPassword";
import { userStore } from "../state/user";
import Main from "../views/main/Main";
import MapDash from "../views/map/MapDash";
import SitePanel from "../views/map/mapPanel/sites/SitePanel";
import CreateSite from "../views/map/mapPanel/sites/CreateSite";
import DronePanel from "../views/map/mapPanel/drones/DronePanel";
import CreateDrone from "../views/map/mapPanel/drones/CreateDrone";
import TaskPanel from "../views/map/mapPanel/tasks/TaskPanel";
import CreateTask from "../views/map/mapPanel/tasks/createTask/CreateTask";
import MissionPanel from "../views/map/mapPanel/missions/MissionPanel";
import TaskInstancePanel from "../views/map/mapPanel/taskInstances/TaskInstancePanel";
import DataObjectPanel from "../views/map/mapPanel/dataObjects/DataObjectPanel";
import Logout from "../views/auth/Logout";
import Account from "../views/Account";
import SelectSite from "../views/map/mapPanel/sites/SelectSite";
import EditTask from "../views/map/mapPanel/tasks/EditTask";
import DockPanel from "../views/map/mapPanel/docks/DockPanel";
import CreateDock from "../views/map/mapPanel/docks/CreateDock";
import Fly from "../views/fly/Fly";
import Debug from "../views/Debug";

function getUser() {
  return userStore.getState().user;
}

function redirectToLogin(request: Request) {
  const url = new URL(request.url);
  if (!["/", ""].includes(url.pathname)) {
    url.searchParams.set("next", url.pathname);
  }
  return redirect(`/login?${url.searchParams}`);
}

function redirectToNext(request: Request) {
  console.log(request.url);
  const url = new URL(request.url);
  const next = url.searchParams.get("next") || "/";
  url.searchParams.delete("next");
  return redirect(next + "?" + url.searchParams);
}

export const router = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
    loader: ({ request }) => (getUser() ? redirectToNext(request) : null),
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
    loader: () => (getUser() ? redirect("/") : null),
  },
  {
    path: "/",
    element: <Main />,
    loader: ({ request }) => (getUser() ? null : redirectToLogin(request)),
    children: [
      { path: "logout", element: <Logout /> },
      { path: "account", element: <Account /> },
      {
        path: "map",
        element: <MapDash />,
        children: [
          { index: true, element: <SelectSite /> },
          {
            path: "site",
            children: [
              {
                path: ":siteId",
                children: [
                  {
                    index: true,
                    element: <Navigate to={"missions"} replace />,
                  },
                  { path: ":tab", element: <SitePanel /> },
                  {
                    path: "drone",
                    children: [
                      { path: ":droneId", element: <DronePanel /> },
                      { path: "create", element: <CreateDrone /> },
                    ],
                  },
                  {
                    path: "dock",
                    children: [
                      { path: ":dockId", element: <DockPanel /> },
                      { path: "create", element: <CreateDock /> },
                    ],
                  },
                  {
                    path: "task",
                    children: [
                      {
                        path: ":taskId",
                        children: [
                          { index: true, element: <TaskPanel /> },
                          {
                            path: "task_instance",
                            children: [
                              {
                                path: ":taskInstanceId",
                                element: <TaskInstancePanel />,
                              },
                            ],
                          },
                        ],
                      },
                      { path: "create", element: <CreateTask /> },
                    ],
                  },
                  {
                    path: "mission",
                    children: [
                      {
                        path: ":missionId",
                        children: [
                          { index: true, element: <MissionPanel /> },
                          {
                            path: "data_object/:dataObjectId",
                            element: <DataObjectPanel />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              { path: "create", element: <CreateSite /> },
            ],
          },
        ],
      },
      { path: "debug", element: <Debug /> },
      { index: true, element: <Navigate to={"/map"} replace /> },
    ],
  },
  { path: "fly/:droneId", element: <Fly /> },
  //{ path: "*", element: <Navigate to={"/"} replace /> }, // Not found? Go to default route
]);
