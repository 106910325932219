import React from "react";
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import EditDrone from "./EditDrone";
import ViewDrone from "./ViewDrone";
import { ChevronLeftIcon, SettingsIcon } from "@chakra-ui/icons";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { useParams } from "react-router-dom";
import { useDronesStore } from "../../../../state/drones";
import { StreamViewer } from "../../../../components/StreamViewer";

export default function DronePanel() {
  const params = useParams();
  const siteId = params.siteId;
  const droneId = params.droneId;
  const shortcuts = useNavigationShortcuts();

  const drones = useDronesStore((state) => state.drones);
  return (
    <Flex flexDirection={"column"} h={"full"}>
      <StreamViewer streamUrl={droneId && drones?.[droneId]?.streamUrl} />
      <Tabs
        as={Flex}
        colorScheme={"white"}
        variant={"enclosed"}
        flexDirection={"column"}
        overflow={"hidden"}
        height={"full"}
      >
        <TabList>
          <Tab>Drone Status</Tab>
          <Tab>
            <SettingsIcon />
          </Tab>
          <Flex flex={1} justifyContent={"flex-end"} alignItems={"center"}>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => shortcuts.selectSite(siteId, "drones", true)}
              mr={1}
            >
              <ChevronLeftIcon /> Back
            </Button>
          </Flex>
        </TabList>
        <TabPanels overflow={"auto"} height={"full"} pb={12}>
          <TabPanel>
            <ViewDrone />
          </TabPanel>
          <TabPanel>
            <EditDrone />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
