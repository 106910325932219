import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import { Auth, Hub } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { FliteworksTheme } from "./themes/Fliteworks";
import { useUserStore } from "./state/user";
import Loading from "./views/Loading";
import "./common/mqtt";
import { connectMQTT, disconnectMQTT } from "./common/mqtt";
import { router } from "./common/router";
import mapboxgl from "mapbox-gl";

import "./styles/Scrollbars.css";

// Fix mapbox transpilation error
// https://stackoverflow.com/questions/71750627/create-react-app-excluding-mapbox-gl-explicitly-from-transpilation
// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line

function App() {
  const { setUser, clearUser } = useUserStore();

  async function authListener(data: any) {
    switch (data.payload.event) {
      case "signIn":
        setUser(data.payload.data);
        connectMQTT();
        break;
      case "signOut":
        disconnectMQTT();
        clearUser();
        break;
    }
  }

  async function checkUser() {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      setUser(user);
      connectMQTT();
    } catch (e) {
      disconnectMQTT();
      clearUser();
    }
  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", authListener);
    checkUser();
    return unsubscribe;
  }, []);

  return (
    <ChakraProvider theme={FliteworksTheme}>
      <Flex height={"100vh"} direction={"column"} overflow={"hidden"}>
        <RouterProvider router={router} fallbackElement={<Loading />} />
      </Flex>
    </ChakraProvider>
  );
}

export default App;
