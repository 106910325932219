import create from "zustand";
import { createTableSyncStore } from "./common";

interface DronesFilter {
  parentSite?: string;
}

interface DronesState {
  drones: Record<string, Drone> | null;
  setDrones: (drones: Drone[]) => void;
  updateDrones: (drones: Drone[]) => void;
  fetchDrones: (filter?: DronesFilter, update?: boolean) => Promise<void>;
  fetchDrone: (droneId: string) => Promise<void>;
}

function customSingleFetchResultMutator(result: any) {
  const drone = result.drone;
  if (drone) {
    drone.streamUrl = result.streamUrl;
  }
  console.log(drone);
  return drone;
}

export const dronesStore = createTableSyncStore<DronesState>(
  "drone",
  true,
  "drone",
  undefined,
  customSingleFetchResultMutator
);
export const useDronesStore = create(dronesStore);
