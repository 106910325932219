import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { API } from "aws-amplify";
import { CONSTANTS } from "../../../../common/constants";
import { useNavigationShortcuts } from "../../../../common/navigation";
import Editable from "../../../../components/forms/Editable";
import SelectCoordinate from "../../../../components/forms/SelectCoordinate";
import { useDocksStore } from "../../../../state/docks";

export default function CreateDock() {
  const toast = useToast();
  const shortcuts = useNavigationShortcuts();
  const params = useParams();
  const siteId = params.siteId;

  const fetchDock = useDocksStore((state) => state.fetchDock);

  const [dockName, setDockName] = useState("");
  const [hardwareReg, setHardwareReg] = useState<DockHardwareReg>({
    make: "",
    model: "",
    serial: "",
  });
  const [padLocation, setPadLocation] = useState<Coordinate>({
    lat: 0,
    lng: 0,
  });
  const [working, setWorking] = useState(false);

  async function createDock() {
    setWorking(true);
    const response = await API.put(CONSTANTS.API_NAME, "/dock", {
      body: {
        dock: {
          parentSite: siteId,
          dockName,
          hardwareReg,
          padLocation,
        },
      },
    });
    console.log(response);
    await fetchDock(response.dockId);
    toast({
      description: "Dock created.",
      status: "success",
      duration: 2000,
    });
    shortcuts.selectDock(siteId, response.dockId);
    setWorking(false);
  }

  return (
    <Flex direction={"column"} overflow={"hidden"} height={"full"}>
      <Flex px={4} pt={4}>
        <Heading as={"h1"} fontSize={"xl"} mb={4} flex={1}>
          Create Dock
        </Heading>
        <Button
          size={"sm"}
          colorScheme={"red"}
          onClick={() => shortcuts.selectSite(siteId, "drones", true)}
        >
          Cancel
        </Button>
      </Flex>
      <Box px={8} pb={12} overflow={"auto"} height={"full"}>
        <Box mb={4}>
          <Editable
            value={dockName}
            onSubmit={setDockName}
            label={"Dock Nickname"}
          />
        </Box>
        <Grid my={4} templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem>
            <Editable
              value={hardwareReg.make}
              onSubmit={(v) => setHardwareReg({ ...hardwareReg, make: v })}
              label={"Make"}
            />
          </GridItem>
          <GridItem>
            <Editable
              value={hardwareReg.model}
              onSubmit={(v) => setHardwareReg({ ...hardwareReg, model: v })}
              label={"Model"}
            />
          </GridItem>
        </Grid>
        <Box my={4}>
          <Editable
            value={hardwareReg.serial}
            onSubmit={(v) => setHardwareReg({ ...hardwareReg, serial: v })}
            label={"Serial Number"}
          />
        </Box>
        <Box my={4}>
          <SelectCoordinate
            label={"Pad Location"}
            coordinate={padLocation}
            setCoordinate={setPadLocation}
          />
        </Box>

        {working ? (
          <Spinner />
        ) : (
          <Button colorScheme={"silicon"} onClick={createDock}>
            Create Dock
          </Button>
        )}
      </Box>
    </Flex>
  );
}
