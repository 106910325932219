import create from "zustand";
import createVanilla from "zustand/vanilla";
import { zipEqualOrBothInvalid } from "../common/helpers";

interface MapCameraState {
  isUrlInitialized: boolean;
  cameraXYZ: [number, number, number];
  targetBBox: [number, number, number, number] | null;
  setCameraXYZ: (lng: number, lat: number, zoom: number) => void;
  setTargetBbox: (bbox: [number, number, number, number] | null) => void;
}

export const mapCameraStore = createVanilla<MapCameraState>((set) => ({
  isUrlInitialized: false,
  cameraXYZ: [NaN, NaN, NaN],
  targetBBox: null,
  setCameraXYZ: (lng, lat, zoom) =>
    set({ cameraXYZ: [lng, lat, zoom], isUrlInitialized: true }),
  setTargetBbox: (bbox) => set({ targetBBox: bbox }),
}));
export const useMapCameraStore = create(mapCameraStore);

// Use this with useMapDashStore to limit firing to only when changes happen and prevent infinite loops.
export function equalMapCameraStates(a: MapCameraState, b: MapCameraState) {
  return (
    zipEqualOrBothInvalid(a.cameraXYZ, b.cameraXYZ) &&
    (a.targetBBox === b.targetBBox ||
      (a.targetBBox !== null &&
        b.targetBBox !== null &&
        zipEqualOrBothInvalid(a.targetBBox, b.targetBBox)))
  );
}
