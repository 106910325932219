import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";
import SelectTaskType from "./SelectTaskType";
import TaskForm from "./TaskForm";

export default function CreateTask() {
  const [taskType, setTaskType] = useState("");

  return (
    <Flex direction={"column"} overflow={"hidden"} height={"full"}>
      {taskType ? (
        <TaskForm taskType={taskType} setTaskType={setTaskType} />
      ) : (
        <SelectTaskType onSelect={setTaskType} />
      )}
    </Flex>
  );
}
