import {
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import shallow from "zustand/shallow";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { selectStatusColor } from "../../../../common/helpers";
import { useDronesStore } from "../../../../state/drones";
import { useParams } from "react-router-dom";
import { TaskTypeSpec } from "../tasks/createTask/SelectTaskType";
import ImageWithFallback from "../../components/ImageWithFallback";

export const DRONE_TYPES: TaskTypeSpec[] = [
  {
    id: "DJI:MAVIC_2E",
    name: "DJI Mavic 2 Enterprise",
    icon: "/assets/drones/dji_mavic2.png",
  },
  {
    id: "DJI:MAVIC_3T",
    name: "DJI Mavic 3T Enterprise",
    icon: "/assets/drones/dji_mavic3t.jpg",
  },
  {
    id: "DJI:M30T",
    name: "DJI Matrice 30T",
    icon: "/assets/drones/dji_m30t.png",
  },
];
export const DRONE_TYPE_LOOKUP = new Map(DRONE_TYPES.map((v) => [v.id, v]));

export interface SelectDroneProps {
  title?: string;
}

export default function SelectDrone(props: SelectDroneProps) {
  const params = useParams();
  const siteId = params.siteId;
  const shortcuts = useNavigationShortcuts();
  const drones = useDronesStore((state) => state.drones, shallow);

  return (
    <Box>
      <Flex mb={4}>
        <Flex flex={1} direction={"column"} justifyContent={"center"}>
          {props.title && (
            <Heading as={"h2"} fontSize={"xl"}>
              {props.title}
            </Heading>
          )}
        </Flex>
        <IconButton
          ml={4}
          aria-label={"Create Drone"}
          onClick={() => shortcuts.selectDrone(siteId, "create")}
          variant={"ghost"}
          icon={<AddIcon />}
        />
      </Flex>
      <Box>
        {drones &&
          Object.values(drones)
            .filter((drone) => drone.parentSite == siteId)
            .sort((a, b) => a.droneName.localeCompare(b.droneName))
            .map((drone) => (
              <Box
                key={drone.droneId}
                mb={4}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={"whiteAlpha.200"}
                bg={"whiteAlpha.200"}
                onClick={() => shortcuts.selectDrone(siteId, drone.droneId)}
                cursor={"pointer"}
                _hover={{ backgroundColor: "whiteAlpha.300" }}
                maxH={100}
                overflow={"hidden"}
              >
                <Flex>
                  <Box minW={"100px"}>
                    <ImageWithFallback
                      w={"100px"}
                      h={"100px"}
                      src={
                        DRONE_TYPE_LOOKUP.get(
                          `${drone.hardwareReg.make}:${drone.hardwareReg.model}`
                        )?.icon
                      }
                      objectFit={"cover"}
                    />
                  </Box>
                  <Box flex={1} ml={1} p={2} overflow={"hidden"}>
                    <Text>
                      <b>{drone.droneName || drone.droneId}</b>
                    </Text>
                    <Text
                      fontSize={"0.8em"}
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      {
                        DRONE_TYPE_LOOKUP.get(
                          `${drone.hardwareReg.make}:${drone.hardwareReg.model}`
                        )?.name
                      }
                    </Text>
                    <Text
                      fontSize={"0.8em"}
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      Status:{" "}
                      <span
                        style={{
                          color: selectStatusColor(drone.droneStatus),
                          lineHeight: 0,
                          verticalAlign: "middle",
                        }}
                      >
                        &#x25C9;
                      </span>{" "}
                      {drone.droneStatus}
                    </Text>
                    <Text
                      fontSize={"0.8em"}
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      Last seen:{" "}
                      {new Date(drone.statusTimestamp).toLocaleString()}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
        {drones &&
          !Object.values(drones).filter((drone) => drone.parentSite).length && (
            <Text color={"whiteAlpha.600"} textAlign={"center"}>
              <i>Nothing here yet.</i>
            </Text>
          )}
        {drones === null && (
          <Center height={"full"}>
            <Spinner />
          </Center>
        )}
      </Box>
    </Box>
  );
}
