import React from "react";
import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import EditDock from "./EditDock";
import ViewDock from "./ViewDock";
import { ChevronLeftIcon, SettingsIcon } from "@chakra-ui/icons";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { useParams } from "react-router-dom";
import { useDocksStore } from "../../../../state/docks";
import { StreamViewer } from "../../../../components/StreamViewer";

export default function DockPanel() {
  const params = useParams();
  const siteId = params.siteId;
  const dockId = params.dockId;
  const shortcuts = useNavigationShortcuts();

  const docks = useDocksStore((state) => state.docks);
  return (
    <Flex flexDirection={"column"} h={"full"}>
      <StreamViewer streamUrl={dockId && docks?.[dockId]?.streamUrl} />
      <Tabs
        as={Flex}
        colorScheme={"white"}
        variant={"enclosed"}
        flexDirection={"column"}
        overflow={"hidden"}
        height={"full"}
      >
        <TabList>
          <Tab>Dock Status</Tab>
          <Tab>
            <SettingsIcon />
          </Tab>
          <Flex flex={1} justifyContent={"flex-end"} alignItems={"center"}>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => shortcuts.selectSite(siteId, "drones", true)}
              mr={1}
            >
              <ChevronLeftIcon /> Back
            </Button>
          </Flex>
        </TabList>
        <TabPanels overflow={"auto"} height={"full"} pb={12}>
          <TabPanel>
            <ViewDock />
          </TabPanel>
          <TabPanel>
            <EditDock />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
