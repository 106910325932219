import React from "react";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemProps,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Text,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useSitesStore } from "../../../state/sites";
import { useDronesStore } from "../../../state/drones";
import Link from "../../../components/Link";
import { useMissionsStore } from "../../../state/missions";
import { timestampToReadableString } from "../../../common/helpers";

function DroneBreadcrumbItems(props: BreadcrumbItemProps) {
  const drones = useDronesStore((state) => state.drones);
  const params = useParams();

  function droneName(droneId: string) {
    return (
      (drones &&
        drones[droneId]?.droneName?.length &&
        drones[droneId].droneName) ||
      droneId
    );
  }

  if (params.droneId) {
    return (
      <BreadcrumbItem isCurrentPage overflow={"hidden"} {...props}>
        <BreadcrumbSeparator />
        <BreadcrumbLink
          as={Link}
          variant={"link"}
          fontSize={"sm"}
          to={`/map/site/${params.siteId}/drone/${params.droneId}`}
          fontWeight={"bold"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          Drone: {droneName(params.droneId)}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  } else {
    return null;
  }
}

function TaskBreadcrumbItems(props: BreadcrumbItemProps) {
  const params = useParams();

  if (params.taskId) {
    return (
      <BreadcrumbItem isCurrentPage overflow={"hidden"} {...props}>
        <BreadcrumbSeparator />
        <BreadcrumbLink
          as={Link}
          variant={"link"}
          fontSize={"sm"}
          to={`/map/site/${params.siteId}/task/${params.taskId}`}
          fontWeight={"bold"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          <b>Task: {params.taskId}</b>
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  } else {
    return null;
  }
}

function MissionBreadcrumbItems(props: BreadcrumbItemProps) {
  const params = useParams();
  const missions = useMissionsStore((state) => state.missions);

  function missionName(missionId: string) {
    const mission = missions?.[missionId];
    if (mission) {
      return timestampToReadableString(mission.startTime);
    } else {
      return `Mission ${missionId}`;
    }
  }

  if (params.missionId) {
    return (
      <BreadcrumbItem isCurrentPage {...props}>
        <BreadcrumbSeparator />
        <BreadcrumbLink
          as={Link}
          variant={"link"}
          fontSize={"sm"}
          to={`/map/site/${params.siteId}/mission/${params.mission}`}
          fontWeight={"bold"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          <b>{missionName(params.missionId)}</b>
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  } else {
    return null;
  }
}

function SiteBreadcrumbItems(props: BreadcrumbItemProps) {
  const sites = useSitesStore((state) => state.sites);
  const params = useParams();
  const noChild = !params.droneId && !params.taskId && !params.missionId;

  function siteName(siteId: string) {
    return (
      (sites && sites[siteId]?.siteName?.length && sites[siteId].siteName) ||
      `Site: ${siteId}`
    );
  }

  if (params.siteId) {
    return (
      <>
        <BreadcrumbItem isCurrentPage={noChild} overflow={"hidden"} {...props}>
          <BreadcrumbLink
            as={Link}
            variant={"link"}
            fontSize={"sm"}
            to={`/map/site/${params.siteId}${
              // Act like a "back" action and go to appropriate tab
              params.taskId
                ? "/tasks"
                : params.droneId
                ? "/drones"
                : params.missionId
                ? "/missions"
                : ""
            }`}
            fontWeight={
              // Normal text if child asset is selected
              noChild ? "bold" : "normal"
            }
            whiteSpace={"nowrap"}
            maxWidth={noChild ? "unset" : "80px"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {siteName(params.siteId)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <TaskBreadcrumbItems {...props} />
        <DroneBreadcrumbItems {...props} />
        <MissionBreadcrumbItems {...props} />
      </>
    );
  } else {
    return (
      <BreadcrumbItem {...props}>
        <Text fontWeight={"bold"} whiteSpace={"nowrap"}>
          Select a Site
        </Text>
      </BreadcrumbItem>
    );
  }
}

export function AssetBreadcrumb() {
  return (
    <Breadcrumb
      mx={2}
      fontSize={"sm"}
      overflow={"hidden"}
      separator={<ChevronRightIcon />}
    >
      <SiteBreadcrumbItems />
    </Breadcrumb>
  );
}
