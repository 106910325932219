import React, { useEffect, useMemo, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { Marker } from "react-map-gl";
import { handleMessage, MQTT, QOS } from "../../../common/mqtt";

interface ADSBLayerProps {
  droneIds: string[];
}

interface ADSBReport {
  detections: ADSBDetection[];
  timestamp: number;
}

interface ADSBDetection {
  identifier: string;
  heading: number;
  lat: number;
  lng: number;
  alt: number;
  distance: number;
}

export default function ADSBLayers(props: ADSBLayerProps) {
  const [orgId, setOrgId] = useState<string | undefined>(undefined);
  const [droneIdList, setDroneIdList] = useState<string>("");
  const [reportsByDrone, setReportsByDrone] = useState<
    Record<string, ADSBReport>
  >({});
  const [lastReportTimestamp, setLastReportTimestamp] = useState(0);
  const [retry, setRetry] = useState(0);

  const proximityAlarm1 = useMemo(
    () => new Audio("/assets/proximity_alarm_1.mp3"),
    []
  );
  const proximityAlarm2 = useMemo(
    () => new Audio("/assets/proximity_alarm_2.mp3"),
    []
  );
  const proximityAlarm3 = useMemo(
    () => new Audio("/assets/proximity_alarm_3.mp3"),
    []
  );
  const alarms = [proximityAlarm1, proximityAlarm2, proximityAlarm3];

  function handleAdsb(droneId: string, payload: any) {
    const data = JSON.parse(payload);
    if (
      reportsByDrone[droneId]?.timestamp != null &&
      reportsByDrone[droneId].timestamp > data.timestamp
    ) {
      // Report is out of date
      return;
    }

    // Update report
    reportsByDrone[droneId] = data;
    setReportsByDrone(reportsByDrone);
    setLastReportTimestamp(Date.now());
  }

  useEffect(() => {
    // Get OrgId
    (async () => {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);
      setOrgId(attributes.find((a) => a.Name == "custom:org_id")?.Value);
    })();

    proximityAlarm1.load();
    proximityAlarm1.loop = true;
    proximityAlarm2.load();
    proximityAlarm2.loop = true;
    proximityAlarm3.load();
    proximityAlarm3.loop = true;
  }, []);

  useEffect(() => {
    setDroneIdList(props.droneIds.sort().join(","));
  }, [props.droneIds]);

  useEffect(() => {
    const droneIds = droneIdList.split(",").filter((e) => e);
    if (orgId) {
      if (!MQTT) {
        // MQTT is not yet connected. Try again later.
        setTimeout(() => setRetry(retry + 1), 1_000);
      } else {
        const subscriptions: string[] = [];
        for (let droneId of droneIds) {
          const topic = `${orgId}/drones/${droneId}/adsb`;
          subscriptions.push(topic);
          MQTT?.subscribe(
            topic,
            QOS.AtLeastOnce,
            handleMessage(({ payload }) => handleAdsb(droneId, payload))
          );
          // .then(() => console.log(`Subscribed to Drone ${droneId} ADSB`))
          // .catch((e) =>
          //   console.error(`Could not subscribe to Drone ${droneId} ADSB`, e)
          // )
        }
        return () => {
          for (let topic of subscriptions) {
            MQTT?.unsubscribe(topic);
            // .then(() => console.log(`Unsubscribed from ${topic}`))
            // .catch((e) =>
            //   console.error(`Could not unsubscribe from ${topic}`, e)
            // )
          }
        };
      }
    }
  }, [orgId, droneIdList, retry]);

  useEffect(() => {
    let warningLevel = 0;
    for (let report of Object.values(reportsByDrone)) {
      if (report) {
        for (let detection of Object.values(report.detections)) {
          if (detection) {
            let thisWarningLevel = 0;
            if (detection.distance < 610) {
              // 2000 feet - below "well clear" standard!
              thisWarningLevel = 3;
            } else if (detection.distance < 1524) {
              // 5000 feet
              thisWarningLevel = 2;
            } else if (detection.distance < 5556) {
              // 3 nautical miles
              thisWarningLevel = 1;
            }
            if (thisWarningLevel > warningLevel) {
              warningLevel = thisWarningLevel;
            }
          }
        }
      }
    }

    console.log("WARNING LEVEL", warningLevel);
    if (warningLevel > 0) {
      if (alarms[warningLevel - 1].paused) {
        alarms[warningLevel - 1].play();
      }
      alarms.filter((_, i) => i != warningLevel - 1).map((a) => a.pause());
    } else {
      alarms.map((a) => a.pause());
    }
  }, [reportsByDrone, lastReportTimestamp]);

  return (
    <>
      {Object.values(reportsByDrone).map((report) =>
        report?.detections?.map((detection) => (
          <>
            <Marker
              key={`adsb-${detection.identifier}`}
              longitude={detection.lng}
              latitude={detection.lat}
              rotation={detection.heading}
            >
              <img
                src={"/assets/plane.png"}
                alt={`ADS-B ${detection.identifier}`}
              />
            </Marker>
            <Marker
              key={`${detection.identifier}-attitude`}
              longitude={detection.lng}
              latitude={detection.lat}
              offset={[24, 0]}
              anchor={"left"}
            >
              <div className={"map-drone-label"}>
                {detection.distance}m
                <br />
                {detection.alt}m
                <br />
                {detection.heading.toFixed(1)}&deg;
              </div>
            </Marker>
          </>
        ))
      )}
    </>
  );
}
