import { useMissionsStore } from "../../../state/missions";
import React from "react";
import { missionIsFlying } from "../../../common/helpers";
import WaypointsVisualizationLayer from "./missionVisualization/WaypointsVisualizationLayer";
import ExecuteNativeMissionVisualizationLayer from "./missionVisualization/ExecuteNativeMissionVisualizationLayer";
import FollowRowVisualizationLayer from "./missionVisualization/FollowRowVisualizationLayer";
import { useParams } from "react-router-dom";
import ActionVisualizationLayer from "./missionVisualization/ActionVisualizationLayer";

export default function MissionLayers() {
  const params = useParams();
  const missions = useMissionsStore((state) => state.missions);
  const selectedMissionId = params.missionId;

  return (
    (missions && (
      <>
        {Object.values(missions).map((mission) =>
          mission ? (
            (selectedMissionId && mission.missionId == selectedMissionId) ||
            (!selectedMissionId && missionIsFlying(mission)) ? (
              <span key={mission.missionId}>
                <WaypointsVisualizationLayer mission={mission} />
                <ExecuteNativeMissionVisualizationLayer mission={mission} />
                <FollowRowVisualizationLayer mission={mission} />
                <ActionVisualizationLayer mission={mission} />
              </span>
            ) : null
          ) : null
        )}
      </>
    )) ||
    null
  );
}
