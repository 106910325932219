import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import Map, { AttributionControl, NavigationControl } from "react-map-gl";
import { useMapDrawStore } from "../../state/mapDraw";
import MapDashParamsController from "./components/MapDashParamsController";
import MapPanel from "./mapPanel/MapPanel";
import MapResizeListener from "./components/MapResizeListener";
import GeocoderControl from "../../components/maps/GeocoderControl";
import SiteLayers from "./components/SiteLayers";
import ImageryLayers from "./components/ImageryLayers";
import DroneLayers from "./components/DroneLayers";
import DrawController from "./components/DrawController";
import FeatureSelectController from "./components/FeatureSelectController";
import MissionLayers from "./components/MissionLayers";
import DataController from "./components/DataController";
import "mapbox-gl/dist/mapbox-gl.css";
import "../../styles/MapboxFix.scss";
import "../../styles/MapMarkers.scss";
import ImageLoader from "./components/ImageLoader";
import { MapErrorBoundary } from "./components/MapErrorBoundary";

export default function MapDash() {
  const isDrawing = useMapDrawStore((state) => state.isDrawing);
  const {
    isOpen: showPanel,
    onOpen: openPanel,
    onToggle: togglePanel,
  } = useDisclosure({ defaultIsOpen: true });

  return (
    <>
      <DataController />
      <MapErrorBoundary>
        {/*  TODO: Diagnose Map "get() on null" error */}
        <Map
          initialViewState={{
            longitude: -96,
            latitude: 38.5,
            zoom: 0,
          }}
          minZoom={2}
          maxZoom={23}
          style={{ flex: 1, zIndex: 0 }}
          mapStyle={"mapbox://styles/mapbox/satellite-streets-v12"}
          // mapStyle={"mapbox://styles/mapbox/streets-v12"}
          projection={isDrawing ? "mercator" : "globe"} // Globe doesn't seem to play well with Drawing
          attributionControl={false}
          pitchWithRotate={false}
        >
          <ImageLoader
            images={{
              home: "/assets/home_icon.png",
              site: "/assets/site_icon.png",
              obstacleFill: "/assets/obstacle_fill.png",
              lineArrow: "/assets/arrow_line.png",
              zigzagLine: "/assets/zigzag_line.png",
              startInterval: "/assets/start_interval_capture_icon.png",
              stopInterval: "/assets/stop_interval_capture_icon.png",
              startVideo: "/assets/start_video_icon.png",
              stopVideo: "/assets/stop_video_icon.png",
            }}
          />
          <MapDashParamsController />
          <MapResizeListener trigger={showPanel} />
          <NavigationControl
            position={"top-right"}
            showZoom={true}
            showCompass={true}
            visualizePitch={true}
          />
          <GeocoderControl options={{ placeholder: "Go to..." }} />
          <DrawController />
          <FeatureSelectController />

          <AttributionControl
            customAttribution={"&copy; Fliteworks"}
            compact={true}
            position={"bottom-left"}
            style={{ color: "black" }}
          />
          <SiteLayers />
          <MissionLayers />
          {/*<ImageryLayers />*/}
          <DroneLayers />
        </Map>
      </MapErrorBoundary>
      <MapPanel showPanel={showPanel} togglePanel={togglePanel} />
    </>
  );
}
