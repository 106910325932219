import {
  Text,
  Box,
  Flex,
  IconButton,
  Center,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React from "react";
import shallow from "zustand/shallow";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { useParams } from "react-router-dom";
import { useDocksStore } from "../../../../state/docks";
import ImageWithFallback from "../../components/ImageWithFallback";
import { TaskTypeSpec } from "../tasks/createTask/SelectTaskType";
import { selectStatusColor } from "../../../../common/helpers";

export const DOCK_TYPES: TaskTypeSpec[] = [
  {
    id: "IDIPLOYER:NEXUS_PLUS",
    name: "IDIPLOYER Nexus+",
    icon: "/assets/docks/idiployer_nexus_plus.jpg",
  },
  {
    id: "IDIPLOYER:NEXUS_PLUS_LEGACY",
    name: "IDIPLOYER Nexus+ (Legacy)",
    icon: "/assets/docks/idiployer_nexus_plus.jpg",
  },
];
export const DOCK_TYPE_LOOKUP = new Map(DOCK_TYPES.map((v) => [v.id, v]));

export interface SelectDockProps {
  title?: string;
}
export default function SelectDock(props: SelectDockProps) {
  const params = useParams();
  const siteId = params.siteId;
  const shortcuts = useNavigationShortcuts();
  const docks = useDocksStore((state) => state.docks, shallow);

  return (
    <Box>
      <Flex mb={4}>
        <Flex flex={1} direction={"column"} justifyContent={"center"}>
          {props.title && (
            <Heading as={"h2"} fontSize={"xl"}>
              {props.title}
            </Heading>
          )}
        </Flex>
        <IconButton
          ml={4}
          aria-label={"Create Dock"}
          onClick={() => shortcuts.selectDock(siteId, "create")}
          variant={"ghost"}
          icon={<AddIcon />}
        />
      </Flex>
      <Box>
        {docks &&
          Object.values(docks)
            .filter((dock) => dock.parentSite == siteId)
            .sort((a, b) => a.dockName.localeCompare(b.dockName))
            .map((dock) => (
              <Box
                key={dock.dockId}
                mb={4}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={"whiteAlpha.200"}
                bg={"whiteAlpha.200"}
                onClick={() => shortcuts.selectDock(siteId, dock.dockId)}
                cursor={"pointer"}
                _hover={{ backgroundColor: "whiteAlpha.300" }}
                maxH={100}
                overflow={"hidden"}
              >
                <Flex>
                  <Box minW={"100px"}>
                    <ImageWithFallback
                      w={"100px"}
                      h={"100px"}
                      src={
                        DOCK_TYPE_LOOKUP.get(
                          `${dock.hardwareReg.make}:${dock.hardwareReg.model}`
                        )?.icon
                      }
                      objectFit={"cover"}
                    />
                  </Box>
                  <Box flex={1} ml={1} p={2} overflow={"hidden"}>
                    <Text>
                      <b>{dock.dockName || dock.dockId}</b>
                    </Text>
                    <Text
                      fontSize={"0.8em"}
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      {
                        DOCK_TYPE_LOOKUP.get(
                          `${dock.hardwareReg.make}:${dock.hardwareReg.model}`
                        )?.name
                      }
                    </Text>
                    <Text
                      fontSize={"0.8em"}
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      Status:{" "}
                      <span
                        style={{
                          color: selectStatusColor(dock.dockStatus),
                          lineHeight: 0,
                          verticalAlign: "middle",
                        }}
                      >
                        &#x25C9;
                      </span>{" "}
                      {dock.dockStatus}
                    </Text>
                    <Text
                      fontSize={"0.8em"}
                      overflow={"hidden"}
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                    >
                      Last seen:{" "}
                      {new Date(dock.statusTimestamp).toLocaleString()}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
        {docks &&
          !Object.values(docks).filter((dock) => dock.parentSite).length && (
            <Text color={"whiteAlpha.600"} textAlign={"center"}>
              <i>Nothing here yet.</i>
            </Text>
          )}
        {docks === null && (
          <Center height={"full"}>
            <Spinner />
          </Center>
        )}
      </Box>
    </Box>
  );
}
