import create from "zustand";
import { createTableSyncStore } from "./common";

interface DataObjectsFilter {
  parentMission?: string;
}

interface DataObjectsState {
  dataObjects: Record<string, DataObject> | null;
  setDataObjects: (dataObjects: DataObject[]) => void;
  updateDataObjects: (dataObjects: DataObject[]) => void;
  fetchDataObjects: (
    filter?: DataObjectsFilter,
    update?: boolean
  ) => Promise<void>;
  fetchDataObject: (dataObjectId: string) => Promise<void>;
}

// TODO: Make these types stricter.
const customFetchResultMutator = (result: any) => {
  const dataObjects = result.dataObjects;
  const originalUrls = result.originalUrls;
  const smallPreviewUrls = result.smallPreviewUrls;
  const largePreviewUrls = result.largePreviewUrls;
  for (let i = 0; i < dataObjects.length; i++) {
    dataObjects[i].originalUrl = originalUrls[i];
    dataObjects[i].smallPreviewUrl = smallPreviewUrls[i];
    dataObjects[i].largePreviewUrl = largePreviewUrls[i];
  }
  return dataObjects;
};

const customSingleFetchResultMutator = (result: any) => {
  const dataObject = result.dataObject;
  dataObject.originalUrl = result.originalUrl;
  dataObject.smallPreviewUrl = result.smallPreviewUrl;
  dataObject.largePreviewUrl = result.largePreviewUrl;
  return dataObject;
};
export const dataObjectsStore = createTableSyncStore<DataObjectsState>(
  "dataObject",
  false,
  "data_object",
  customFetchResultMutator,
  customSingleFetchResultMutator
);
export const useDataObjectsStore = create(dataObjectsStore);
