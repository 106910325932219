import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { API } from "aws-amplify";
import shallow from "zustand/shallow";
import { CONSTANTS } from "../../../../common/constants";
import { useSitesStore } from "../../../../state/sites";
import { useMapDrawStore } from "../../../../state/mapDraw";
import { useNavigationShortcuts } from "../../../../common/navigation";
import Editable from "../../../../components/forms/Editable";

export default function CreateSite() {
  const toast = useToast();
  const shortcuts = useNavigationShortcuts();

  const fetchSite = useSitesStore((state) => state.fetchSite);
  const [startDraw, features, endDraw] = useMapDrawStore(
    (state) => [state.startDraw, state.features, state.endDraw],
    shallow
  );

  const [siteName, setSiteName] = useState("");
  const [address, setAddress] = useState("");
  const [panelTilt, setPanelTilt] = useState(0);
  const [working, setWorking] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    startDraw(undefined, true);
    return () => {
      endDraw();
    };
  }, []);

  async function createSite() {
    setWorking(true);
    try {
      // Validate fields
      if (!address) {
        throw Error("Must provide address.");
      }
      if (!features) {
        throw Error("Must provide flight boundary.");
      }

      const response = await API.put(CONSTANTS.API_NAME, "/site", {
        body: {
          site: {
            siteName,
            address,
            panelTilt,
            flightBounds: JSON.stringify(features),
          },
        },
      });
      await fetchSite(response.siteId);
      toast({
        description: "Site created.",
        status: "success",
        duration: 2000,
      });
      shortcuts.selectSite(response.siteId);
      endDraw();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setWorking(false);
    }
  }

  return (
    <Box p={4} pb={12} overflow={"auto"} height={"full"}>
      <Flex>
        <Heading as={"h1"} fontSize={"xl"} mb={4} flex={1}>
          Create New Site
        </Heading>
        <Button
          size={"sm"}
          colorScheme={"red"}
          onClick={shortcuts.clearSelection.bind(shortcuts)}
        >
          Cancel
        </Button>
      </Flex>
      <Box my={4}>
        <Editable value={siteName} onSubmit={setSiteName} label={"Site Name"} />
      </Box>
      <Box my={4}>
        <Editable value={address} onSubmit={setAddress} label={"Address"} />
      </Box>
      <Box my={4}>
        <Editable
          inputProps={{ type: "number" }}
          value={panelTilt.toString()}
          display={(v) => `${v || ""}\u00b0`}
          onSubmit={(v) => setPanelTilt(parseFloat(v))}
          label={"Panel Tilt (from horizontal)"}
        />
      </Box>
      <Box my={4}>
        <Text mb={2}>
          <b>Flight Boundary</b>
        </Text>
        <Text mb={2}>
          Use the{" "}
          <Box
            as={"span"}
            rounded={"sm"}
            display={"inline-block"}
            verticalAlign={"text-bottom"}
            width={"1.5em"}
            height={"1.5em"}
            backgroundColor={"white"}
            className={"mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"}
          />{" "}
          button on the map to draw a boundary around the site.
        </Text>
      </Box>
      <Divider />
      <Box my={4}>
        {working ? (
          <Spinner />
        ) : (
          <Button colorScheme={"silicon"} onClick={createSite}>
            Create Site
          </Button>
        )}
      </Box>
      {error && <Text color={"red"}>{error}</Text>}
    </Box>
  );
}
