import React from "react";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function Logout() {
  useEffect(() => {
    Auth.signOut();
  }, []);

  return <Navigate to={"/"} />;
}
