import EditSchedule from "../../../../../../components/forms/EditSchedule";
import { Box, Checkbox } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

interface SimpleTestFormProps {
  schedule: Schedule;
  setTaskSchedule: (newSchedule: Schedule) => void;
  taskConfig: Record<string, string>;
  setTaskConfig: (newTaskConfig: Record<string, string>) => void;
  creating?: boolean;
}

export default function SimpleTestForm(props: SimpleTestFormProps) {
  function saveConfigFlag(property: string): (e: any) => void {
    return (e) => {
      if (e.target.checked) {
        props.setTaskConfig({
          ...props.taskConfig,
          [property]: "true",
        });
      } else {
        const newTaskConfig = { ...props.taskConfig };
        delete newTaskConfig[property];
        props.setTaskConfig(newTaskConfig);
      }
    };
  }

  useEffect(() => {
    // Set initial task config
    if (props.creating) {
      props.setTaskConfig({
        useVisualCamera: "true",
        useInfraredCamera: "true",
      });
    }
  }, []);

  return (
    <Box my={4}>
      <EditSchedule value={props.schedule} onSubmit={props.setTaskSchedule} />
      <Box>
        <Checkbox
          isChecked={Boolean(props.taskConfig?.useVisualCamera)}
          onChange={saveConfigFlag("useVisualCamera")}
        >
          Use Visual Camera
        </Checkbox>
      </Box>
      <Box>
        <Checkbox
          isChecked={Boolean(props.taskConfig?.useInfraredCamera)}
          onChange={saveConfigFlag("useInfraredCamera")}
        >
          Use IR Camera
        </Checkbox>
      </Box>
      <Box>
        <Checkbox
          isChecked={Boolean(props.taskConfig?.useSimulation)}
          onChange={saveConfigFlag("useSimulation")}
        >
          Use Simulation
        </Checkbox>
      </Box>
      <Box>
        <Checkbox
          isChecked={Boolean(props.taskConfig?.skipProcessing)}
          onChange={saveConfigFlag("skipProcessing")}
        >
          Don't Process
        </Checkbox>
      </Box>
    </Box>
  );
}
