import create from "zustand";
import { createTableSyncStore } from "./common";

interface TaskInstancesFilter {
  parentMission?: string;
}

interface TaskInstancesState {
  taskInstances: Record<string, TaskInstance> | null;
  setTaskInstances: (taskInstances: TaskInstance[]) => void;
  updateTaskInstances: (taskInstances: TaskInstance[]) => void;
  fetchTaskInstances: (
    filter?: TaskInstancesFilter,
    update?: boolean
  ) => Promise<void>;
  fetchTaskInstance: (taskInstanceId: string) => Promise<void>;
}

function customFetchResultMutator(result: any) {
  const taskInstances = result.taskInstances;
  const previewUrls = result.previewUrls;
  for (let i = 0; i < taskInstances.length; i++) {
    taskInstances[i].previewUrl = previewUrls[i];
  }
  return taskInstances;
}

function customSingleFetchResultMutator(result: any) {
  const taskInstance = result.taskInstance;
  taskInstance.previewUrl = result.previewUrl;
  return taskInstance;
}

export const taskInstancesStore = createTableSyncStore<TaskInstancesState>(
  "taskInstance",
  false,
  "task_instance",
  customFetchResultMutator,
  customSingleFetchResultMutator
);
export const useTaskInstancesStore = create(taskInstancesStore);
