import { Image, ImageProps } from "@chakra-ui/react";
import React from "react";

interface FallbackProps {
  fallbackImage?: string;
}

export default function ImageWithFallback(props: ImageProps & FallbackProps) {
  const fallbackSrc = props.fallbackImage || "/assets/no_image.png";
  const patchedProps = { ...props };
  if (!patchedProps.src) {
    patchedProps.src = fallbackSrc;
  }

  function handleError(e: any) {
    e.target.src = fallbackSrc;
  }

  return <Image onError={handleError} {...patchedProps} />;
}
