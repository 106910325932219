import React from "react";
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import EditTask from "./EditTask";
import { ChevronLeftIcon, SettingsIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { useNavigationShortcuts } from "../../../../common/navigation";

export default function TaskPanel() {
  const params = useParams();
  const siteId = params.siteId;
  const shortcuts = useNavigationShortcuts();

  return (
    <Tabs
      as={Flex}
      colorScheme={"white"}
      variant={"enclosed"}
      flexDirection={"column"}
      overflow={"hidden"}
      height={"full"}
    >
      <TabList>
        <Tab>
          <SettingsIcon />
        </Tab>
        <Flex flex={1} justifyContent={"flex-end"} alignItems={"center"}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => shortcuts.selectSite(siteId, "tasks", true)}
            mr={1}
          >
            <ChevronLeftIcon /> Back
          </Button>
        </Flex>
      </TabList>
      <TabPanels overflow={"auto"} height={"full"} pb={12}>
        <TabPanel>
          <EditTask />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
