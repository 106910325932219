import React, { useCallback, useEffect, useState } from "react";
import Editable from "./Editable";
import { Text, Box, Button, Radio, RadioGroup, Stack } from "@chakra-ui/react";

export interface EditScheduleProps {
  value?: Schedule;
  onSubmit?: (newSchedule: Schedule) => void;
}

function makeEmptySchedule(): Schedule {
  return {
    scheduleType: "ONE_OFF",
    startTime: Math.floor(new Date().setSeconds(0) / 1000),
    recurrenceType: "RATE",
    rate: { amount: 1, unit: "WEEKS" },
    cron: "* * * * * * *",
  };
}

function interpretCronRate(cron?: string) {
  if (!cron) {
    return "WEEKLY";
  }
  const [second, minute, hour, dom, month, dow, year] = cron.split(" ");
  if (dow !== "*") {
    return "WEEKLY";
  } else if (dom === "*") {
    return "DAILY";
  } else if (month === "*") {
    return "MONTHLY";
  } else {
    return "ANNUALLY";
  }
}

export default function EditSchedule(props: EditScheduleProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [schedule, setSchedule] = useState<Schedule>({
    ...makeEmptySchedule(),
    ...props.value,
  });
  const [cronRate, setCronRate] = useState(interpretCronRate(schedule.cron));
  const [error, setError] = useState("");

  function updateScheduleField(fieldname: string): (value: any) => void {
    return (value) => {
      setSchedule({ ...schedule, [fieldname]: value });
    };
  }

  function updateRateAmount(value: string) {
    if (!schedule.rate) schedule.rate = makeEmptySchedule().rate;
    if (schedule.rate) schedule.rate.amount = Number(value);
    setSchedule(schedule);
  }

  function updateRateUnit(value: string) {
    if (!schedule.rate) schedule.rate = makeEmptySchedule().rate;
    if (schedule.rate) schedule.rate.unit = value;
    setSchedule(schedule);
  }

  function focus() {
    setIsEditing(true);
  }

  function cancel() {
    setIsEditing(false);
    setSchedule({ ...makeEmptySchedule(), ...props.value });
  }

  function handleSubmit() {
    const newSchedule: Schedule = {
      scheduleType: schedule.scheduleType,
      startTime: schedule.startTime,
    };
    if (schedule.scheduleType === "RECURRING") {
      newSchedule.recurrenceType = schedule.recurrenceType;
      if (schedule.recurrenceType === "RATE") {
        newSchedule.rate = schedule.rate;
      } else if (schedule.recurrenceType === "CRON") {
        newSchedule.cron = schedule.cron;
      }
    } else if (schedule.scheduleType === "ONE_OFF") {
      if (newSchedule.startTime <= Date.now() / 1000) {
        setError("Start time must be in the future.");
        return;
      }
    }
    if (props.onSubmit) props.onSubmit(newSchedule);
    setIsEditing(false);
  }

  return (
    <>
      <Box my={4}>
        <Text mb={2}>
          <b>Schedule</b>
        </Text>
        <Stack direction={"row"}>
          <Editable
            boxProps={{ flex: 1 }}
            type={"select"}
            selectOptions={{ ONE_OFF: "Once", RECURRING: "Recurring" }}
            selectOrder={["ONE_OFF", "RECURRING"]}
            value={schedule.scheduleType}
            onSubmit={updateScheduleField("scheduleType")}
            onFocus={focus}
          />
          {schedule.scheduleType === "RECURRING" && (
            <>
              {/*<Editable*/}
              {/*  type={"select"}*/}
              {/*  selectOptions={{ RATE: "every", CRON: "each" }}*/}
              {/*  selectOrder={["RATE", "CRON"]}*/}
              {/*  value={schedule.recurrenceType}*/}
              {/*  onSubmit={updateScheduleField("recurrenceType")}*/}
              {/*/>*/}
              {schedule.recurrenceType === "CRON" && (
                <Editable
                  type={"select"}
                  selectOptions={{
                    DAILY: "day",
                    WEEKLY: "week",
                    MONTHLY: "month",
                    ANNUALLY: "year",
                  }}
                  selectOrder={["DAILY", "WEEKLY", "MONTHLY", "ANNUALLY"]}
                  value={cronRate}
                  onSubmit={setCronRate}
                  onFocus={focus}
                />
              )}
            </>
          )}
        </Stack>
      </Box>
      {schedule.scheduleType === "RECURRING" && (
        <>
          <Text mb={2}>
            <b>every</b>
          </Text>
          <Box my={4}></Box>
          {(schedule.recurrenceType === "RATE" ||
            schedule.recurrenceType == undefined) && (
            <Box my={4}>
              <Stack direction={"row"} w={"full"}>
                <Editable
                  boxProps={{ flex: 1 }}
                  inputProps={{ type: "number" }}
                  value={String(schedule.rate?.amount || 1)}
                  onSubmit={updateRateAmount}
                  onFocus={focus}
                ></Editable>
                <Editable
                  type={"select"}
                  selectOptions={{
                    MINUTES: "minutes",
                    DAYS: "days",
                    WEEKS: "weeks",
                    MONTHS: "months",
                    YEARS: "years",
                  }}
                  selectOrder={["MINUTES", "DAYS", "WEEKS", "MONTHS", "YEARS"]}
                  value={schedule.rate?.unit}
                  onSubmit={updateRateUnit}
                  onFocus={focus}
                />
              </Stack>
            </Box>
          )}
          {schedule.recurrenceType === "CRON" && (
            <>
              {cronRate === "WEEKLY" && (
                <Box my={4}>
                  <Editable
                    type={"select"}
                    label={"on"}
                    selectOptions={{
                      0: "Monday",
                      1: "Tuesday",
                      2: "Wednesday",
                      3: "Thursday",
                      4: "Friday",
                      5: "Saturday",
                      6: "Sunday",
                    }}
                    value={schedule.cron?.split(" ")[5]}
                    // onSubmit={setCronDow}
                    onFocus={focus}
                  />
                </Box>
              )}
              <Box my={4}>
                <Editable
                  type={"time"}
                  label={"at"}
                  value={"12:00PM"}
                  onFocus={focus}
                />
              </Box>
            </>
          )}
        </>
      )}
      <Box my={4}>
        <Editable
          label={"starting"}
          type={"datetime"}
          value={schedule.startTime.toString()}
          onSubmit={(v) => updateScheduleField("startTime")(parseInt(v))}
          onFocus={focus}
        />
      </Box>
      {isEditing && (
        <>
          <Button colorScheme={"silicon"} onClick={handleSubmit}>
            Save Schedule
          </Button>
          <Button ml={4} colorScheme={"red"} onClick={cancel}>
            Cancel
          </Button>
          {error && <Text color={"red"}>{error}</Text>}
        </>
      )}
    </>
  );
}
