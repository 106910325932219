import create from "zustand";
import { createTableSyncStore } from "./common";

interface MissionsFilter {
  before?: number;
  after?: number;
  // TODO: see fetchMissions in api
  // parentSite?: string
  // pageKey?: string
}

interface MissionsState {
  missions: Record<string, Mission | undefined> | null;
  setMissions: (missions: Mission[]) => void;
  updateMissions: (missions: Mission[]) => void;
  fetchMissions: (filter?: MissionsFilter, update?: boolean) => Promise<void>;
  fetchMission: (missionId: string) => Promise<void>;
}

export const missionsStore = createTableSyncStore<MissionsState>(
  "mission",
  true // TODO: Potentially many missions. Implement filters and set this to false.
);
export const useMissionsStore = create(missionsStore);
