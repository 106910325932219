import AuthChangeRedirector from "../../components/AuthChangeRedirector";
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { setValueFromEvent } from "../../common/helpers";
import React, { FormEvent, useState } from "react";
import { useUserStore } from "../../state/user";
import Loading from "../Loading";
import PasswordInput from "../../components/forms/PasswordInput";
import Link from "../../components/Link";
import { Auth } from "aws-amplify";

export default function ForgotPassword() {
  const [user, userInitialized] = useUserStore((state) => [
    state.user,
    state.isInitialized,
  ]);

  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [working, setWorking] = useState(false);
  const toast = useToast();

  async function handleSendConfirm(e: FormEvent) {
    e.preventDefault();
    setWorking(true);
    try {
      await Auth.forgotPassword(email);
      setStep(1);
    } catch (e: any) {
      console.error(e);
      toast({ description: e.message, status: "error", duration: 2000 });
    } finally {
      setWorking(false);
    }
  }

  async function handleChangePassword(e: FormEvent) {
    e.preventDefault();
    try {
      setWorking(true);
      if (password !== confirmPassword) {
        throw Error("Password confirmation does not match password.");
      }
      await Auth.forgotPasswordSubmit(email, code, password);
      setStep(2);
    } catch (e: any) {
      console.error(e);
      toast({ description: e.message, status: "error", duration: 2000 });
    } finally {
      setWorking(false);
    }
  }

  return (
    <>
      {userInitialized && !user ? (
        <Center flex={1}>
          <Container w={"sm"} maxW={"90%"} textAlign={"center"}>
            <Heading mb={4} as={"h2"}>
              Forgot Password
            </Heading>
            {step === 0 && (
              <form onSubmit={handleSendConfirm}>
                <Stack spacing={4}>
                  <Text>Enter your email to start the reset process.</Text>
                  <Input
                    textAlign={"center"}
                    placeholder={"Email"}
                    type={"email"}
                    name={"email"}
                    autoComplete={"username"}
                    onChange={setValueFromEvent(setEmail)}
                  />
                  {working ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : (
                    <Button type={"submit"} colorScheme={"silicon"}>
                      Send Confirmation Email
                    </Button>
                  )}
                </Stack>
              </form>
            )}
            {step === 1 && (
              <form onSubmit={handleChangePassword}>
                <Stack spacing={4}>
                  <Text>
                    Check your email for a confirmation code and enter it here
                    with your new password.
                  </Text>
                  <Input
                    placeholder={"Confirmation Code"}
                    type={"text"}
                    autoComplete={"confirm-code"}
                    onChange={setValueFromEvent(setCode)}
                  />
                  <PasswordInput
                    placeholder={"New Password"}
                    autoComplete={"new-password"}
                    onChange={setValueFromEvent(setPassword)}
                    onToggleShowPassword={setShowPassword}
                  />
                  <Input
                    placeholder={"Confirm New Password"}
                    type={showPassword ? "text" : "password"}
                    onChange={setValueFromEvent(setConfirmPassword)}
                    autoComplete={"new-password"}
                  />
                  {working ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : (
                    <Button type={"submit"} colorScheme={"silicon"}>
                      Reset Password
                    </Button>
                  )}
                </Stack>
              </form>
            )}
            {step == 2 && (
              <Box my={4}>
                <Text>Your password has been reset.</Text>
              </Box>
            )}
            <Box my={4}>
              <Link to={"/login"} color={"whiteAlpha.500"}>
                Back to Login
              </Link>
            </Box>
          </Container>
        </Center>
      ) : (
        <Loading />
      )}
    </>
  );
}
