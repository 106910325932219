import React from "react";
import Editable from "./Editable";
import { Box, Text } from "@chakra-ui/react";

interface SelectCoordinateProps {
  coordinate: Coordinate;
  setCoordinate: (newCoordinate: Coordinate) => void;
  label?: string;
}

export default function SelectCoordinate(props: SelectCoordinateProps) {
  return (
    <>
      {props.label && (
        <Text>
          <b>{props.label}</b>
        </Text>
      )}
      <Box mb={4}>
        <Editable
          value={props.coordinate.lat.toString()}
          label={"Latitude"}
          inputProps={{ type: "number" }}
          onSubmit={(v) =>
            props.setCoordinate({ ...props.coordinate, lat: parseFloat(v) })
          }
        />
      </Box>
      <Box my={4}>
        <Editable
          value={props.coordinate.lng.toString()}
          label={"Longitude"}
          inputProps={{ type: "number" }}
          onSubmit={(v) =>
            props.setCoordinate({ ...props.coordinate, lng: parseFloat(v) })
          }
        />
      </Box>
    </>
  );
}
