export function setValueFromEvent(
  setter: (v: any) => void
): (event: any) => void {
  // TODO: Can this type be refined?
  return (event: any) => setter(event?.target?.value);
}

export function equalOrBothInvalid(a: number, b: number) {
  return (
    a === b || // Finite numbers
    (!isFinite(a) && !isFinite(b)) // Both invalid
  );
}

export function zipEqualOrBothInvalid(a: number[], b: number[]) {
  return a
    .map((e, i) => [e, b[i]])
    .every(([ea, eb]) => equalOrBothInvalid(ea, eb));
}

export function listToMap<T>(list: T[], key: string): Record<string, T> {
  return Object.fromEntries(list.map((item: any) => [item[key], item]));
}

export function capitalize(str: string) {
  return str[0].toUpperCase() + str.substring(1);
}

export function pushOrCreate(object: any, key: string, value: any) {
  if (object[key]) object[key].push(value);
  else object[key] = [value];
}

export function timestampToReadableString(timestamp: number) {
  return new Date(timestamp * 1000).toLocaleString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "2-digit",
  });
}

export function missionIsFlying(mission: Mission) {
  return ["STARTING", "PREFLIGHT", "INFLIGHT"].includes(mission.missionStatus);
}

export function selectStatusColor(
  status: string | undefined
): string | undefined {
  switch (status) {
    case "READY":
      return "darkgreen";
    case "PREFLIGHT":
    case "INFLIGHT":
    case "POSTFLIGHT":
      return "orange";
    case "FAULT":
    case "NOT_READY":
      return "red";
    default:
      return undefined;
  }
}

export function selectBatteryColor(batteryLevel?: number | null): string {
  return batteryLevel == null // NB: Weak test for undefined OR null
    ? "transparent"
    : batteryLevel > 50
    ? "darkgreen"
    : batteryLevel > 20
    ? "darkorange"
    : "red";
}

export function selectGpsColor(gpsSignalLevel?: string | null): string {
  return gpsSignalLevel == null // NB: Weak test for undefined OR null
    ? "transparent"
    : ["LEVEL_5", "LEVEL_4"].includes(gpsSignalLevel)
    ? "darkgreen"
    : ["LEVEL_3", "LEVEL_2"].includes(gpsSignalLevel)
    ? "darkorange"
    : "red";
}

export function computeGroundSpeedKts(telemetry: DroneTelemetry) {
  if (telemetry.vX == null || telemetry.vY == null) return null; // NB: Weak test for undefined OR null
  return (
    Math.sqrt(Math.pow(telemetry.vX, 2) + Math.pow(telemetry.vY, 2)) * 1.943844
  );
}
