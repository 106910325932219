import {
  Box,
  Button,
  Code,
  Collapse,
  Flex,
  Heading,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useMissionsStore } from "../../../../state/missions";
import Editable from "../../../../components/forms/Editable";
import { ChevronLeftIcon, EditIcon } from "@chakra-ui/icons";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { useTaskInstancesStore } from "../../../../state/taskInstances";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import {
  missionIsFlying,
  timestampToReadableString,
} from "../../../../common/helpers";
import { useTasksStore } from "../../../../state/tasks";
import { TASK_TYPE_LOOKUP } from "../tasks/createTask/SelectTaskType";
import { useDronesStore } from "../../../../state/drones";
import shallow from "zustand/shallow";
import { StreamViewer } from "../../../../components/StreamViewer";
import { API } from "aws-amplify";
import { CONSTANTS } from "../../../../common/constants";

export default function MissionPanel() {
  const params = useParams();
  const siteId = params.siteId;
  const missionId = params.missionId;
  const dataObjectId = params.dataObjectId;
  const shortcuts = useNavigationShortcuts();
  const toast = useToast();

  const [drones, fetchDrone] = useDronesStore(
    (state) => [state.drones, state.fetchDrone],
    shallow
  );
  const missions = useMissionsStore((state) => state.missions, shallow);
  const tasks = useTasksStore((state) => state.tasks, shallow);
  const [taskInstances, fetchTaskInstance] = useTaskInstancesStore(
    (state) => [state.taskInstances, state.fetchTaskInstance],
    shallow
  );

  const [showMcl, setShowMcl] = useState(false);

  const mission = missionId ? missions?.[missionId] : undefined;
  const assignedDrone = mission?.assignedDrone;
  const useSimulation = mission?.missionPlan.includes("USE_SIMULATION");

  function taskName(taskInstance: TaskInstance) {
    const task = tasks?.[taskInstance.parentTask];
    var taskName = null;
    if (task) {
      taskName = TASK_TYPE_LOOKUP.get(task.taskType)?.name;
    }
    if (taskName) {
      return `${taskName} Task`;
    } else {
      return `Task ${taskInstance.parentTask}`;
    }
  }

  async function checkDataReady() {
    if (!mission) return;
    const result = await API.post(CONSTANTS.API_NAME, `/check_data_ready`, {
      body: {
        missionId: missionId,
      },
    });
    console.log(result);
    if (result.nMissingDescriptors < 0) {
      toast({
        title: "Check Data Ready",
        description: `Result: ${result.result} - Data Expectations Not Set`,
        status: "info",
        duration: 2000,
      });
    } else if (result.nMissingDescriptors > 0) {
      toast({
        title: "Check Data Ready",
        description: `Result: ${result.result} Missing Descriptors: ${result.nMissingDescriptors}`,
        status: "info",
        duration: 2000,
      });
    } else {
      toast({
        title: "Check Data Ready",
        description: `Result: ${result.result} Missing Files: ${result.nMissingFiles}`,
        status: "info",
        duration: 2000,
      });
    }
  }

  async function executeMission() {
    if (!mission) return;
    try {
      var ok = false;
      const result = await API.post(
        CONSTANTS.API_NAME,
        `/mission/${mission.missionId}`,
        { body: {} }
      );
      console.log(result);
      if (result.result == "OK") {
        ok = true;
      }
    } catch (e) {
      console.error(e);
      ok = false;
    }

    if (ok) {
      toast({
        title: "Mission Started",
        status: "success",
        duration: 2000,
      });
    } else {
      toast({
        title: "Could not start mission.",
        status: "error",
        duration: 2000,
      });
    }
  }

  useEffect(() => {
    if (mission?.assignedDrone && !drones?.[mission.assignedDrone]?.streamUrl) {
      fetchDrone(mission.assignedDrone);
    }
    if (mission?.servedTaskInstances) {
      for (let taskInstanceId of mission.servedTaskInstances) {
        fetchTaskInstance(taskInstanceId);
      }
    }

    console.log(mission);
  }, [mission]);

  return (
    <Flex flexDirection={"column"} h={"full"}>
      {/* TODO: Show mission recording here after the mission */}
      <StreamViewer
        streamUrl={assignedDrone && drones?.[assignedDrone]?.streamUrl}
      />
      <Tabs
        as={Flex}
        colorScheme={"white"}
        variant={"enclosed"}
        flexDirection={"column"}
        overflow={"hidden"}
        height={"full"}
      >
        <TabList>
          <Tab>Mission Info</Tab>
          <Flex flex={1} justifyContent={"flex-end"} alignItems={"center"}>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => shortcuts.selectSite(siteId, "missions", true)}
              mr={1}
            >
              <ChevronLeftIcon /> Back
            </Button>
          </Flex>
        </TabList>
        <TabPanels overflow={"auto"} height={"full"} pb={12}>
          <TabPanel>
            <Box p={4}>
              <Heading as={"h1"} fontSize={"xl"} mb={4}>
                {mission
                  ? timestampToReadableString(mission.startTime)
                  : `Mission ${missionId}`}
              </Heading>
              {useSimulation && (
                <Box bg={"yellow"} color={"black"} textAlign={"center"}>
                  <Text>
                    <b>SIMULATED</b>
                  </Text>
                </Box>
              )}
              <Box my={4}>
                <Editable
                  value={missionId || ""}
                  label={"Mission ID"}
                  disabled={true}
                  selectAll={true}
                />
              </Box>
              <Box my={4}>
                <Editable
                  value={mission?.missionStatus || ""}
                  label={"Mission Status"}
                  disabled={true}
                  selectAll={true}
                />
                {mission?.statusTimestamp && (
                  <Text fontSize={"sm"} ml={4}>
                    Updated:{" "}
                    {new Date(mission.statusTimestamp).toLocaleString()}{" "}
                  </Text>
                )}
              </Box>
              {mission &&
                missionIsFlying(mission) &&
                assignedDrone &&
                drones?.[assignedDrone] && (
                  <Box my={4}>
                    <Editable
                      value={drones[assignedDrone].droneStatus || ""}
                      label={"Drone Status"}
                      disabled={true}
                      selectAll={true}
                    />
                    {drones[assignedDrone].droneStatusReasons?.length > 0 && (
                      <Text fontSize={"sm"} ml={4}>
                        {drones[assignedDrone].droneStatusReasons?.replace(
                          " ",
                          "\n"
                        )}
                      </Text>
                    )}
                    {drones[assignedDrone].statusTimestamp && (
                      <Text fontSize={"sm"} ml={4}>
                        Updated:{" "}
                        {new Date(
                          drones[assignedDrone].statusTimestamp
                        ).toLocaleString()}{" "}
                      </Text>
                    )}
                  </Box>
                )}
              <Box my={4}>
                <Text>
                  <b>Task Instances</b>
                </Text>
                {mission?.servedTaskInstances?.map((taskInstanceId) => (
                  <Box
                    key={taskInstanceId}
                    my={4}
                    p={4}
                    borderRadius={"md"}
                    borderWidth={1}
                    borderColor={"whiteAlpha.200"}
                    position={"relative"}
                  >
                    {taskInstances?.[taskInstanceId] && (
                      <IconButton
                        aria-label={"Open Task Instance"}
                        icon={<EditIcon />}
                        variant={"ghost"}
                        position={"absolute"}
                        top={2}
                        right={2}
                        onClick={() =>
                          shortcuts.selectTaskInstance(
                            siteId,
                            taskInstances[taskInstanceId].parentTask,
                            taskInstances[taskInstanceId].taskInstanceId
                          )
                        }
                      />
                    )}
                    {taskInstances?.[taskInstanceId] ? (
                      <Text>
                        Instance of {taskName(taskInstances[taskInstanceId])}
                      </Text>
                    ) : (
                      <Text>Task Instance</Text>
                    )}
                    <Text fontSize={"xs"}>ID: {taskInstanceId}</Text>
                    {taskInstances?.[taskInstanceId] && (
                      <Text fontSize={"xs"}>
                        Status:{" "}
                        {taskInstances[taskInstanceId].taskInstanceStatus ||
                          "UNKNOWN"}
                        {taskInstances[taskInstanceId].statusTimestamp &&
                          ` (${new Date(
                            taskInstances[taskInstanceId].statusTimestamp
                          ).toLocaleString()})`}
                      </Text>
                    )}
                  </Box>
                ))}
              </Box>
              {missionId && (
                <Box my={4}>
                  <Text onClick={() => setShowMcl(!showMcl)} cursor={"pointer"}>
                    <Icon
                      as={showMcl ? FaCaretDown : FaCaretRight}
                      verticalAlign={"middle"}
                    />{" "}
                    <b>Mission Commands</b>
                  </Text>
                  <Collapse in={showMcl}>
                    <Code as={"pre"} my={2}>
                      {(() => {
                        try {
                          return JSON.stringify(
                            JSON.parse(
                              missions?.[missionId]?.missionPlan || ""
                            ),
                            null,
                            2
                          );
                        } catch (e) {
                          return null;
                        }
                      })()}
                    </Code>
                  </Collapse>
                </Box>
              )}
            </Box>

            <Box background={"silicon.900"} borderRadius={"md"} p={4} my={4}>
              <Heading as={"h2"} fontSize={"lg"} mb={4}>
                Mission Actions
              </Heading>
              <Box my={4}>
                <Button colorScheme={"yellow"} onClick={executeMission}>
                  Run Mission Now
                </Button>
              </Box>
              <Box my={4}>
                <Button colorScheme={"silicon"} onClick={checkDataReady}>
                  Check Data Ready
                </Button>
              </Box>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
