import React from "react";
import { Button } from "@chakra-ui/react";
import Link from "./Link";

interface HoverLinkProps {
  to: string;
  onClick?: (e: Event) => void;
  linkProps?: any;
  buttonProps?: any;
  children?: string | JSX.Element;
}

export default function HoverLink(props: HoverLinkProps) {
  return (
    <Link
      to={props.to}
      onClick={props.onClick}
      _hover={{ textDecoration: "none" }}
      {...props.linkProps}
    >
      <Button
        variant={"ghost"}
        borderRadius={0}
        p={8}
        w={"full"}
        justifyContent={"flex-start"}
        fontWeight={"normal"}
        {...props.buttonProps}
      >
        {props.children}
      </Button>
    </Link>
  );
}
