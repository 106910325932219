import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import create from "zustand";
import createVanilla from "zustand/vanilla";

interface UserState {
  isInitialized: boolean;
  user: CognitoUser | null;
  attributes: Record<string, string>;
  setUser: (user: CognitoUser) => void;
  clearUser: () => void;
  reloadUserAttributes: () => void;
}

async function mapUserAttributes(user: CognitoUser) {
  const attributesList = await Auth.userAttributes(user);
  return Object.fromEntries(attributesList.map((e) => [e.Name, e.Value]));
}

export const userStore = createVanilla<UserState>((set, getState) => ({
  isInitialized: false,
  user: null,
  attributes: {},
  setUser: async (user: CognitoUser) => {
    const attributes = await mapUserAttributes(user);
    set(() => ({ user, attributes, isInitialized: true }));
  },
  clearUser: () =>
    set(() => ({ user: null, attributes: {}, isInitialized: true })),
  reloadUserAttributes: async () => {
    const state = getState();
    if (!state.user) {
      console.warn("Cannot reload user attributes: no user.");
      return;
    }
    const attributes = await mapUserAttributes(state.user);
    set({ attributes });
  },
}));
export const useUserStore = create(userStore);
