import { Box, Button, Text } from "@chakra-ui/react";
import Link from "../../../../../components/Link";
import React, { useEffect, useState } from "react";
import { useDroneTelemetryStore } from "../../../../../state/droneTelemetry";
import shallow from "zustand/shallow";
import { useDockStateStore } from "../../../../../state/dockState";

interface DockStateProps {
  dockId: string;
}

export default function DockState(props: DockStateProps) {
  const { dockState } = useDockStateStore((state) => state, shallow);
  const [state, setState] = useState<DockState | null>(null);

  useEffect(() => {
    if (props.dockId && dockState?.[props.dockId]) {
      setState(dockState[props.dockId]);
    }
  }, [dockState]);

  return state ? (
    <Box>
      <Text>
        <b>Last update:</b> {new Date(state.timestamp).toLocaleString()}
      </Text>
      <Text background={state.bayFullyClosed ? "darkgreen" : "red"}>
        <b>
          Drawer:{" "}
          {state.bayFullyOpen
            ? "FULLY OPEN"
            : state.bayFullyClosed
            ? "FULLY CLOSED"
            : "PARTIALLY OPEN"}
        </b>
      </Text>
      <Text>
        <b>RC Power:</b> {state.rcOn ? "ON" : "OFF"}
      </Text>
      <Text>
        <b>Drone Power:</b> {state.droneOn ? "ON" : "OFF"}
      </Text>
      <Text>
        <b>Internal Light:</b> {state.lightOn ? "ON" : "OFF"}
      </Text>
      <Text>
        <b>Charger:</b> {state.droneChargerOn ? "ON" : "OFF"}
      </Text>
    </Box>
  ) : (
    <Text>Unknown state.</Text>
  );
}
