import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Editable from "../../../../components/forms/Editable";
import { useDataObjectsStore } from "../../../../state/dataObjects";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigationShortcuts } from "../../../../common/navigation";

export default function DataObjectPanel() {
  const params = useParams();
  const dataObjects = useDataObjectsStore((state) => state.dataObjects);
  const shortcuts = useNavigationShortcuts();

  return (
    <Tabs
      as={Flex}
      colorScheme={"white"}
      variant={"enclosed"}
      flexDirection={"column"}
      overflow={"hidden"}
      height={"full"}
    >
      <TabList>
        <Tab>Data Object Info</Tab>
        <Flex flex={1} justifyContent={"flex-end"} alignItems={"center"}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() =>
              shortcuts.selectMission(params.siteId, params.missionId)
            }
            mr={1}
          >
            <ChevronLeftIcon /> Back
          </Button>
        </Flex>
      </TabList>
      <TabPanels overflow={"auto"} height={"full"} pb={12}>
        <TabPanel>
          <Heading as={"h1"} fontSize={"xl"} mb={4}>
            Data Object: {params.dataObjectId}
          </Heading>
          <Box my={4}>
            <Editable
              label={"Filename"}
              value={
                (params.dataObjectId &&
                  dataObjects?.[params.dataObjectId]?.uri.split("/").pop()) ||
                ""
              }
              disabled={true}
              selectAll={true}
            />
          </Box>
          {params.dataObjectId && dataObjects?.[params.dataObjectId] && (
            <>
              <Image
                src={
                  (params.dataObjectId &&
                    dataObjects[params.dataObjectId].smallPreviewUrl) ||
                  undefined
                }
              />
              <Box my={4}>
                <Button
                  as={Link}
                  href={
                    (params.dataObjectId &&
                      dataObjects[params.dataObjectId].originalUrl) ||
                    undefined
                  }
                  isExternal={true}
                  _hover={{ textDecoration: "none" }}
                >
                  Download Original
                </Button>
              </Box>
            </>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
