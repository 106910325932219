import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { CognitoUser } from "@aws-amplify/auth";
import { mqtt, iot } from "aws-iot-device-sdk-v2";

export const QOS = mqtt.QoS;
export let MQTT: mqtt.MqttClientConnection | null = null;

export function handleMessage(
  handler: (message: { topic: string; payload: string }) => void
): mqtt.OnMessageCallback {
  return (topic, payload, dup, qos, retain) => {
    const decoder = new TextDecoder("utf8");
    let decodedPayload = decoder.decode(new Uint8Array(payload));
    handler({ topic, payload: decodedPayload });
  };
}

export async function connectMQTT() {
  const uuid = uuidv4();
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  const attributes = await Auth.userAttributes(user);
  const orgId = attributes.find((a) => a.Name == "custom:org_id")?.Value;
  if (!orgId) throw Error("User does not have orgId.");
  const clientId = `${orgId}/web_${uuid}`;

  const credentials = await Auth.currentUserCredentials();
  let config = iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
    .with_clean_session(true)
    .with_client_id(clientId)
    .with_endpoint(process.env.REACT_APP_AWS_IOT_ENDPOINT!)
    .with_credentials(
      process.env.REACT_APP_AWS_REGION!,
      credentials.accessKeyId,
      credentials.secretAccessKey,
      credentials.sessionToken
    )
    // .with_keep_alive_seconds(30)
    .build();

  const client = new mqtt.MqttClient();
  const connection = client.new_connection(config);
  connection.on("connect", (session_present) => {
    console.log("Connected.");
    MQTT = connection;
  });
  connection.on("interrupt", (error) => {
    console.log(`Connection interrupted: error=${error}`);
  });
  connection.on("resume", (return_code, session_present) => {
    console.log(
      `Resumed: rc: ${return_code} existing session: ${session_present}`
    );
  });
  connection.on("disconnect", () => {
    console.log("Disconnected");
    MQTT = null;
  });
  connection.on("error", (error) => {
    console.log(`Connection error: error=${error}`);
    MQTT = null;
  });

  console.log("Connecting to MQTT with client ID:", clientId);
  return connection.connect();
}

export function disconnectMQTT() {
  MQTT?.disconnect();
}
