import React, { useEffect } from "react";
import { Layer, Marker, Source, useMap } from "react-map-gl";
import { useDroneTelemetryStore } from "../../../state/droneTelemetry";
import { useDronesStore } from "../../../state/drones";
import { useMissionsStore } from "../../../state/missions";
import { useNavigationShortcuts } from "../../../common/navigation";
import {
  computeGroundSpeedKts,
  missionIsFlying,
  selectBatteryColor,
} from "../../../common/helpers";

function groundSpeed(vX: number, vY: number) {
  return Math.sqrt(Math.pow(vX, 2) + Math.pow(vY, 2));
}

interface DroneMarkerProps {
  droneId: string;
  telemetry: DroneTelemetry | null;
}

function DroneMarker(props: DroneMarkerProps) {
  const droneId = props.droneId;
  const telemetry = props.telemetry;
  const drones = useDronesStore((state) => state.drones);
  const missions = useMissionsStore((state) => state.missions);

  const map = useMap();
  const shortcuts = useNavigationShortcuts();

  // console.log(telemetry);

  function handleDroneClick() {
    var activeMission: string | null = null;
    if (missions) {
      for (let mission of Object.values(missions)) {
        if (
          mission &&
          mission.assignedDrone === droneId &&
          missionIsFlying(mission)
        ) {
          activeMission = mission.missionId;
        }
      }
    }
    if (activeMission) {
      const mission = missions?.[activeMission];
      if (mission) {
        shortcuts.selectMission(mission.parentSite, mission.missionId);
      }
    } else {
      const drone = drones?.[droneId];
      if (drone) {
        shortcuts.selectDrone(drone.parentSite, drone.droneId);
      }
    }
  }

  return telemetry && telemetry.lastKnownLocation ? (
    <>
      <Marker
        key={droneId}
        onClick={handleDroneClick}
        longitude={telemetry.lastKnownLocation.longitude}
        latitude={telemetry.lastKnownLocation.latitude}
        rotation={telemetry.yaw || 0}
      >
        <img
          src={
            telemetry.location?.latitude && telemetry.yaw !== null
              ? "/assets/drone_icon.png"
              : "/assets/drone_unknown_icon.png"
          }
          alt={`Drone ${droneId}`}
        />
      </Marker>
      <Marker
        key={`${droneId}-attitude`}
        longitude={telemetry.lastKnownLocation.longitude}
        latitude={telemetry.lastKnownLocation.latitude}
        offset={[38, 0]}
        anchor={"left"}
      >
        <div className={"map-drone-label"}>
          {telemetry.location?.altitude != null
            ? (telemetry.location.altitude * 3.28084).toFixed(1)
            : "??"}
          ft
          {telemetry.vZ == null
            ? "??"
            : Math.abs(telemetry.vZ) > 0 &&
              `${telemetry.vZ < 0 ? "+" : ""}${
                telemetry.vZ ? (-telemetry.vZ * 3.28084).toFixed(1) : ""
              }`}
          <br />
          {computeGroundSpeedKts(telemetry)?.toFixed(1) ?? "??"}kt
          <br />
          {telemetry.yaw == null ? "??" : telemetry.yaw.toFixed(1)}&deg;
        </div>
      </Marker>
      <Marker
        key={`${droneId}-status`}
        longitude={telemetry.lastKnownLocation.longitude}
        latitude={telemetry.lastKnownLocation.latitude}
        offset={[-38, 0]}
        anchor={"right"}
      >
        <div
          className={"map-drone-label"}
          style={{
            backgroundColor: selectBatteryColor(telemetry.batteryPercent),
          }}
        >
          {telemetry.batteryPercent ?? "??"}%
        </div>
      </Marker>
    </>
  ) : null;
}

interface DroneHomeMarkerProps {
  drone: Drone;
}

function DroneHomeMarker(props: DroneHomeMarkerProps) {
  const drone = props.drone;

  return drone.homeLocation ? (
    <Source
      key={`${drone.droneId}-home`}
      type={"geojson"}
      data={{
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [drone.homeLocation.lng, drone.homeLocation.lat],
        },
      }}
    >
      <Layer
        type={"symbol"}
        paint={{}}
        layout={{
          "icon-image": "home",
          "icon-allow-overlap": true,
        }}
        minzoom={15}
      />
    </Source>
  ) : null;
}

export default function DroneLayers() {
  const droneTelemetry = useDroneTelemetryStore(
    (state) => state.droneTelemetry
  );
  const drones = useDronesStore((state) => state.drones);

  return (
    <>
      {Object.entries(droneTelemetry).map(([droneId, telemetry]) => (
        <DroneMarker key={droneId} droneId={droneId} telemetry={telemetry} />
      ))}
      {/*{Object.entries(droneTelemetry).map(([droneId, telemetry]) =>*/}
      {/*  telemetry &&*/}
      {/*  telemetry.homeLocation &&*/}
      {/*  telemetry.homeLocation.longitude !== null &&*/}
      {/*  telemetry.homeLocation.latitude !== null ? (*/}
      {/*    <Marker*/}
      {/*      key={`${droneId}_home`}*/}
      {/*      longitude={telemetry.homeLocation.longitude}*/}
      {/*      latitude={telemetry.homeLocation.latitude}*/}
      {/*    >*/}
      {/*      <img*/}
      {/*        src={"/assets/home_symbol.png"}*/}
      {/*        alt={`Home of Drone ${droneId}`}*/}
      {/*      />*/}
      {/*    </Marker>*/}
      {/*  ) : null*/}
      {/*)}*/}
      {drones &&
        Object.values(drones).map((drone) => (
          <DroneHomeMarker key={drone.droneId} drone={drone} />
        ))}
    </>
  );
}
