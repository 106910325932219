import { useMapFeatureSelectStore } from "../../../state/mapFeatureSelect";
import { Layer, Source, useMap } from "react-map-gl";
import React, { useEffect } from "react";
import GeoJSON from "geojson";

interface SelectableFeatureProps {
  feature: GeoJSON.Feature;
  index: number;
}

function SelectableFeature(props: SelectableFeatureProps) {
  const feature = props.feature;
  const map = useMap();

  const [
    isMultiSelect,
    selectedFeatureIds,
    selectFeature,
    unselectFeature,
    setSelectedFeatureIds,
  ] = useMapFeatureSelectStore((state) => [
    state.isMultiSelect,
    state.selectedFeatureIds,
    state.selectFeature,
    state.unselectFeature,
    state.setSelectedFeatureIds,
  ]);

  function handleClick() {
    if (feature.id !== undefined) {
      if (selectedFeatureIds.has(feature.id.toString())) {
        unselectFeature(feature.id.toString());
      } else {
        if (isMultiSelect) selectFeature(feature.id.toString());
        else {
          setSelectedFeatureIds(new Set([feature.id.toString()]));
        }
      }
    }
  }

  useEffect(() => {
    if (!map.current) return;
    map.current.on("click", `feature-${feature.id}-fill`, handleClick);
    return () => {
      if (!map.current) return;
      map.current.off("click", `feature-${feature.id}-fill`, handleClick);
    };
  }, [
    map,
    selectedFeatureIds,
    unselectFeature,
    selectFeature,
    setSelectedFeatureIds,
  ]);

  return (
    <Source type={"geojson"} data={feature} key={feature.id}>
      <Layer
        id={`feature-${feature.id}-fill`}
        type={"fill"}
        paint={{
          "fill-color": selectedFeatureIds.has(feature.id!.toString())
            ? "red"
            : "black",
          "fill-opacity": 0.3,
        }}
      />
      <Layer
        id={`feature-${feature.id}-shape`}
        type={"line"}
        paint={{
          "line-color": selectedFeatureIds.has(feature.id!.toString())
            ? "red"
            : "black",
          "line-width": 5,
        }}
      />
      <Layer
        id={`feature-${feature.id}-label`}
        type={"symbol"}
        layout={{
          "text-field": feature.properties?.name || `Area ${props.index + 1}`,
          "text-size": 24,
          "text-font": ["Open Sans Bold"],
        }}
        paint={{
          "text-color": "#ff0",
          "text-halo-color": "#000",
          "text-halo-width": 2,
        }}
      />
    </Source>
  );
}

export default function FeatureSelectController() {
  const [isSelectingFeatures, features] = useMapFeatureSelectStore((state) => [
    state.isSelectingFeatures,
    state.features,
  ]);
  return isSelectingFeatures && features ? (
    <>
      {features.features
        .filter((feature) => feature.id !== undefined)
        .sort((a, b) => a.id!.toString().localeCompare(b.id!.toString()))
        .map((feature, index) => (
          <SelectableFeature feature={feature} index={index} />
        ))}
    </>
  ) : null;
}
