import { Box, Button, Divider, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DroneTelemetry from "./components/DroneTelemetry";
import { useDronesStore } from "../../../../state/drones";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import Link from "../../../../components/Link";
import { MQTT, QOS } from "../../../../common/mqtt";

export default function ViewDrone() {
  const toast = useToast();
  const params = useParams();
  const droneId = params.droneId;
  const drones = useDronesStore((state) => state.drones);

  const [orgId, setOrgId] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);
      setOrgId(attributes.find((a) => a.Name == "custom:org_id")?.Value);
    })();
  }, []);

  async function sendCommand(command: string) {
    if (!orgId || !droneId) return;
    await MQTT?.publish(
      `${orgId}/drones/${droneId}/commands`,
      {
        command: command,
      },
      QOS.AtMostOnce
    );
    toast({
      description: `Sent ${command} command.`,
      status: "info",
      duration: 2000,
    });
  }

  return (
    <Box p={4} pb={12} overflow={"auto"} height={"full"}>
      {droneId && drones?.[droneId] && (
        <Box>
          <Text>
            <b>Drone Status:</b>
          </Text>
          <Box p={4}>
            <Text>{drones[droneId].droneStatus}</Text>
            <Text fontSize={"sm"} ml={4}>
              {drones[droneId].droneStatusReasons?.replace(" ", "\n")}
            </Text>
          </Box>
          <Text fontSize={"sm"} ml={4}>
            Updated:{" "}
            {drones[droneId].statusTimestamp
              ? new Date(drones[droneId].statusTimestamp).toLocaleString()
              : "Unknown"}
          </Text>
        </Box>
      )}
      <Divider my={4} />
      {droneId && (
        <Box>
          <DroneTelemetry droneId={droneId} />
        </Box>
      )}
      <Divider my={4} />
      {droneId && (
        <Box>
          <Box my={4}>
            <Button onClick={() => sendCommand("REPORT_STATUS")}>
              Report Status
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendCommand("TOGGLE_VIDEO_FEED")}>
              Toggle Video Feed
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendCommand("START_LIVESTREAM")}>
              Start Live Stream
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendCommand("STOP_LIVESTREAM")}>
              Stop Live Stream
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendCommand("RESET_STATE")}>
              Reset State
            </Button>
          </Box>
        </Box>
      )}
      <Divider my={4} />
      <Box>
        <Box my={4}>
          <Button colorScheme={"silicon"} as={Link} to={`/fly/${droneId}`}>
            Fly Drone
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
