import React, { useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { API } from "aws-amplify";
import { CONSTANTS } from "../../../../common/constants";
import { useTasksStore } from "../../../../state/tasks";
import { useParams } from "react-router-dom";
import { useNavigationShortcuts } from "../../../../common/navigation";
import Editable from "../../../../components/forms/Editable";
import GridScanForm from "./createTask/forms/GridScanForm";
import EditSchedule from "../../../../components/forms/EditSchedule";
import RaptormapsForm from "./createTask/forms/RaptormapsForm";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import SimpleTestForm from "./createTask/forms/SimpleTestForm";
import SpotCheckForm from "./createTask/forms/SpotCheckForm";

export default function EditTask() {
  const toast = useToast();
  const shortcuts = useNavigationShortcuts();
  const params = useParams();
  const siteId = params.siteId;
  const taskId = params.taskId;

  const [tasks, fetchTask] = useTasksStore((state) => [
    state.tasks,
    state.fetchTask,
  ]);

  const [working, setWorking] = useState(false);
  const {
    isOpen: alertOpen,
    onOpen: openAlert,
    onClose: closeAlert,
  } = useDisclosure();

  const cancelRef = useRef<HTMLButtonElement>(null);

  function updateTaskProperty(property: string): (value: any) => void {
    return async (value) => {
      if (!taskId) return;
      if (value === tasks?.[taskId]?.[property as keyof Task]) return;
      try {
        await API.patch(CONSTANTS.API_NAME, `/task/${taskId}`, {
          body: { task: { [property]: value } },
        });
        await fetchTask(taskId);
        toast({
          description: "Updated task.",
          status: "success",
          duration: 2000,
        });
      } catch (e) {
        toast({
          title: "Failed to update task.",
          description: `${e}`,
          status: "error",
          duration: 2000,
        });
      }
    };
  }

  async function deleteTask() {
    if (!taskId) return;
    setWorking(true);
    try {
      await API.del(CONSTANTS.API_NAME, `/task/${taskId}`, {});
      await fetchTask(taskId);
      toast({
        description: "Task deleted.",
        status: "success",
        duration: 2000,
      });
      shortcuts.selectSite(siteId, "tasks");
    } catch (e) {
      toast({
        title: "Failed to delete task.",
        description: `${e}`,
        status: "error",
        duration: 2000,
      });
    }
    setWorking(false);
  }

  return (
    <Box p={4}>
      <Box mb={4}>
        <Editable
          value={taskId || ""}
          label={"Task ID"}
          disabled={true}
          selectAll={true}
        />
      </Box>
      <Box my={4}>
        <Editable
          value={(taskId && tasks?.[taskId]?.taskType) || ""}
          label={"Type"}
          disabled={true}
          selectAll={true}
        />
      </Box>
      {/*<Box my={4}>*/}
      {/*  <Editable*/}
      {/*    value={(taskId && JSON.stringify(tasks?.[taskId]?.taskConfig)) || ""}*/}
      {/*    label={"Config"}*/}
      {/*    disabled={true}*/}
      {/*    selectAll={true}*/}
      {/*  />*/}
      {/*</Box>*/}
      {taskId &&
        (tasks?.[taskId]?.taskType === "GRID_SCAN" ? (
          <Box my={4}>
            <GridScanForm
              siteId={siteId || ""}
              taskConfig={tasks[taskId].taskConfig}
              setTaskConfig={updateTaskProperty("taskConfig")}
              schedule={tasks[taskId].schedule}
              setTaskSchedule={updateTaskProperty("schedule")}
            />
          </Box>
        ) : tasks?.[taskId]?.taskType === "RAPTORMAPS_STANDARD" ? (
          <Box my={4}>
            <RaptormapsForm
              siteId={siteId || ""}
              taskConfig={tasks[taskId].taskConfig}
              setTaskConfig={updateTaskProperty("taskConfig")}
              schedule={tasks[taskId].schedule}
              setTaskSchedule={updateTaskProperty("schedule")}
            />
          </Box>
        ) : tasks?.[taskId]?.taskType === "SIMPLE_TEST" ? (
          <Box my={4}>
            <SimpleTestForm
              schedule={tasks[taskId].schedule}
              setTaskSchedule={updateTaskProperty("schedule")}
              taskConfig={tasks[taskId].taskConfig}
              setTaskConfig={updateTaskProperty("taskConfig")}
            />{" "}
          </Box>
        ) : (
          tasks?.[taskId]?.taskType === "SPOT_CHECK" && (
            <Box my={4}>
              <SpotCheckForm
                siteId={siteId || ""}
                schedule={tasks[taskId].schedule}
                setTaskSchedule={updateTaskProperty("schedule")}
                taskConfig={tasks[taskId].taskConfig}
                setTaskConfig={updateTaskProperty("taskConfig")}
              />
            </Box>
          )
        ))}

      <Box background={"dangerAlpha.100"} borderRadius={"md"} p={4}>
        <Heading as={"h2"} fontSize={"lg"} mb={4}>
          Danger Zone
        </Heading>
        {working ? (
          <Spinner />
        ) : (
          <Flex>
            <Button colorScheme={"red"} onClick={openAlert}>
              Permanently Delete Task
            </Button>
          </Flex>
        )}
      </Box>

      <AlertDialog
        isOpen={alertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent background={"steel.800"}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Permanently Delete Task
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeAlert}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  closeAlert();
                  deleteTask();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
