import { API } from "aws-amplify";
import create from "zustand";
import createVanilla from "zustand/vanilla";
import { CONSTANTS } from "../common/constants";

interface SitesState {
  sites: Record<string, Site> | null;
  setSites: (sites: Site[]) => void;
  fetchSites: () => Promise<void>;
  fetchSite: (siteId: string) => Promise<void>;
}

// TODO: Should this be unified with createTableSyncStore, or is it sufficiently different?
export const sitesStore = createVanilla<SitesState>((set, getState) => ({
  sites: null,
  setSites: (sites) =>
    set({
      sites: Object.fromEntries(sites.map((site) => [site.siteId, site])),
    }),
  fetchSites: async () => {
    const sites = await API.get(CONSTANTS.API_NAME, "/site", {}).then((r) => {
      const sites: Site[] = r.sites;
      const thumbnails = r.thumbnailUrls;
      for (let i = 0; i < sites.length; i++) {
        if (thumbnails[i]) {
          sites[i].thumbnailUrl = thumbnails[i];
        }
      }
      return sites;
    });
    getState().setSites(sites);
  },
  fetchSite: async (siteId) => {
    const site = await API.get(CONSTANTS.API_NAME, `/site/${siteId}`, {}).then(
      (r) => {
        const site: Site = r.site;
        if (r.thumbnailUrl) {
          site.thumbnailUrl = r.thumbnailUrl;
        }
        return site;
      }
    );
    if (!site) {
      const sites = getState().sites;
      if (!sites) return;
      delete sites[siteId];
      set({ sites });
    } else {
      set({ sites: { ...getState().sites, [siteId]: site } });
    }
  },
}));
export const useSitesStore = create(sitesStore);
