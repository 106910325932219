import React, { useEffect, useState } from "react";
import GeoJSON from "geojson";
import { Layer, Source } from "react-map-gl";

type NativeMissionEntry = [[number, number] | undefined, any];

interface FollowRowVisualizationProps {
  mission: Mission;
}

export default function FollowRowVisualizationLayer(
  props: FollowRowVisualizationProps
) {
  const mission = props.mission;
  const [segments, setSegments] = useState<GeoJSON.FeatureCollection | null>(
    null
  );

  useEffect(() => {
    if (mission?.missionPlan) {
      try {
        const missionPlan = JSON.parse(mission.missionPlan);
        let newSegments: GeoJSON.Feature[] = [];
        let startLocation: [number, number] | null = null;
        for (let c of missionPlan.flight) {
          switch (c.command) {
            case "WAYPOINT":
              startLocation = [c.coordinates.lng, c.coordinates.lat];
              break;
            case "EXECUTE_NATIVE_MISSION":
              const nativeMission = c.missionPlan;
              for (let nc of nativeMission.flight) {
                if (nc.command === "WAYPOINT")
                  startLocation = [nc.coordinates.lng, nc.coordinates.lat];
              }
              break;
            case "FOLLOW_ROW":
              if (startLocation)
                newSegments.push({
                  properties: {},
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: [
                      startLocation,
                      [c.finishPoint.lng, c.finishPoint.lat],
                    ],
                  },
                });
              break;
          }
        }

        if (newSegments.length > 0) {
          const featureCollection: GeoJSON.FeatureCollection = {
            type: "FeatureCollection",
            features: newSegments,
          };
          setSegments(featureCollection);
        } else {
          setSegments(null);
        }
      } catch (e: any) {
        // Do nothing
      }
    } else {
      setSegments(null);
    }
  }, [mission]);

  return (
    <>
      {segments && (
        <Source type={"geojson"} data={segments}>
          <Layer
            id={`mission-${mission.missionId}-follow-row-segments`}
            type={"line"}
            paint={{ "line-pattern": "zigzagLine", "line-width": 8 }}
          />
        </Source>
      )}
    </>
  );
}
