import React, { useEffect, useState } from "react";
import GeoJSON from "geojson";
import { missionIsFlying } from "../../../../common/helpers";
import { Layer, Source } from "react-map-gl";

function saveLocations(
  setter: (value: GeoJSON.FeatureCollection | null) => void,
  locations: [number, number][]
) {
  if (locations.length) {
    setter({
      type: "FeatureCollection",
      features: locations.map((location) => ({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: location,
        },
      })),
    });
  } else {
    setter(null);
  }
}

interface ActionVisualizationLayerProps {
  mission: Mission;
}

export default function ActionVisualizationLayer(
  props: ActionVisualizationLayerProps
) {
  const mission = props.mission;
  const [startIntervalLocations, setStartIntervalLocations] =
    useState<GeoJSON.FeatureCollection | null>(null);
  const [stopIntervalLocations, setStopIntervalLocations] =
    useState<GeoJSON.FeatureCollection | null>(null);
  const [startVideoLocations, setStartVideoLocations] =
    useState<GeoJSON.FeatureCollection | null>(null);
  const [stopVideoLocations, setStopVideoLocations] =
    useState<GeoJSON.FeatureCollection | null>(null);

  useEffect(() => {
    if (mission?.missionPlan) {
      try {
        const missionPlan = JSON.parse(mission.missionPlan);
        let currentLocation: [number, number] | null = null;
        let newStartIntervalLocations: [number, number][] = [];
        let newStopIntervalLocations: [number, number][] = [];
        let takeOffLocations: [number, number][] = [];
        let landLocations: [number, number][] = [];
        let rthLocations: [number, number][] = [];
        let newStartVideoLocations: [number, number][] = [];
        let newStopVideoLocations: [number, number][] = [];
        for (let c of missionPlan.flight) {
          switch (c.command) {
            case "WAYPOINT":
              currentLocation = [c.coordinates.lng, c.coordinates.lat];
              break;
            case "EXECUTE_NATIVE_MISSION":
              const nativeMission = c.missionPlan;
              for (let nc of nativeMission.flight) {
                if (nc.command === "WAYPOINT")
                  currentLocation = [nc.coordinates.lng, nc.coordinates.lat];
              }
              break;
            case "FOLLOW_ROW":
              currentLocation = [c.finishPoint.lng, c.finishPoint.lat];
              break;
            case "START_INTERVAL_PHOTO_CAPTURE":
              if (currentLocation)
                newStartIntervalLocations.push(currentLocation);
              break;
            case "STOP_INTERVAL_PHOTO_CAPTURE":
              if (currentLocation)
                newStopIntervalLocations.push(currentLocation);
              break;
            case "TAKEOFF":
              if (currentLocation) takeOffLocations.push(currentLocation);
              break;
            case "LAND":
              if (currentLocation) landLocations.push(currentLocation);
              break;
            case "RTH":
              if (currentLocation) rthLocations.push(currentLocation);
              break;
            case "START_VIDEO":
              if (currentLocation) newStartVideoLocations.push(currentLocation);
              break;
            case "STOP_VIDEO":
              if (currentLocation) newStopVideoLocations.push(currentLocation);
              break;
          }
        }

        saveLocations(setStartIntervalLocations, newStartIntervalLocations);
        saveLocations(setStopIntervalLocations, newStopIntervalLocations);
        saveLocations(setStartVideoLocations, newStartVideoLocations);
        saveLocations(setStopVideoLocations, newStopVideoLocations);
      } catch (e: any) {
        // Do nothing
      }
    } else {
      setStartIntervalLocations(null);
      setStopIntervalLocations(null);
      setStartVideoLocations(null);
      setStopVideoLocations(null);
    }
  }, [mission]);

  return (
    <>
      {startIntervalLocations && (
        <Source type={"geojson"} data={startIntervalLocations}>
          <Layer
            id={`mission-${mission.missionId}-start-interval-actions`}
            type={"symbol"}
            paint={{}}
            layout={{ "icon-image": "startInterval", "icon-size": 0.6 }}
          />
        </Source>
      )}
      {stopIntervalLocations && (
        <Source type={"geojson"} data={stopIntervalLocations}>
          <Layer
            id={`mission-${mission.missionId}-stop-interval-actions`}
            type={"symbol"}
            paint={{}}
            layout={{ "icon-image": "stopInterval", "icon-size": 0.6 }}
          />
        </Source>
      )}
      {startVideoLocations && (
        <Source type={"geojson"} data={startVideoLocations}>
          <Layer
            id={`mission-${mission.missionId}-start-video-actions`}
            type={"symbol"}
            paint={{}}
            layout={{ "icon-image": "startVideo", "icon-size": 0.6 }}
          />
        </Source>
      )}
      {stopVideoLocations && (
        <Source type={"geojson"} data={stopVideoLocations}>
          <Layer
            id={`mission-${mission.missionId}-stop-video-actions`}
            type={"symbol"}
            paint={{}}
            layout={{ "icon-image": "stopVideo", "icon-size": 0.6 }}
          />
        </Source>
      )}
    </>
  );
}
