import {
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  IconButton,
  Icon,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import React, { useCallback, useState } from "react";
import shallow from "zustand/shallow";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { useParams } from "react-router-dom";
import { useTasksStore } from "../../../../state/tasks";
import { TASK_TYPE_LOOKUP } from "./createTask/SelectTaskType";
import ImageWithFallback from "../../components/ImageWithFallback";
import { timestampToReadableString } from "../../../../common/helpers";

function extraTaskDetail(task: Task) {
  switch (task.taskType) {
    case "GRID_SCAN":
      return `${task.taskConfig.scanAltitude}m, ${Math.round(
        Number(task.taskConfig.frontOverlap) * 100
      )}%/${Math.round(Number(task.taskConfig.sideOverlap) * 100)}% overlap`;
    default:
      return "";
  }
}

export default function SelectTask() {
  const params = useParams();
  const siteId = params.siteId;
  const shortcuts = useNavigationShortcuts();
  const tasks = useTasksStore((state) => state.tasks, shallow);

  return (
    <Box>
      <Flex mb={4}>
        <Box flex={1} />
        <IconButton
          ml={4}
          aria-label={"Create Task"}
          onClick={() => shortcuts.selectTask(siteId, "create")}
          variant={"ghost"}
          icon={<AddIcon />}
        />
      </Flex>
      <Box>
        {tasks &&
          Object.values(tasks)
            .filter((task) => task.parentSite === siteId)
            .sort((a, b) => a.taskId.localeCompare(b.taskId))
            .map((task) => (
              <Box
                key={task.taskId}
                mb={4}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={"whiteAlpha.200"}
                bg={"whiteAlpha.200"}
                onClick={() => shortcuts.selectTask(siteId, task.taskId)}
                cursor={"pointer"}
                _hover={{ backgroundColor: "whiteAlpha.300" }}
                maxH={100}
                overflow={"hidden"}
              >
                <Flex>
                  <Box minW={"100px"}>
                    <ImageWithFallback
                      w={"100px"}
                      h={"100px"}
                      src={TASK_TYPE_LOOKUP.get(task.taskType)?.icon}
                      objectFit={"cover"}
                    />
                  </Box>
                  <Box flex={1} ml={1} p={2} overflow={"hidden"}>
                    <Text>
                      <b>{TASK_TYPE_LOOKUP.get(task.taskType)?.name} Task</b>
                    </Text>
                    <Text
                      fontSize={"0.8em"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {task.schedule.scheduleType == "ONE_OFF"
                        ? timestampToReadableString(task.schedule.startTime)
                        : task.schedule.recurrenceType == "RATE"
                        ? `Every ${task.schedule.rate?.amount} ${task.schedule.rate?.unit}`
                        : "Custom schedule"}
                      <br />
                      {extraTaskDetail(task)}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
        {tasks &&
          !Object.values(tasks).filter((task) => task.parentSite).length && (
            <Text color={"whiteAlpha.600"} textAlign={"center"}>
              <i>Nothing here yet.</i>
            </Text>
          )}
        {tasks === null && (
          <Center height={"full"}>
            <Spinner />
          </Center>
        )}
      </Box>
    </Box>
  );
}
