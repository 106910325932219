import React, { FormEvent, useState } from "react";
import {
  Box,
  Button,
  Center,
  Heading,
  Input,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { API, Auth } from "aws-amplify";
import { CONSTANTS } from "../common/constants";
import { useUserStore } from "../state/user";
import { useOrgStore } from "../state/organizations";
import PasswordInput from "../components/forms/PasswordInput";
import { setValueFromEvent } from "../common/helpers";
import Loading from "./Loading";
import Editable from "../components/forms/Editable";

export default function Account() {
  const toast = useToast();

  const [user, userAttributes, reloadUserAttributes] = useUserStore((state) => [
    state.user,
    state.attributes,
    state.reloadUserAttributes,
  ]);
  const [org, fetchOrg] = useOrgStore((state) => [state.org, state.fetchOrg]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [changePasswordWorking, setChangePasswordWorking] = useState(false);
  const [changePasswordKey, setChangePasswordKey] = useState(0);

  function updateUserAttribute(attribute: string): (value: string) => void {
    return async (value: string) => {
      if (value === userAttributes[attribute]) return;
      await Auth.updateUserAttributes(user, { [attribute]: value });
      await reloadUserAttributes();
      toast({
        description: "Updated user.",
        status: "success",
        duration: 2000,
      });
    };
  }

  function updateOrgProperty(property: string): (value: string) => void {
    return async (value: string) => {
      if (value === org?.[property as keyof Organization]) return;
      await API.patch(CONSTANTS.API_NAME, "/org", {
        body: { org: { [property]: value } },
      });
      await fetchOrg();
      toast({
        description: "Updated organization.",
        status: "success",
        duration: 2000,
      });
    };
  }

  function updateOrgConfig(property: string): (value: string) => void {
    return async (value: string) => {
      if (value === org?.orgConfig?.[property]) return;
      await API.patch(CONSTANTS.API_NAME, "/org", {
        body: {
          org: {
            orgConfig: {
              ...org?.orgConfig,
              [property]: value,
            },
          },
        },
      });
      await fetchOrg();
      toast({
        description: "Updated organization.",
        status: "success",
        duration: 2000,
      });
    };
  }

  function resetPasswordForm() {
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setShowPassword(false);
    setChangePasswordKey(changePasswordKey + 1);
  }

  async function changePassword(e: FormEvent) {
    e.preventDefault();
    setChangePasswordWorking(true);
    try {
      if (newPassword !== confirmNewPassword)
        throw new Error("Password confirmation does not match password.");
      await Auth.changePassword(user, oldPassword, newPassword);
      toast({
        status: "success",
        description: "Password changed.",
        duration: 2000,
      });
      resetPasswordForm();
    } catch (e: any) {
      toast({ status: "error", description: e.message, duration: 2000 });
    } finally {
      setChangePasswordWorking(false);
    }
  }

  return (
    <Box
      flex={1}
      background={"steel.800"}
      p={8}
      overflow={"auto"}
      textAlign={"left"}
    >
      <Center>
        <Box w={"md"}>
          <Heading as={"h1"}>Manage Account</Heading>
          <Box mt={4}>
            <Text>
              Logged in as <b>{userAttributes.email}</b>
            </Text>
          </Box>
          <Box mt={4}>
            <Heading as={"h2"} size={"lg"}>
              User
            </Heading>
            <Box my={4}>
              <Editable
                value={userAttributes.given_name}
                onSubmit={updateUserAttribute("given_name")}
                label={"First Name"}
              />
            </Box>
            <Box my={4}>
              <Editable
                value={userAttributes.family_name}
                onSubmit={updateUserAttribute("family_name")}
                label={"Last Name"}
              />
            </Box>
            <Box my={4} key={changePasswordKey}>
              <Text mb={2}>
                <b>Change Password</b>
              </Text>
              <form onSubmit={changePassword}>
                <Stack spacing={2}>
                  <PasswordInput
                    placeholder={"Old Password"}
                    autoComplete={"current-password"}
                    onChange={setValueFromEvent(setOldPassword)}
                    onToggleShowPassword={setShowPassword}
                  />
                  <Input
                    placeholder={"New Password"}
                    autoComplete={"new-password"}
                    onChange={setValueFromEvent(setNewPassword)}
                    type={showPassword ? "text" : "password"}
                  />
                  <Input
                    placeholder={"Confirm New Password"}
                    autoComplete={"new-password"}
                    onChange={setValueFromEvent(setConfirmNewPassword)}
                    type={showPassword ? "text" : "password"}
                  />
                  {changePasswordWorking ? (
                    <Loading />
                  ) : (
                    <Button type={"submit"}>Change Password</Button>
                  )}
                </Stack>
              </form>
            </Box>
          </Box>
          <Box mt={8}>
            <Heading as={"h2"} size={"lg"}>
              Organization
            </Heading>
            {org ? (
              <>
                <Box my={4}>
                  <Editable
                    value={org.orgId}
                    label={"Organization ID"}
                    disabled={true}
                    selectAll={true}
                  />
                </Box>
                <Box my={4}>
                  <Editable
                    value={org.legalName}
                    onSubmit={updateOrgProperty("legalName")}
                    label={"Legal Name"}
                  />
                </Box>
                <Box my={4}>
                  <Editable
                    value={org.principalAddress}
                    onSubmit={updateOrgProperty("principalAddress")}
                    label={"Principal Address"}
                    type={"textarea"}
                  />
                </Box>
              </>
            ) : (
              <Spinner />
            )}
          </Box>
          <Box mt={8}>
            <Heading as={"h2"} size={"lg"}>
              RaptorMaps Integration
            </Heading>
            {org ? (
              <>
                <Box my={4}>
                  <Editable
                    value={org.orgConfig?.raptormapsOrgId || ""}
                    label={"RaptorMaps Org ID"}
                    onSubmit={updateOrgConfig("raptormapsOrgId")}
                  />
                </Box>
                <Box my={4}>
                  <Editable
                    value={org.orgConfig?.raptormapsApiKey || ""}
                    label={"RaptorMaps API Key"}
                    onSubmit={updateOrgConfig("raptormapsApiKey")}
                  />
                </Box>
              </>
            ) : (
              <Spinner />
            )}
          </Box>
        </Box>
      </Center>
    </Box>
  );
}
