import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import { ColorModeScript } from "@chakra-ui/react";
import awsExports from "./aws-exports";
import reportWebVitals from "./reportWebVitals";
import "./common/api";
import { FliteworksTheme } from "./themes/Fliteworks";
import App from "./App";

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <ColorModeScript
      initialColorMode={FliteworksTheme.config.initialColorMode}
    />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
