import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layer, Marker, Source, useMap } from "react-map-gl";
import { useSitesStore } from "../../../state/sites";
import { useMapDrawStore } from "../../../state/mapDraw";
import { useMapFeatureSelectStore } from "../../../state/mapFeatureSelect";
import centroid from "@turf/centroid";
import { MapMouseEvent } from "mapbox-gl";
import { useNavigationShortcuts } from "../../../common/navigation";
import shallow from "zustand/shallow";

const TRANSITION_ZOOM = 12;

function LayersForSite(props: any) {
  const site = props.site;

  const params = useParams();
  const map = useMap();
  const shortcuts = useNavigationShortcuts();

  function selectSite() {
    shortcuts.selectSite(site.siteId);
  }

  useEffect(() => {
    if (map.current) {
      map.current.on("click", `${site.siteId}-marker`, selectSite);
      map.current.on("click", `${site.siteId}-label`, selectSite);
      return () => {
        map.current?.off("click", `${site.siteId}-marker`, selectSite);
        map.current?.off("click", `${site.siteId}-label`, selectSite);
      };
    }
  }, [map]);

  const [isDrawing, drawingFeatureType] = useMapDrawStore(
    (state) => [state.isDrawing, state.featureType],
    shallow
  );
  const isSelectingFeatures = useMapFeatureSelectStore(
    (state) => state.isSelectingFeatures
  );

  const isDrawingSite = isDrawing && site.siteId === params.siteId;

  if (isSelectingFeatures && site.siteId === params.siteId) {
    // FeatureSelect has its own UI.
    return null;
  }

  let flightBounds;
  let pinLocation;
  try {
    flightBounds = JSON.parse(site.flightBounds);
    pinLocation = centroid(flightBounds);
  } catch (e) {
    // Do nothing
  }

  let scanAreas;
  try {
    scanAreas = JSON.parse(site.scanAreas);
  } catch (e) {
    // Do nothing
    console.log("Could not parse scan areas:", e);
  }

  let obstacles;
  try {
    obstacles = JSON.parse(site.obstacles);
  } catch (e) {
    // Do nothing
  }

  return (
    <>
      {/* Site markers at low zoom levels */}
      {pinLocation && (
        <Source type={"geojson"} data={pinLocation}>
          {/* Markers */}
          <Layer
            id={`${site.siteId}-marker`}
            type={"symbol"}
            paint={{}}
            layout={{
              "icon-image": ["image", "site"],
              "icon-allow-overlap": true,
            }}
            maxzoom={TRANSITION_ZOOM}
          />
          {/* Marker labels */}
          <Layer
            id={`${site.siteId}-label`}
            type={"symbol"}
            paint={{
              "text-color": "#ff0",
              "text-halo-color": "#000",
              "text-halo-width": 2,
            }}
            layout={{
              "text-field": site.siteName,
              "text-size": 14,
              "text-font": ["Open Sans Bold"],
              "text-anchor": "left",
              "text-offset": [1.3, 0],
              "text-justify": "left",
            }}
            maxzoom={TRANSITION_ZOOM}
          />
        </Source>
      )}
      {/* Site detail shown at high zoom levels */}
      {!(isDrawingSite && drawingFeatureType === "FLIGHT_AREA") && (
        <Source type={"geojson"} data={flightBounds}>
          {/* Site flight boundaries */}
          <Layer
            id={`site-${site.siteId}-border`}
            type={"line"}
            paint={{ "line-color": "black", "line-width": 8 }}
            layout={{ "line-join": "round" }}
            minzoom={TRANSITION_ZOOM}
          />

          <Layer
            id={`site-${site.siteId}-shape`}
            type={"line"}
            paint={{ "line-color": "yellow", "line-width": 2 }}
            layout={{ "line-join": "round" }}
            minzoom={TRANSITION_ZOOM}
          />
          {/* Site name labels */}
          {!params.taskInstanceId && (
            <Layer
              id={`site-${site.siteId}-label`}
              type={"symbol"}
              minzoom={TRANSITION_ZOOM}
              layout={{
                "text-field": site.siteName,
                "text-size": 24,
                "text-font": ["Open Sans Bold"],
              }}
              paint={{
                "text-color": "#ff0",
                "text-halo-color": "#000",
                "text-halo-width": 2,
              }}
            />
          )}
        </Source>
      )}
      {!(isDrawingSite && drawingFeatureType === "SCAN_AREA") && (
        <Source type={"geojson"} data={scanAreas}>
          {/* Site scan areas */}

          <Layer
            id={`site-${site.siteId}-scanareas-border`}
            type={"line"}
            paint={{ "line-color": "black", "line-width": 1 }}
            layout={{ "line-join": "round" }}
            minzoom={TRANSITION_ZOOM}
          />
        </Source>
      )}
      {!(isDrawingSite && drawingFeatureType === "OBSTACLE") && (
        <Source type={"geojson"} data={obstacles}>
          {/* Site obstacles */}

          <Layer
            id={`site-${site.siteId}-obstacles-border`}
            type={"line"}
            paint={{ "line-color": "red", "line-width": 2 }}
            layout={{ "line-join": "round" }}
            minzoom={TRANSITION_ZOOM}
          />
          <Layer
            id={`site-${site.siteId}-obstacles-fill`}
            type={"fill"}
            paint={{
              "fill-pattern": "obstacleFill",
            }}
            minzoom={TRANSITION_ZOOM}
          />
        </Source>
      )}
    </>
  );
}

export default function SiteLayers() {
  const sites = useSitesStore((state) => state.sites);

  return (
    <>
      {sites
        ? Object.values(sites).map((site) => (
            <LayersForSite site={site} key={site.siteId} />
          ))
        : null}
    </>
  );
}
