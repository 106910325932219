import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigationShortcuts } from "../common/navigation";
import { useUserStore } from "../state/user";

export default function AuthChangeRedirector() {
  const user = useUserStore(
    (state) => state.user,
    (a, b) => Boolean(a) == Boolean(b)
  );
  const shortcuts = useNavigationShortcuts();
  const location = useLocation();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (user && location.pathname === "/login") {
      const next = search.get("next") || "/";
      shortcuts.navigateWithDelta(next, { next: undefined });
    } else if (!user && location.pathname !== "/login") {
      shortcuts.navigateWithDelta("/login", { next: location.pathname });
    }
  }, [user]);

  return null;
}
