import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import EditSite from "./EditSite";
import { EditIcon, SettingsIcon } from "@chakra-ui/icons";
import SelectDrone from "../drones/SelectDrone";
import { useParams } from "react-router-dom";
import {
  SiteTabs,
  useNavigationShortcuts,
} from "../../../../common/navigation";
import SelectTask from "../tasks/SelectTask";
import MissionAgenda from "../missions/MissionAgenda";
import ImageWithFallback from "../../components/ImageWithFallback";
import { API } from "aws-amplify";
import { CONSTANTS } from "../../../../common/constants";
import { useSitesStore } from "../../../../state/sites";
import shallow from "zustand/shallow";
import SelectDock from "../docks/SelectDock";

const TAB_LIST = ["missions", "tasks", "drones", "settings"];
const TAB_MAP = new Map(TAB_LIST.map((v, i) => [v, i]));

export default function SitePanel() {
  const params = useParams();
  const siteId = params.siteId;
  const tab = params.tab;
  const shortcuts = useNavigationShortcuts();
  const [tabIndex, setTabIndex] = useState((tab && TAB_MAP.get(tab)) || 0);
  const [thumbnailUploading, setThumbnailUploading] = useState(false);
  const thumbnailInput = useRef<HTMLInputElement>(null);
  const [sites, fetchSite] = useSitesStore(
    (state) => [state.sites, state.fetchSite],
    shallow
  );
  const toast = useToast();

  useEffect(() => {
    if (tab === undefined) return;
    const newTabIndex = TAB_MAP.get(tab);
    if (newTabIndex !== undefined && newTabIndex !== tabIndex) {
      setTabIndex(newTabIndex);
    }
  }, [tab]);

  useEffect(() => {
    if (tabIndex < TAB_LIST.length) {
      shortcuts.selectSite(siteId, TAB_LIST[tabIndex] as SiteTabs, true);
    }
  }, [tabIndex]);

  function editSiteThumbnail() {
    if (thumbnailInput.current) {
      thumbnailInput.current.click();
    }
  }

  function handleThumbnailUpload(e: any) {
    const input = e.target;
    const file = input.files[0];
    if (file.size > CONSTANTS.MAX_UPLOAD_SIZE) {
      // TODO: This is a limitation of API Gateway
      // TODO: Not great UX - so we should use S3 upload instead
      // TODO: where there are much higher filesize limits,
      // TODO: then have updateSite modify that uploaded image.
      toast({
        description: "File cannot exceed 10MB.",
        status: "error",
        duration: 2000,
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      setThumbnailUploading(true);
      toast({
        description: "Uploading thumbnail...",
        status: "info",
        duration: 2000,
      });

      try {
        if (!siteId) {
          throw new Error("No siteId.");
        }
        if (!reader.result) {
          throw new Error("Could not get data URL.");
        }
        const [formatHead, thumbnailData] = reader.result.toString().split(",");

        // Validate format
        const format = formatHead.match(/data:([a-z/]+);/)?.[1];
        if (
          !format ||
          !["image/png", "image/jpeg", "image/jpg"].includes(format)
        ) {
          throw new Error("Unsupported format. Supported formats: PNG, JPEG");
        }

        // Update Site
        await API.patch(CONSTANTS.API_NAME, `/site/${siteId}`, {
          body: { site: {}, thumbnail: thumbnailData },
        });
        await fetchSite(siteId);
        toast({
          description: "Updated site.",
          status: "success",
          duration: 2000,
        });
      } catch (e: any) {
        console.error(e);
        toast({
          description: e.message,
          status: "error",
          duration: 2000,
        });
      } finally {
        setThumbnailUploading(false);
        input.value = null;
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  return (
    <Flex flexDirection={"column"} h={"full"}>
      <Box pos={"relative"}>
        {thumbnailUploading ? (
          <Center w={"full"} h={200} bg={"black"}>
            <Spinner />
          </Center>
        ) : (
          <>
            <ImageWithFallback
              src={siteId && sites?.[siteId]?.thumbnailUrl}
              w={"full"}
              h={200}
              objectFit={"cover"}
            />
            <IconButton
              aria-label={"Edit Site Thumbnail"}
              icon={<EditIcon />}
              position={"absolute"}
              right={2}
              top={2}
              variant={"ghost"}
              onClick={editSiteThumbnail}
            />
          </>
        )}
        <Input
          ref={thumbnailInput}
          type={"file"}
          display={"none"}
          onChange={handleThumbnailUpload}
        />
      </Box>
      <Tabs
        index={tabIndex}
        onChange={setTabIndex}
        as={Flex}
        colorScheme={"white"}
        variant={"enclosed"}
        flexDirection={"column"}
        overflow={"hidden"}
        flex={1}
        display={"flex"}
      >
        <TabList>
          <Tab>Missions</Tab>
          <Tab>Tasks</Tab>
          <Tab>Devices</Tab>
          <Tab>
            <SettingsIcon />
          </Tab>
          {/*<Flex flex={1} justifyContent={"flex-end"} alignItems={"center"}>*/}
          {/*  <Button*/}
          {/*    size={"sm"}*/}
          {/*    variant={"outline"}*/}
          {/*    onClick={shortcuts.clearSelection.bind(shortcuts)}*/}
          {/*    mr={1}*/}
          {/*  >*/}
          {/*    <ChevronLeftIcon />*/}
          {/*  </Button>*/}
          {/*</Flex>*/}
        </TabList>
        <TabPanels overflow={"hidden"} flex={1}>
          <TabPanel overflow={"hidden"} height={"full"} p={0}>
            <MissionAgenda />
          </TabPanel>
          <TabPanel overflow={"hidden"} height={"full"} p={0}>
            <Box overflow={"auto"} height={"full"} p={4} pb={12}>
              <SelectTask />
            </Box>
          </TabPanel>
          <TabPanel overflow={"hidden"} height={"full"} p={0}>
            <Box overflow={"auto"} height={"full"} p={4} pb={12}>
              <SelectDrone title={"Drones"} />
              <Divider mt={6} mb={4} />
              <SelectDock title={"Docks"} />
            </Box>
          </TabPanel>
          <TabPanel overflow={"hidden"} height={"full"} p={0}>
            <Box overflow={"auto"} height={"full"} p={4} pb={12}>
              <EditSite />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
