import React, { useEffect, useState } from "react";
import GeoJSON from "geojson";
import { Layer, Source } from "react-map-gl";

interface WaypointVisualizationLayerProps {
  mission: Mission;
  previousLocation?: [number, number];
}

export default function WaypointsVisualizationLayer(
  props: WaypointVisualizationLayerProps
) {
  const mission = props.mission;
  const [segments, setSegments] = useState<GeoJSON.FeatureCollection | null>(
    null
  );

  useEffect(() => {
    if (mission?.missionPlan) {
      try {
        const missionPlan = JSON.parse(mission.missionPlan);
        let newSegments: GeoJSON.Feature[] = [];
        let currentSegment: [number, number][] = [];
        let previousLocation: [number, number] | undefined =
          props.previousLocation;

        function finishSegment() {
          if (currentSegment.length > 0) {
            newSegments.push({
              properties: {},
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: currentSegment,
              },
            });
            if (!previousLocation) {
              currentSegment = [];
            } else {
              currentSegment = [previousLocation];
            }
          }
        }

        for (let c of missionPlan.flight) {
          switch (c.command) {
            case "WAYPOINT":
              const waypointLocation: [number, number] = [
                c.coordinates.lng,
                c.coordinates.lat,
              ];
              previousLocation = waypointLocation;
              currentSegment.push(waypointLocation);
              break;
            case "FOLLOW_ROW":
              previousLocation = [c.finishPoint.lng, c.finishPoint.lat];
              finishSegment();
              break;
            case "EXECUTE_NATIVE_MISSION":
              const nativeMission = c.missionPlan;
              for (let nc of nativeMission.flight) {
                if (nc.command === "WAYPOINT")
                  previousLocation = [nc.coordinates.lng, nc.coordinates.lat];
              }
              break;
            default:
              finishSegment();
          }
        }
        finishSegment();

        if (newSegments.length > 0) {
          const featureCollection: GeoJSON.FeatureCollection = {
            type: "FeatureCollection",
            features: newSegments,
          };
          setSegments(featureCollection);
        } else {
          setSegments(null);
        }
      } catch (e: any) {
        // Do nothing
      }
    } else {
      setSegments(null);
    }
  }, [mission]);

  return (
    <>
      {segments && (
        <Source type={"geojson"} data={segments}>
          <Layer
            id={`mission-${mission.missionId}-segments`}
            type={"line"}
            paint={{ "line-pattern": "lineArrow", "line-width": 8 }}
          />
        </Source>
      )}
    </>
  );
}
