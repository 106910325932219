import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

interface StreamViewer {
  streamUrl: string | undefined;
}

export function StreamViewer(props: StreamViewer) {
  const [streamUrl, setStreamUrl] = useState<string | null>(null);

  useEffect(() => {
    if (props.streamUrl != undefined) {
      // console.log("STREAM URL:", props.streamUrl);
      setStreamUrl(props.streamUrl);
    }
  }, [props.streamUrl]);

  return (
    <Box bgImage={"/assets/no_video.png"} bgSize={"cover"} bgPos={"center"}>
      <Box position={"relative"} pt={"56.25%"}>
        {streamUrl && (
          <iframe
            src={`${streamUrl}?autoplay=true&muted=true`}
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              background: "black",
            }}
            allow={
              "accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            }
            allowFullScreen={true}
          ></iframe>
        )}
      </Box>
    </Box>
  );
}
