import { Box, Button, Text } from "@chakra-ui/react";
import Link from "../../../../../components/Link";
import React, { useEffect, useState } from "react";
import { useDroneTelemetryStore } from "../../../../../state/droneTelemetry";
import shallow from "zustand/shallow";
import { selectBatteryColor } from "../../../../../common/helpers";

interface DroneTelemetryProps {
  droneId: string;
}

export default function DroneTelemetry(props: DroneTelemetryProps) {
  const { droneTelemetry } = useDroneTelemetryStore((state) => state, shallow);
  const [telemetry, setTelemetry] = useState<DroneTelemetry | null>(null);

  useEffect(() => {
    if (props.droneId && droneTelemetry?.[props.droneId]) {
      setTelemetry(droneTelemetry[props.droneId]);
    }
  }, [droneTelemetry]);

  return telemetry ? (
    <Box>
      <Text>
        <b>Last update:</b> {new Date(telemetry.timestamp).toLocaleString()}
      </Text>
      <Text background={telemetry.motorsOn ? "red" : "darkgreen"}>
        <b>Motors {telemetry.motorsOn ? "ON" : "OFF"}</b>
      </Text>
      <Text background={telemetry.isFlying ? "red" : "darkgreen"}>
        <b>{telemetry.isFlying ? "AIRBORNE" : "Not flying"}</b>
      </Text>
      <Text background={selectBatteryColor(telemetry.batteryPercent)}>
        <b>Battery Level: {telemetry.batteryPercent}%</b>
      </Text>
      {telemetry.rcBatteryPercent != null && ( // NB: Using weak inequality != to test undefined and null
        <Text background={selectBatteryColor(telemetry.rcBatteryPercent)}>
          <b>RC Battery Level: {telemetry.rcBatteryPercent}%</b>
        </Text>
      )}
      <Text>
        <b>Flight Time:</b> {telemetry.flightTime}s
      </Text>
      <Text>
        <b>GPS Signal Level:</b> {telemetry.gpsSignalLevel} (
        {telemetry.satelliteCount} satellites)
      </Text>
      <Text>
        <b>Home Location:</b> Lat: {telemetry.homeLocation?.latitude} Lng:{" "}
        {telemetry.homeLocation?.longitude}
      </Text>
      <Text>
        <b>Location:</b>
        <br />
        Lat: {telemetry.location?.latitude}
        <br />
        Lng: {telemetry.location?.longitude}
        <br />
        Alt: {telemetry.location?.altitude}m
      </Text>
      <Text>
        <b>Attitude:</b>
        <br />
        Pitch: {telemetry.pitch}
        <br />
        Roll: {telemetry.roll}
        <br />
        Yaw: {telemetry.yaw}
      </Text>
      <Text>
        <b>Velocity:</b>
        <br />
        X: {telemetry.vX}
        <br />
        Y: {telemetry.vY}
        <br />
        Z: {telemetry.vZ}
      </Text>
      {/*<Button*/}
      {/*  as={Link}*/}
      {/*  mt={4}*/}
      {/*  to={`?x=${telemetry.location?.longitude}&y=${telemetry.location?.latitude}&z=19`}*/}
      {/*>*/}
      {/*  Center on Drone*/}
      {/*</Button>*/}

      {/*<Code>{JSON.stringify(telemetry, null, 2)}</Code>*/}
    </Box>
  ) : (
    <Text>No telemetry.</Text>
  );
}
