import {
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";

interface PasswordInputProps extends InputProps {
  onToggleShowPassword?: (showPassword: boolean) => void;
}

export default function PasswordInput(props: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const { onToggleShowPassword, ...inputProps } = props;

  useEffect(() => {
    if (onToggleShowPassword) {
      onToggleShowPassword(showPassword);
    }
  }, [showPassword, onToggleShowPassword]);

  return (
    <InputGroup>
      <Input type={showPassword ? "text" : "password"} {...inputProps} />
      <InputRightElement>
        <IconButton
          color={"whiteAlpha.500"}
          variant={"ghost"}
          aria-label={`${showPassword ? "Hide" : "Show"} password`}
          icon={showPassword ? <ViewIcon /> : <ViewOffIcon />}
          onClick={() => setShowPassword(!showPassword)}
        />
      </InputRightElement>
    </InputGroup>
  );
}
