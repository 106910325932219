import React, { useEffect, useState } from "react";
import SelectFeatures from "../../../../../../components/forms/SelectFeatures";
import { Box, Checkbox, Text } from "@chakra-ui/react";
import EditSchedule from "../../../../../../components/forms/EditSchedule";

interface SpotCheckFormProps {
  siteId: string;
  schedule: Schedule;
  setTaskSchedule: (newSchedule: Schedule) => void;
  taskConfig: Record<string, string>;
  setTaskConfig: (newTaskConfig: Record<string, string>) => void;
  creating?: boolean;
}

interface SiteLayout {
  siteId: string;
  blocks: BlockLayout[];
}

interface BlockLayout {
  blockId: string;
  strings: StringLayout[];
}

interface StringLayout {
  stringId: string;
  modules: ModuleLayout[];
}

interface ModuleLayout {
  moduleId: string;
  center: [number, number];
  tilt: number;
}

export default function SpotCheckForm(props: SpotCheckFormProps) {
  const siteId = props.siteId;
  const [features, setFeatures] = useState("");

  function saveConfigProperty(property: string): (value: string) => void {
    return (value) => {
      props.setTaskConfig({ ...props.taskConfig, [property]: value });
    };
  }

  function saveConfigFlag(property: string): (e: any) => void {
    return (e) => {
      if (e.target.checked) {
        props.setTaskConfig({
          ...props.taskConfig,
          [property]: "true",
        });
      } else {
        const newTaskConfig = { ...props.taskConfig };
        delete newTaskConfig[property];
        props.setTaskConfig(newTaskConfig);
      }
    };
  }

  useEffect(() => {
    // Set initial task config
    if (props.creating) {
      props.setTaskConfig({
        useVisualCamera: "true",
        useInfraredCamera: "true",
      });
    }
  }, []);

  useEffect(() => {
    if (siteId) {
      fetch(
        `https://testbucket132324-dev.s3.amazonaws.com/data/shpr_co/sites/${siteId}/layout.json`
      )
        .then((response) => response.json())
        .then((layout: SiteLayout) => {
          const features = [];
          for (let block of Object.values(layout.blocks)) {
            for (let string of Object.values(block.strings)) {
              for (let module of Object.values(string.modules)) {
                features.push({
                  id: `${block.blockId}/${string.stringId}/${module.moduleId}`,
                  type: "Feature",
                  properties: {},
                  geometry: {
                    coordinates: [
                      [
                        [
                          module.center[0] - 0.000005,
                          module.center[1] - 0.000007,
                        ],
                        [
                          module.center[0] + 0.000005,
                          module.center[1] - 0.000007,
                        ],
                        [
                          module.center[0] + 0.000005,
                          module.center[1] + 0.000007,
                        ],
                        [
                          module.center[0] - 0.000005,
                          module.center[1] + 0.000007,
                        ],
                        [
                          module.center[0] - 0.000005,
                          module.center[1] - 0.000007,
                        ],
                      ],
                    ],
                    type: "Polygon",
                  },
                });
              }
            }
          }
          setFeatures(JSON.stringify({ type: "FeatureCollection", features }));
        });
    }
  }, [siteId]);

  return (
    <>
      <Box>
        <SelectFeatures
          label={"Target Entity"}
          features={features}
          selectedFeatureIds={props.taskConfig?.entityPath}
          onSubmit={saveConfigProperty("entityPath")}
          isMultiSelect={false}
        />
      </Box>
      <Box>
        <Text>Selected Entity: {props.taskConfig?.entityPath}</Text>
      </Box>
      <EditSchedule value={props.schedule} onSubmit={props.setTaskSchedule} />
      <Box>
        <Checkbox
          isChecked={Boolean(props.taskConfig?.useVisualCamera)}
          onChange={saveConfigFlag("useVisualCamera")}
        >
          Use Visual Camera
        </Checkbox>
      </Box>
      <Box>
        <Checkbox
          isChecked={Boolean(props.taskConfig?.useInfraredCamera)}
          onChange={saveConfigFlag("useInfraredCamera")}
        >
          Use IR Camera
        </Checkbox>
      </Box>
      <Box>
        <Checkbox
          isChecked={Boolean(props.taskConfig?.useSimulation)}
          onChange={saveConfigFlag("useSimulation")}
        >
          Use Simulation
        </Checkbox>
      </Box>
    </>
  );
}
