import {
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  IconButton,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import React, { useCallback, useState } from "react";
import { useSitesStore } from "../../../../state/sites";
import shallow from "zustand/shallow";
import { useNavigationShortcuts } from "../../../../common/navigation";
import { setValueFromEvent } from "../../../../common/helpers";
import ImageWithFallback from "../../components/ImageWithFallback";

export default function SelectSite() {
  const shortcuts = useNavigationShortcuts();
  const sites = useSitesStore((state) => state.sites, shallow);
  const [search, setSearch] = useState("");

  const filterSites = useCallback(
    (site: Site) => {
      return site.siteName.toLowerCase().includes(search.toLowerCase());
    },
    [search]
  );

  return (
    <Flex flexDirection={"column"} overflow={"hidden"} height={"full"}>
      <Flex p={4}>
        <InputGroup flex={1}>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.500" />}
          />
          <Input
            type="tel"
            placeholder="Search"
            onChange={setValueFromEvent(setSearch)}
          />
        </InputGroup>
        <IconButton
          ml={4}
          aria-label={"Create Site"}
          onClick={() => shortcuts.selectSite("create")}
          variant={"ghost"}
          icon={<AddIcon />}
        />
      </Flex>
      <Box overflow={"auto"} flex={1} px={4} pb={12}>
        {sites &&
          Object.values(sites)
            .filter(filterSites)
            .sort((a, b) => a.siteName.localeCompare(b.siteName))
            .map((site) => (
              <Box
                key={site.siteId}
                mb={4}
                borderRadius={"md"}
                borderWidth={1}
                borderColor={"whiteAlpha.200"}
                bg={"whiteAlpha.200"}
                onClick={() => shortcuts.selectSite(site.siteId)}
                cursor={"pointer"}
                _hover={{ backgroundColor: "whiteAlpha.300" }}
                maxH={100}
                overflow={"hidden"}
              >
                <Flex>
                  <Box minW={"100px"}>
                    <ImageWithFallback
                      w={"100px"}
                      h={"100px"}
                      src={site.thumbnailUrl}
                      objectFit={"cover"}
                    />
                  </Box>
                  <Box flex={1} ml={1} p={2} overflow={"hidden"}>
                    <Text>
                      <b>{site.siteName}</b>
                    </Text>
                    <Text
                      fontSize={"0.8em"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {site.address}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
        {sites &&
          Object.values(sites).filter(filterSites).length == 0 &&
          (Object.values(sites).length ? (
            <Text color={"whiteAlpha.600"} textAlign={"center"} mt={4}>
              <i>No sites found.</i>
            </Text>
          ) : (
            <Text color={"whiteAlpha.600"} textAlign={"center"} mt={4}>
              <i>Nothing here yet.</i>
            </Text>
          ))}
        {sites === null && (
          <Center height={"full"}>
            <Spinner />
          </Center>
        )}
      </Box>
    </Flex>
  );
}
