import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const solid = defineStyle((options) => ({
  _dark: {
    color: "white",
    background: `${options.colorScheme}.600`,
    _hover: {
      background: `${options.colorScheme}.500`,
    },
  },
}));

export const buttonTheme = defineStyleConfig({
  variants: { solid },
});
