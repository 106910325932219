import { NavigateFunction, useNavigate } from "react-router-dom";

type PanelViewType = "edit" | "view" | null;
export type SiteTabs = "missions" | "tasks" | "drones" | "settings" | null;

export function useNavigationShortcuts() {
  const navigate = useNavigate();
  return new NavigationShortcuts(navigate);
}

export class NavigationShortcuts {
  navigate: NavigateFunction;

  constructor(navigate: NavigateFunction) {
    this.navigate = navigate;
  }

  navigateWithDelta(path: string, params: Record<string, string | undefined>) {
    const search = new URLSearchParams(window.location.search);
    for (let [key, value] of Object.entries(params)) {
      if (value === undefined) {
        search.delete(key);
      } else {
        search.set(key, value);
      }
    }
    this.navigate({ pathname: path, search: `?${search.toString()}` });
  }

  navigateAndClearCamera(path: string) {
    this.navigateWithDelta(path, {
      x: undefined,
      y: undefined,
      z: undefined,
    });
  }

  navigateAndKeepCamera(path: string) {
    this.navigateWithDelta(path, {});
  }

  navigateWithCamera(path: string, keepCamera = false) {
    if (keepCamera) {
      this.navigateAndKeepCamera(path);
    } else {
      this.navigateAndClearCamera(path);
    }
  }

  clearSelection() {
    this.navigateAndClearCamera("/map");
  }

  selectSite(siteId?: string, view: SiteTabs = null, keepCamera = false) {
    if (!siteId) return;
    this.navigateWithCamera(
      `/map/site/${siteId}` + ((view && `/${view}`) || ""),
      keepCamera
    );
  }

  selectDrone(
    siteId?: string,
    droneId?: string,
    view: PanelViewType = null,
    keepCamera = false
  ) {
    if (!(siteId && droneId)) return;
    this.navigateWithCamera(
      `/map/site/${siteId}/drone/${droneId}` + ((view && `/${view}`) || ""),
      keepCamera
    );
  }

  selectDock(
    siteId?: string,
    dockId?: string,
    view: PanelViewType = null,
    keepCamera = false
  ) {
    if (!(siteId && dockId)) return;
    this.navigateWithCamera(
      `/map/site/${siteId}/dock/${dockId}` + ((view && `/${view}`) || ""),
      keepCamera
    );
  }

  selectTask(
    siteId?: string,
    taskId?: string,
    view: PanelViewType = null,
    keepCamera = false
  ) {
    if (!(siteId && taskId)) return;
    this.navigateWithCamera(
      `/map/site/${siteId}/task/${taskId}` + ((view && `/${view}`) || ""),
      keepCamera
    );
  }

  selectMission(
    siteId?: string,
    missionId?: string,
    view: PanelViewType = null,
    keepCamera = false
  ) {
    if (!(siteId && missionId)) return;
    this.navigateWithCamera(
      `/map/site/${siteId}/mission/${missionId}` + ((view && `/${view}`) || ""),
      keepCamera
    );
  }

  selectTaskInstance(
    siteId?: string,
    taskId?: string,
    taskInstanceId?: string,
    view: PanelViewType = null,
    keepCamera = false
  ) {
    if (!(siteId && taskId && taskInstanceId)) return;
    this.navigateWithCamera(
      `/map/site/${siteId}/task/${taskId}/task_instance/${taskInstanceId}` +
        ((view && `/${view}`) || ""),
      keepCamera
    );
  }

  selectDataObject(
    siteId?: string,
    missionId?: string,
    dataObjectId?: string,
    view: PanelViewType = null,
    keepCamera = false
  ) {
    if (!(siteId && missionId && dataObjectId)) return;
    this.navigateWithCamera(
      `/map/site/${siteId}/mission/${missionId}/data_object/${dataObjectId}`,
      keepCamera
    );
  }
}
