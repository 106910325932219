import { useMap } from "react-map-gl";
import { MapRef } from "react-map-gl/dist/es5";
import { useEffect } from "react";

interface ImageLoaderProps {
  images: Record<string, string>; // Map of {name: source}
}

function loadImage(map: MapRef, name: string, source: string) {
  map.loadImage(source, (err, image) => {
    if (err) console.error(err);
    if (image && !map.hasImage(name)) map.addImage(name, image);
  });
}

export default function ImageLoader(props: ImageLoaderProps) {
  const map = useMap();

  useEffect(() => {
    if (map.current) {
      for (let [name, source] of Object.entries(props.images)) {
        loadImage(map.current, name, source);
      }
    }
  }, [map]);

  return null;
}
