import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";

export default function Link(props: RouterLinkProps & ChakraLinkProps) {
  return <ChakraLink as={RouterLink} {...props} />;
}
