import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigationShortcuts } from "../../../common/navigation";
import { sitesStore, useSitesStore } from "../../../state/sites";
import { dronesStore, useDronesStore } from "../../../state/drones";
import { tasksStore, useTasksStore } from "../../../state/tasks";
import { missionsStore, useMissionsStore } from "../../../state/missions";
import {
  taskInstancesStore,
  useTaskInstancesStore,
} from "../../../state/taskInstances";
import {
  dataObjectsStore,
  useDataObjectsStore,
} from "../../../state/dataObjects";
import { docksStore, useDocksStore } from "../../../state/docks";

export default function DataController() {
  const params = useParams();
  const shortcuts = useNavigationShortcuts();

  const { fetchSites, fetchSite } = useSitesStore();
  const { fetchDrones, fetchDrone } = useDronesStore();
  const { fetchDocks, fetchDock } = useDocksStore();
  const { fetchTasks, fetchTask } = useTasksStore();
  const { fetchMissions, fetchMission } = useMissionsStore();
  const { fetchTaskInstances, fetchTaskInstance } = useTaskInstancesStore();
  const { fetchDataObjects, fetchDataObject } = useDataObjectsStore();

  useEffect(() => {
    // Load everything the first time
    fetchSites();
    fetchDrones();
    fetchDocks();
    const now = Math.round(Date.now() / 1000);
    fetchMissions({
      before: now + 2_592_000, // 1 month from now
      after: now - 604_800, // 1 week ago
    }); // TODO: Potentially many missions - implement parentSite filter with before/after and fetch on the site-level
  }, []);

  // Watch for selection updates and fetch objects
  useEffect(() => {
    const siteId = params.siteId;
    if (siteId) {
      Promise.all([
        fetchSite(siteId),
        fetchTasks(siteId, true),
        fetchDrones({ parentSite: siteId }, true),
        fetchDocks({ parentSite: siteId }, true),
      ]).then(() => {
        const sites = sitesStore.getState().sites;
        // Clear selection if site does not exist
        if (!sites?.[siteId]) shortcuts.clearSelection();
      });
    }
  }, [params.siteId]);

  useEffect(() => {
    const taskId = params.taskId;
    if (taskId) {
      fetchTask(taskId).then(() => {
        const tasks = tasksStore.getState().tasks;
        // Go to selected site if task does not exist under this site.
        if (!tasks?.[taskId] || tasks[taskId].parentSite !== params.siteId) {
          shortcuts.selectSite(params.siteId);
        }
      });
    }
  }, [params.taskId]);

  useEffect(() => {
    const droneId = params.droneId;
    if (droneId) {
      fetchDrone(droneId).then(() => {
        const drones = dronesStore.getState().drones;
        // Go to selected site if drone does not exist under this site.
        if (
          !drones?.[droneId] ||
          drones[droneId].parentSite !== params.siteId
        ) {
          shortcuts.selectSite(params.siteId);
        }
      });
    }
  }, [params.droneId]);

  useEffect(() => {
    const dockId = params.dockId;
    if (dockId) {
      fetchDock(dockId).then(() => {
        const docks = docksStore.getState().docks;
        // Go to selected site if drone does not exist under this site.
        if (!docks?.[dockId] || docks[dockId].parentSite !== params.siteId) {
          shortcuts.selectSite(params.siteId);
        }
      });
    }
  }, [params.dockId]);

  useEffect(() => {
    const missionId = params.missionId;
    if (missionId) {
      Promise.all([
        fetchMission(missionId),
        fetchTaskInstances({ parentMission: missionId }, false), // Potentially many taskInstances - don't hold in memory
        fetchDataObjects({ parentMission: missionId }, false), // Potentially many dataObjects - don't hold in memory
      ]).then(() => {
        const missions = missionsStore.getState().missions;
        // Go to selected site if mission does not exist under this site.
        if (
          !(
            missions?.[missionId] &&
            missions[missionId]?.parentSite == params.siteId
          )
        ) {
          shortcuts.selectSite(params.siteId);
        }
      });
    }
  }, [params.missionId]);

  useEffect(() => {
    const taskInstanceId = params.taskInstanceId;
    if (taskInstanceId) {
      fetchTaskInstance(taskInstanceId).then(() => {
        const taskInstances = taskInstancesStore.getState().taskInstances;
        // Go to selected site if taskInstance does not exist under this task.
        if (
          !taskInstances?.[taskInstanceId] ||
          taskInstances[taskInstanceId].parentTask !== params.taskId
        ) {
          shortcuts.selectSite(params.siteId);
        }
      });
    }
  }, [params.taskInstanceId]);

  useEffect(() => {
    const dataObjectId = params.dataObjectId;
    if (dataObjectId) {
      fetchDataObject(dataObjectId).then(() => {
        const dataObjects = dataObjectsStore.getState().dataObjects;
        // Go to selected mission if dataObject does not exist under this mission.
        if (
          !dataObjects?.[dataObjectId] ||
          dataObjects[dataObjectId].parentMission !== params.missionId
        ) {
          shortcuts.selectMission(params.siteId, params.missionId);
        }
      });
    }
  }, [params.dataObjectId]);

  return null;
}
