import { Box, Button, Divider, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDocksStore } from "../../../../state/docks";
import { API, Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import DockState from "./components/DockState";
import { CONSTANTS } from "../../../../common/constants";
import DockWeather from "./components/DockWeather";
import { doc } from "prettier";
import { MQTT, QOS } from "../../../../common/mqtt";

export default function ViewDock() {
  const params = useParams();
  const toast = useToast();

  const dockId = params.dockId;
  const docks = useDocksStore((state) => state.docks);

  const [orgId, setOrgId] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);
      setOrgId(attributes.find((a) => a.Name == "custom:org_id")?.Value);
    })();
  }, []);

  useEffect(() => {
    if (orgId && dockId) {
      sendDockCommand("REPORT_STATUS");
      sendDockCommand("REPORT_STATE");
      sendDockCommand("REPORT_WEATHER");
    }
  }, [orgId, dockId]);

  async function sendDockCommand(command: string) {
    if (!orgId || !dockId) return;
    try {
      await MQTT?.publish(
        `${orgId}/docks/${dockId}/commands`,
        command,
        QOS.AtMostOnce
      );
      toast({
        title: `Sent ${command} command.`,
        status: "info",
        duration: 2000,
      });
    } catch (e: any) {
      toast({
        title: `${command} command failed.`,
        description: String(e),
        status: "error",
        duration: 2000,
      });
    }
  }

  return (
    <Box p={4} pb={12} overflow={"auto"} height={"full"}>
      {dockId && docks?.[dockId] && (
        <Box>
          <Text>
            <b>Dock Status:</b>
          </Text>
          <Box p={4}>
            <Text>{docks[dockId].dockStatus}</Text>
            <Text fontSize={"sm"} ml={4}>
              {docks[dockId].dockStatusReasons?.replace(" ", "\n")}
            </Text>
          </Box>
          <Text fontSize={"sm"} ml={4}>
            Updated:{" "}
            {docks[dockId].statusTimestamp
              ? new Date(docks[dockId].statusTimestamp).toLocaleString()
              : "Unknown"}
          </Text>
        </Box>
      )}
      <Divider my={4} />
      {dockId && (
        <Box>
          <DockState dockId={dockId} />
        </Box>
      )}
      <Divider my={4} />
      {dockId && (
        <Box>
          <DockWeather dockId={dockId} />
        </Box>
      )}
      <Divider my={4} />
      {dockId && (
        <Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("REPORT_STATUS")}>
              Report Status
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("REPORT_STATE")}>
              Report State
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("REPORT_WEATHER")}>
              Report Weather
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("START_REMOTE_CONTROL")}>
              Start Remote Control
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("STOP_REMOTE_CONTROL")}>
              Stop Remote Control
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("OPEN_BAY")}>
              Open Drawer
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("CLOSE_BAY")}>
              Close Drawer
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("TOGGLE_RC")}>
              Toggle RC
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("POWER_ON_RC")}>
              Power On RC
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("POWER_OFF_RC")}>
              Power Off RC
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("TOGGLE_DRONE")}>
              Toggle Drone
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("POWER_ON_DRONE")}>
              Power On Drone
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("POWER_OFF_DRONE")}>
              Power Off Drone
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("TURN_ON_LIGHT")}>
              Turn On Light
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("TURN_OFF_LIGHT")}>
              Turn Off Light
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("ACTIVATE_DRONE_CHARGER")}>
              Activate Charger
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("DEACTIVATE_DRONE_CHARGER")}>
              Deactivate Charger
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("START_LIVESTREAM")}>
              Start Live Stream
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("STOP_LIVESTREAM")}>
              Stop Live Stream
            </Button>
          </Box>
          <Box my={4}>
            <Button onClick={() => sendDockCommand("INIT")}>Init Dock</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
