import React, { useEffect, useState } from "react";
import { Button, Spinner, Text } from "@chakra-ui/react";
import shallow from "zustand/shallow";
import { useMapFeatureSelectStore } from "../../state/mapFeatureSelect";
import GeoJSON from "geojson";

interface SelectFeaturesProps {
  label: string;
  features: string;
  selectedFeatureIds?: string;
  onSubmit?: (selectedFeatureIds: string) => void;
  onWorking?: (working: boolean) => void;
  disabled?: boolean;
  isMultiSelect?: boolean;
}

export default function SelectFeatures(props: SelectFeaturesProps) {
  const [
    isSelectingFeatures,
    startSelectingFeatures,
    endSelectingFeatures,
    setFeatures,
    selectedFeatureIds,
    setSelectedFeatureIds,
    setIsMultiSelect,
  ] = useMapFeatureSelectStore((state) => [
    state.isSelectingFeatures,
    state.startSelectingFeatures,
    state.endSelectingFeatures,
    state.setFeatures,
    state.selectedFeatureIds,
    state.setSelectedFeatureIds,
    state.setMultiSelect,
  ]);

  const [working, setWorking] = useState(false);
  const [featureIndex, setFeatureIndex] = useState<Record<string, number>>({});

  useEffect(() => {
    if (props.onWorking) props.onWorking(working);
  }, [working]);

  useEffect(() => {
    try {
      const features: GeoJSON.FeatureCollection = JSON.parse(props.features);
      setFeatureIndex(
        Object.fromEntries(
          features.features
            .filter((f) => f.id !== undefined)
            .sort((a, b) => a.id!.toString().localeCompare(b.id!.toString()))
            .map((f, i) => [f.id!.toString(), i])
        )
      );
      setFeatures(features);
    } catch (e: any) {
      // Do nothing
    }
  }, [props.features]);

  function setupAndStartSelectingFeatures() {
    if (props.selectedFeatureIds) {
      setSelectedFeatureIds(new Set(props.selectedFeatureIds.split(",")));
    }
    setIsMultiSelect(Boolean(props.isMultiSelect));
    startSelectingFeatures();
  }

  async function saveSelectedFeatures() {
    setWorking(true);
    endSelectingFeatures();
    if (props.onSubmit) {
      await props.onSubmit(Array.from(selectedFeatureIds.values()).join(","));
    }
    setWorking(false);
  }

  return (
    <>
      <Text mb={2}>
        <b>{props.label}</b>
      </Text>
      {isSelectingFeatures ? (
        <Text mb={2}>{selectedFeatureIds.size} areas selected</Text>
      ) : (
        props.selectedFeatureIds &&
        props.selectedFeatureIds.split(",").length > 0 && (
          <Text mb={2}>
            {props.selectedFeatureIds.split(",").length} areas selected
          </Text>
        )
      )}
      {working ? (
        <Spinner />
      ) : (
        <>
          <Button
            colorScheme={"silicon"}
            onClick={() =>
              isSelectingFeatures
                ? saveSelectedFeatures()
                : setupAndStartSelectingFeatures()
            }
            disabled={props.disabled}
          >
            {isSelectingFeatures ? "Confirm" : "Select"}
          </Button>
          {isSelectingFeatures && (
            <Button ml={4} colorScheme={"red"} onClick={endSelectingFeatures}>
              Cancel
            </Button>
          )}
        </>
      )}
    </>
  );
}
