import { useEffect, useState } from "react";
import GeoJSON from "geojson";
import { useControl, useMap } from "react-map-gl";
import type { ControlPosition } from "react-map-gl";
import MapboxDraw, { MapboxDrawOptions } from "@mapbox/mapbox-gl-draw";
// @ts-ignore // Package is pure JS and has no types definition.
import * as MapboxDrawWaypoint from "mapbox-gl-draw-waypoint";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { flightAreaStyle } from "./flightAreaStyle";
import { obstacleAreaStyle } from "./obstacleAreaStyle";
import { MapDrawFeatureType } from "../../../state/mapDraw";

interface DrawControlProps {
  onChange?: (features: GeoJSON.FeatureCollection) => void;
  position?: ControlPosition;
  options?: MapboxDrawOptions;
  features?: GeoJSON.FeatureCollection | null;
  featureType?: MapDrawFeatureType;
}

export default function DrawControl(props: DrawControlProps) {
  const map = useMap();
  const [didInit, setDidInit] = useState(false);

  function handleEvent() {
    return props.onChange!(draw.getAll());
  }

  const draw = useControl(
    () => {
      return new MapboxDraw({
        // TODO: Waypoint mode is MUCH more intuitive than default, but deletion of whole polygons is tedious because
        // TODO: you have to go vertex by vertex. Possibly can be improved.
        modes: MapboxDrawWaypoint.enable(MapboxDraw.modes), // Disable polygon dragging and simplify draw flow.
        touchBuffer: 10,
        displayControlsDefault: false,
        styles:
          props.featureType === "OBSTACLE"
            ? obstacleAreaStyle
            : flightAreaStyle,
        ...props.options,
      });
    },
    {
      position: props.position,
    }
  );

  useEffect(() => {
    if (!map.current) return;
    if (props.onChange) {
      map.current.on("draw.create", handleEvent);
      map.current.on("draw.update", handleEvent);
      map.current.on("draw.delete", handleEvent);
    }

    return () => {
      if (!map.current) return;
      if (props.onChange) {
        map.current.off("draw.create", handleEvent);
        map.current.off("draw.update", handleEvent);
        map.current.off("draw.delete", handleEvent);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!map.current) return;
    if (didInit) return;
    draw.deleteAll();
    if (props.features) {
      for (let feature of props.features.features) {
        draw.add(feature);
      }
    }
    setDidInit(true);
  }, [map, draw, props.features]);

  return null;
}

DrawControl.defaultProps = {
  position: "top-right",
};
