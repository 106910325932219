import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMap } from "react-map-gl";
import bbox from "@turf/bbox";
import { useSitesStore } from "../../../state/sites";
import {
  equalMapCameraStates,
  useMapCameraStore,
} from "../../../state/mapCamera";
import { zipEqualOrBothInvalid } from "../../../common/helpers";
import { useDataObjectsStore } from "../../../state/dataObjects";
import { useDroneTelemetryStore } from "../../../state/droneTelemetry";
import GeoJSON from "geojson";

// ARCHITECTURE NOTE: It is important to take care with URL state updates to avoid infinite loops.
// The URL can be updated by anyone at any time - whether it be a path or search params change, by the app or user.
// URL changes should be debounced to prevent looping. At the same time, useful internal state that is convenient for
// the user to be able to copy in the URL (for sharing) should be reflected into the URL.
// To debounce the URL, we use state variables to gate the change event and only fire updates if the content changes.
export default function MapDashParamsController() {
  const map = useMap();
  const params = useParams();
  const [search, setSearch] = useSearchParams();

  const sites = useSitesStore((state) => state.sites);
  const dataObjects = useDataObjectsStore((state) => state.dataObjects);
  const droneTelemetry = useDroneTelemetryStore(
    (state) => state.droneTelemetry
  );
  const mapCameraState = useMapCameraStore(
    (state) => state,
    equalMapCameraStates // Important! This gates changes to prevent loops.
  );

  // Reflect updates to mapCameraState in URL
  useEffect(() => {
    const newSearch = new URLSearchParams(search.toString());
    if (mapCameraState.cameraXYZ.every((e: number) => isFinite(e))) {
      newSearch.set("x", mapCameraState.cameraXYZ[0].toString());
      newSearch.set("y", mapCameraState.cameraXYZ[1].toString());
      newSearch.set("z", mapCameraState.cameraXYZ[2].toString());
      setSearch(newSearch, { replace: true });
    }
  }, [mapCameraState]);

  // Reflect URL search updates to mapCameraState
  useEffect(() => {
    // cameraXYZ
    const lng = parseFloat(search.get("x") ?? "NaN");
    const lat = parseFloat(search.get("y") ?? "NaN");
    const zoom = parseFloat(search.get("z") ?? "NaN");

    if (!zipEqualOrBothInvalid([lng, lat, zoom], mapCameraState.cameraXYZ)) {
      mapCameraState.setCameraXYZ(lng, lat, zoom);
    }
  }, [search]);

  // Reflect URL path params updates to mapCameraState
  useEffect(() => {
    if (sites && params.siteId) {
      // Center on site
      try {
        const features = JSON.parse(sites[params.siteId].flightBounds);
        const [b0, b1, b2, b3] = bbox(features); // Explicit expansion to match type
        if ([b0, b1, b2, b3].every((v) => isFinite(v))) {
          mapCameraState.setTargetBbox([b0, b1, b2, b3]);
        }
      } catch (e) {
        // console.error(`Could not parse flightBounds of Site ${siteId}:`, e);
      }
    }
    // else if (sites) {
    //   // Default to view fit to all sites.
    //   const sitesFeatures = Object.values(sites).reduce(
    //     (acc: GeoJSON.Feature[], site) => {
    //       try {
    //         const data: GeoJSON.FeatureCollection = JSON.parse(
    //           site.flightBounds
    //         );
    //         acc.push(...data.features);
    //         return acc;
    //       } catch (e) {
    //         return acc;
    //       }
    //     },
    //     []
    //   );
    //   // console.log(sitesFeatures);
    //   const [b0, b1, b2, b3] = bbox({
    //     type: "FeatureCollection",
    //     features: sitesFeatures,
    //   }); // Explicit expansion to match type
    //   console.log(b0, b1, b2, b3);
    //   if ([b0, b1, b2, b3].every((v) => isFinite(v))) {
    //     mapCameraState.setTargetBbox([b0, b1, b2, b3]);
    //     console.log("CENTER");
    //   }
    // }
    else mapCameraState.setTargetBbox(null);
  }, [params, sites]);

  // Reflect map changes to mapCameraState
  function handleViewChange() {
    if (!map.current) return;
    const center = map.current.getCenter();
    const zoom = map.current.getZoom();
    mapCameraState.setCameraXYZ(center.lng, center.lat, zoom);
  }

  useEffect(() => {
    map.current?.on("moveend", handleViewChange);
    return () => {
      map.current?.off("moveend", handleViewChange);
    };
  }, [map]);

  // Reflect mapCameraState changes to map.
  useEffect(() => {
    // console.log(mapCameraState);
    if (!map.current) return;
    if (mapCameraState.cameraXYZ.every((e: number) => isFinite(e))) {
      // Center on precise XYZ
      map.current.setCenter([
        mapCameraState.cameraXYZ[0],
        mapCameraState.cameraXYZ[1],
      ]);
      map.current.setZoom(mapCameraState.cameraXYZ[2]);
    } else if (mapCameraState.targetBBox !== null) {
      map.current.fitBounds(mapCameraState.targetBBox, {
        padding: 100,
        animate: false,
      });
    }
  }, [mapCameraState]);

  return null;
}
