import { Button, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { MapDrawFeatureType, useMapDrawStore } from "../../state/mapDraw";

interface EditFeaturesProps {
  label: string;
  features?: string;
  featureType?: MapDrawFeatureType;
  onSubmit?: (newFeatures: string) => void;
  onStartDraw?: () => void;
  onWorking?: (working: boolean) => void;
  disabled?: boolean;
}

export default function EditFeatures(props: EditFeaturesProps) {
  const [isDrawing, features, updateFeatures, startDraw, endDraw] =
    useMapDrawStore(
      (state) => [
        state.isDrawing,
        state.features,
        state.setFeatures,
        state.startDraw,
        state.endDraw,
      ],
      shallow
    );

  const [working, setWorking] = useState(false);

  useEffect(() => {
    if (props.onWorking) props.onWorking(working);
  }, [working]);

  function setupAndStartDraw() {
    if (props.onStartDraw) props.onStartDraw();
    try {
      if (props.features) {
        updateFeatures(JSON.parse(props.features));
      } else {
        updateFeatures(null);
      }
    } catch (e) {
      // Do nothing
      console.error(e);
    }
    startDraw(props.featureType);
  }

  async function saveDraw() {
    setWorking(true);
    const featuresString = JSON.stringify(features);
    if (props.onSubmit) await props.onSubmit(featuresString);
    endDraw();
    setWorking(false);
  }

  return (
    <>
      <Text mb={2}>
        <b>{props.label}</b>
      </Text>
      {working ? (
        <Spinner />
      ) : (
        <>
          <Button
            colorScheme={"silicon"}
            onClick={() => (isDrawing ? saveDraw() : setupAndStartDraw())}
            disabled={props.disabled}
          >
            {isDrawing ? "Save" : "Edit"}
          </Button>
          {isDrawing && (
            <Button ml={4} colorScheme={"red"} onClick={endDraw}>
              Cancel
            </Button>
          )}
        </>
      )}
    </>
  );
}
