import create from "zustand";
import createVanilla from "zustand/vanilla";

interface DroneTelemetryState {
  droneTelemetry: Record<string, DroneTelemetry | null>;
  setDroneTelemetry: (
    droneId: string,
    telemetry: DroneTelemetry | null
  ) => void;
}

export const droneTelemetryStore = createVanilla<DroneTelemetryState>(
  (set, getState) => ({
    droneTelemetry: {},
    setDroneTelemetry: (droneId, telemetry) => {
      if (telemetry) {
        if (
          telemetry.location &&
          // Weak comparison intentional to also test for undefined
          telemetry.location.latitude != null &&
          telemetry.location.longitude != null &&
          telemetry.location.altitude != null
        ) {
          telemetry.lastKnownLocation = {
            latitude: telemetry.location.latitude,
            longitude: telemetry.location.longitude,
            altitude: telemetry.location.altitude,
            timestamp: telemetry.timestamp,
          };
        } else {
          telemetry.lastKnownLocation =
            getState().droneTelemetry[droneId]?.lastKnownLocation || null;
        }
      }
      set({
        droneTelemetry: { ...getState().droneTelemetry, [droneId]: telemetry },
      });
    },
  })
);
export const useDroneTelemetryStore = create(droneTelemetryStore);
