import React, { useEffect, useState } from "react";
import { useUserStore } from "../../../../../state/user";
import { Box, Text } from "@chakra-ui/react";
import { handleMessage, MQTT, QOS } from "../../../../../common/mqtt";

interface DockWeatherProps {
  dockId: string;
}

export default function DockWeather(props: DockWeatherProps) {
  const userAttributes = useUserStore((state) => state.attributes);
  const [orgId, setOrgId] = useState<string | undefined>(undefined);
  const [weather, setWeather] = useState<DockWeather | undefined>(undefined);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    setOrgId(userAttributes["custom:org_id"]);
  }, [userAttributes]);

  useEffect(() => {
    if (orgId) {
      if (!MQTT) {
        // MQTT is not yet connected. Try again later.
        setTimeout(() => setRetry(retry + 1), 1_000);
      } else {
        const topic = `${orgId}/docks/${props.dockId}/weather`;
        MQTT?.subscribe(
          topic,
          QOS.AtMostOnce,
          handleMessage(({ payload }) => {
            const data = JSON.parse(payload);
            setWeather(data);
          })
        );
        // .then(() => console.log(`Subscribed to Dock ${props.dockId} Weather`))
        // .catch((e) =>
        //   console.error(
        //     `Could not subscribe to Dock ${props.dockId} Weather:`,
        //     e
        //   )
        // )
        return () => {
          MQTT?.unsubscribe(topic)
            .then(() =>
              console.log(`Unsubscrcibed from Dock ${props.dockId} Weather`)
            )
            .catch((e) =>
              console.error(
                `Could not unsubscribe from Dock ${props.dockId} Weather:`,
                e
              )
            );
        };
      }
    }
  }, [orgId, props.dockId, retry]);

  return weather ? (
    <Box>
      <Text>
        <b>Last update:</b> {new Date(weather.timestamp).toLocaleString()}
      </Text>
      <Text>
        <b>Wind:</b> {Math.round(weather.windSpeed * 1.943844)}|
        {Math.round(weather.windGustSpeed * 1.943844)} kt{" "}
        {weather.windDirection}&deg;
      </Text>
      <Text>
        <b>Temperature:</b> {Math.round((weather.temperature * 9) / 5 + 32)}
        &deg;F
      </Text>
      <Text>
        <b>Precipitation:</b> {(weather.rainLevel * 0.03937008).toFixed(1)}
        "/hr
      </Text>
      <Text>
        <b>Humidity:</b> {weather.humidity}%
      </Text>
      <Text>
        <b>Barometer:</b> {(weather.barometer * 0.02953).toFixed(2)}"Hg
      </Text>
      <Text>
        <b>Est. Irradiance:</b> {Math.round(weather.brightness * 0.0079)} W/m2
      </Text>
    </Box>
  ) : (
    <Text>Unknown weather.</Text>
  );
}
