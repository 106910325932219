import create from "zustand";
import { createTableSyncStore } from "./common";

interface TasksState {
  tasks: Record<string, Task> | null;
  setTasks: (tasks: Task[]) => void;
  updateTasks: (tasks: Task[]) => void;
  fetchTasks: (parentSite?: string, update?: boolean) => Promise<void>;
  fetchTask: (taskId: string) => Promise<void>;
}

export const tasksStore = createTableSyncStore<TasksState>("task", false);
export const useTasksStore = create(tasksStore);
