import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const filled = definePartsStyle({
  field: {
    _dark: {
      background: "whiteAlpha.200",
      _placeholder: {
        color: "whiteAlpha.500",
      },
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  defaultProps: {
    variant: "filled",
  },
  variants: { filled },
});
