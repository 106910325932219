import {
  Button,
  Center,
  Input,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { setValueFromEvent } from "../../common/helpers";
import PasswordInput from "../../components/forms/PasswordInput";
import React, { FormEvent, useState } from "react";
import { CognitoUser } from "@aws-amplify/auth";
import { API, Auth } from "aws-amplify";
import { CONSTANTS } from "../../common/constants";

interface CompletePasswordProps {
  user: CognitoUser;
}

export default function CompletePassword(props: CompletePasswordProps) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [working, setWorking] = useState(false);

  const toast = useToast();

  async function submitNewPassword(e: FormEvent) {
    setWorking(true);
    e.preventDefault();
    try {
      if (password != confirmPassword)
        throw new Error("Password confirmation does not match password.");
      await Auth.completeNewPassword(props.user, password);
      await API.post(CONSTANTS.API_NAME, "/user/init", { body: {} })
        .then(console.log)
        .catch(console.error);
    } catch (e: any) {
      toast({ description: e.message, status: "error" });
      setWorking(false);
    }
  }

  return (
    <>
      <Text mb={4}>Set a new password to continue.</Text>
      <form onSubmit={submitNewPassword}>
        <Stack spacing={4}>
          <PasswordInput
            placeholder={"New Password"}
            autoComplete={"new-password"}
            onChange={setValueFromEvent(setPassword)}
            onToggleShowPassword={setShowPassword}
          />
          <Input
            placeholder={"Confirm New Password"}
            type={showPassword ? "text" : "password"}
            autoComplete={"new-password"}
            onChange={setValueFromEvent(setConfirmPassword)}
          />
          {working ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Button type={"submit"} colorScheme={"silicon"}>
              Submit
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
}
