import DrawControl from "../../../components/maps/drawControl/DrawControl";
import React from "react";
import { useMapDrawStore } from "../../../state/mapDraw";

export default function DrawController() {
  const [isDrawing, featureType, features, updateFeatures] = useMapDrawStore(
    (state) => [
      state.isDrawing,
      state.featureType,
      state.features,
      state.setFeatures,
    ]
  );

  return isDrawing ? (
    <DrawControl
      options={{
        controls: {
          polygon: true,
          trash: true,
        },
      }}
      featureType={featureType}
      features={features}
      onChange={updateFeatures}
    />
  ) : null;
}
