import GeoJSON from "geojson";
import create from "zustand";
import createVanilla from "zustand/vanilla";

export type MapDrawFeatureType =
  | "FLIGHT_AREA"
  | "SCAN_AREA"
  | "OBSTACLE"
  | undefined;

interface MapDrawState {
  isDrawing: boolean;
  featureType: MapDrawFeatureType;
  features: GeoJSON.FeatureCollection | null;
  startDraw: (
    featureType?: MapDrawFeatureType,
    clearFeatures?: boolean
  ) => void;
  setFeatures: (featureData: GeoJSON.FeatureCollection | null) => void;
  endDraw: () => void;
}

export const mapDrawStore = createVanilla<MapDrawState>((set, getState) => ({
  isDrawing: false,
  features: null,
  featureType: undefined,
  startDraw: (featureType, clearFeatures) => {
    set({
      isDrawing: true,
      featureType: featureType || "FLIGHT_AREA",
      ...(clearFeatures && { features: null }),
    });
  },

  setFeatures: (features) => set({ features }),
  endDraw: () => set({ isDrawing: false }),
}));
export const useMapDrawStore = create(mapDrawStore);
