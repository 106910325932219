import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  BoxProps,
  Input,
  InputProps,
  Select,
  SelectProps,
  Text,
  Textarea,
  TextareaProps,
  TextProps,
  useToast,
} from "@chakra-ui/react";
import { setValueFromEvent } from "../../common/helpers";

interface LabeledEditableProps {
  value?: string;
  display?: (value?: string) => string;
  placeholder?: string;
  onFocus?: () => void;
  onSubmit?: (nextValue: string) => void;
  label?: string;
  disabled?: boolean;
  selectAll?: boolean;
  type?: "textarea" | "input" | "select" | "datetime" | "time";
  boxProps?: BoxProps;
  previewProps?: TextProps;
  inputProps?: InputProps;
  textareaProps?: TextareaProps;
  selectProps?: SelectProps;
  selectOptions?: Record<string, string>;
  selectOrder?: string[];
  startEditing?: boolean;
}

export default function Editable(props: LabeledEditableProps) {
  const toast = useToast();

  const [value, setValue] = useState(props.value);
  const [editValue, setEditValue] = useState(props.value);
  const [isEditing, setIsEditing] = useState(props.startEditing || false);

  const displayValue = props.display
    ? props.display(value)
    : props.type === "select"
    ? props.selectOptions?.[value || ""]
    : props.type === "datetime"
    ? value &&
      parseFloat(value) &&
      new Date(parseFloat(value) * 1000).toLocaleString([], {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
    : value;

  // Keep internal values synced with outside
  useEffect(() => {
    setValue(props.value);
    setEditValue(props.value);
  }, [props.value]);

  const makeDateInputValue = useCallback(() => {
    if (editValue && !isNaN(parseFloat(editValue))) {
      const date = new Date(parseFloat(editValue) * 1000);
      return `${date.getFullYear().toString().padStart(4, "0")}-${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
      // :${date.getSeconds().toString().padStart(2, "0")}`; // We don't have granularity to the second yet
    } else {
      return undefined;
    }
  }, [editValue]);

  function focus() {
    setIsEditing(true);
    if (props.onFocus) props.onFocus();
  }

  function handlePreviewClick() {
    if (!props.disabled) focus();
    // else {
    //   if (value !== undefined) {
    //     navigator.clipboard.writeText(value).then(() =>
    //       toast({
    //         description: "Copied to clipboard.",
    //         status: "info",
    //         duration: 2000,
    //       })
    //     );
    //   }
    // }
  }

  function handlePreviewKeyUp(e: any) {
    if (e.code === "Enter") {
      if (!isEditing) focus();
    }
  }

  async function handleSubmit(submitValue?: string) {
    setIsEditing(false);
    if (!submitValue) submitValue = editValue;
    if (submitValue !== value) {
      setValue(submitValue);
      if (props.onSubmit && submitValue !== undefined) {
        await props.onSubmit(submitValue);
      }
    }
  }

  function handleCancel() {
    setIsEditing(false);
    setEditValue(value);
  }

  function handleInputKeyUp(e: any) {
    if (e.code === "Enter") {
      handleSubmit();
    } else if (e.code === "Escape") {
      handleCancel();
    }
  }

  function handleTextareaKeyUp(e: any) {
    if (e.code === "Enter" && (e.shiftKey || e.ctrlKey)) {
      handleSubmit();
    } else if (e.code === "Escape") {
      handleCancel();
    }
  }

  function handleDateTimeChange(e: any) {
    const date = new Date(e.target.value);
    setEditValue(Math.round(date.getTime() / 1000).toString());
  }

  const commonProps = {
    placeholder: "",
    px: 4,
    py: 2,
    background: "blackAlpha.400",
    _dark: {
      background: "whiteAlpha.200",
    },
    value: editValue,
    onChange: setValueFromEvent(setEditValue),
    onKeyUp: handleInputKeyUp,
    onBlur: () => handleSubmit(),
    autoFocus: true,
  };

  return (
    <Box {...props.boxProps}>
      {props.label && (
        <Text mb={2}>
          <b>{props.label}</b>
        </Text>
      )}
      {isEditing ? (
        props.type === "textarea" ? (
          <Textarea
            {...commonProps}
            onKeyUp={handleTextareaKeyUp}
            {...props.textareaProps}
          />
        ) : props.type === "select" ? (
          <Select
            {...commonProps}
            border={"none"}
            px={0}
            py={0}
            onChange={(e) => handleSubmit(e.target.value)}
            {...props.selectProps}
          >
            <option value={""} disabled>
              {props.placeholder || "-"}
            </option>
            {props.selectOptions &&
              (
                props.selectOrder || Object.keys(props.selectOptions).sort()
              ).map((k) => (
                <option value={k} key={k}>
                  {props.selectOptions?.[k]}
                </option>
              ))}
          </Select>
        ) : props.type === "datetime" ? (
          <Input
            {...commonProps}
            value={makeDateInputValue()}
            onChange={handleDateTimeChange}
            type={"datetime-local"}
            {...props.inputProps}
          />
        ) : (
          <Input {...commonProps} {...props.inputProps} />
        )
      ) : props.type === "time" ? (
        <Input {...commonProps} type={"time"} {...props.inputProps} />
      ) : (
        <Text
          px={4}
          py={2}
          borderRadius={"md"}
          w={"full"}
          _after={{ content: `"\\200b"` }} // Fix height when empty
          background={"blackAlpha.400"}
          color={!displayValue ? "blackAlpha.500" : "inherit"}
          _hover={{ background: "blackAlpha.500" }}
          _disabled={{
            background: "none",
          }}
          _dark={{
            background: "whiteAlpha.200",
            color: !displayValue ? "whiteAlpha.500" : "inherit",
            _hover: {
              background: "whiteAlpha.300",
            },
            _disabled: {
              background: "none",
            },
          }}
          userSelect={props.selectAll ? "all" : "inherit"}
          aria-disabled={props.disabled}
          role={"button"}
          tabIndex={0}
          onFocus={handlePreviewClick}
          onClick={handlePreviewClick}
          onKeyUp={handlePreviewKeyUp}
          cursor={"pointer"}
          {...props.previewProps}
        >
          {displayValue || props.placeholder}
        </Text>
      )}
    </Box>
  );
}
