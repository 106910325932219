import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Code,
  Divider,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Map, { AttributionControl, NavigationControl } from "react-map-gl";
import ImageLoader from "../map/components/ImageLoader";
import MapDashParamsController from "../map/components/MapDashParamsController";
import SiteLayers from "../map/components/SiteLayers";
import MissionLayers from "../map/components/MissionLayers";
import DroneLayers from "../map/components/DroneLayers";
import { MapErrorBoundary } from "../map/components/MapErrorBoundary";
import { useDronesStore } from "../../state/drones";
import { useDocksStore } from "../../state/docks";
import { useDroneTelemetryStore } from "../../state/droneTelemetry";
import { useSitesStore } from "../../state/sites";
import { useNavigationShortcuts } from "../../common/navigation";
import ADSBLayers from "../map/components/ADSBLayers";
import { useMissionsStore } from "../../state/missions";
import { useUserStore } from "../../state/user";
import AuthChangeRedirector from "../../components/AuthChangeRedirector";
import MqttController from "../main/MqttController";
import DataController from "../map/components/DataController";
import {
  computeGroundSpeedKts,
  selectBatteryColor,
  selectGpsColor,
  selectStatusColor,
} from "../../common/helpers";
import { handleMessage, MQTT, QOS } from "../../common/mqtt";
import { RepeatIcon } from "@chakra-ui/icons";

const LEFT_HORIZONTAL_RANGE = [-200, 200];
const LEFT_VERTICAL_RANGE = [-200, 200];
const RIGHT_HORIZONTAL_RANGE = [-200, 200];
const RIGHT_VERTICAL_RANGE = [-200, 200];
const STICK_MS_TO_FULL = 1000;
const GIMBAL_RANGE = [-90, 35];
const GIMBAL_DELTA_PER_SECOND = 30;
const TICK_RATE = 100;
const MIN_PUBLISH_RATE = 500;

interface StickCommand {
  command: string;
  lh: number;
  lv: number;
  rh: number;
  rv: number;
  g?: number;
  r: boolean;
  ts: number;
}

interface CheckpointRequest {
  missionId: string;
  checkpointId: string;
  checkpointName: string;
  checkpointStatus: string;
  timestamp: number;
}

export default function Fly() {
  const toast = useToast();
  const params = useParams();
  const droneId = params.droneId;
  const shortcuts = useNavigationShortcuts();

  const userAttributes = useUserStore((state) => state.attributes);
  const [drones, fetchDrone] = useDronesStore((state) => [
    state.drones,
    state.fetchDrone,
  ]);
  const [docks, fetchDock] = useDocksStore((state) => [
    state.docks,
    state.fetchDock,
  ]);
  const [sites, fetchSite] = useSitesStore((state) => [
    state.sites,
    state.fetchSite,
  ]);
  const [missions, fetchMissions, fetchMission] = useMissionsStore((state) => [
    state.missions,
    state.fetchMissions,
    state.fetchMission,
  ]);
  const droneTelemetry = useDroneTelemetryStore(
    (state) => state.droneTelemetry
  );

  const [clientId, setClientId] = useState(0);
  const [orgId, setOrgId] = useState<string | undefined>();
  const [siteId, setSiteId] = useState<string | undefined>();
  const [username, setUsername] = useState<string | undefined>();
  const [telemetry, setTelemetry] = useState<DroneTelemetry | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [controlActive, setControlActive] = useState(false);
  const [currentKeyCodes, setCurrentKeyCodes] = useState<Set<string>>(
    new Set()
  );
  const [obstacleAvoidanceSetting, setObstacleAvoidanceSetting] = useState<
    boolean | null
  >(null);
  const [geofenceSetting, setGeofenceSetting] = useState<boolean | null>(null);
  const [leftHorizontal, setLeftHorizontal] = useState(0);
  const [leftVertical, setLeftVertical] = useState(0);
  const [rightHorizontal, setRightHorizontal] = useState(0);
  const [rightVertical, setRightVertical] = useState(0);
  const [gimbalPitch, setGimbalPitch] = useState(0);
  const [hasFocus, setHasFocus] = useState(false);
  const [abortMissionAlertOpen, setAbortMissionAlertOpen] = useState(false);
  const [checkAudioAlertOpen, setCheckAudioAlertOpen] = useState(true);
  const [diveState, setDiveState] = useState(3); // 3 = off, 2-1 = count, 0 = DIVE, -1 = sent dive command
  const [diveHoldStartTime, setDiveHoldStartTime] = useState(0);
  const [currentMission, setCurrentMission] = useState<Mission | null>(null);
  const [checkpointRequest, setCheckpointRequest] =
    useState<CheckpointRequest | null>(null);
  const [checkpointId, setCheckpointId] = useState("");
  const [isInSimulation, setIsInSimulation] = useState(false);
  const [isTakeoffImminent, setIsTakeoffImminent] = useState(false);
  const [isLanding, setIsLanding] = useState(false);
  const [isFault, setIsFault] = useState(false);
  const [pingToggle, setPingToggle] = useState(false);
  const [weather, setWeather] = useState<DockWeather | undefined>(undefined);
  const [retry, setRetry] = useState(0);

  const cancelAbortMissionRef = useRef<HTMLButtonElement>(null);
  const checkAudioRef = useRef<HTMLButtonElement>(null);

  let lastCommand: StickCommand | null = null;

  const diveWarningAudio = useMemo(
    () => new Audio("/assets/dive_warning.mp3"),
    []
  );
  const landingWarning = useMemo(
    () => new Audio("/assets/landing_warning.mp3"),
    []
  );

  function startDive() {
    if (diveState == 3) {
      console.log("START DIVE");
      setDiveState(2);
      setDiveHoldStartTime(Date.now());
    }
  }

  function cancelDive() {
    if (diveState > 0) {
      setDiveState(3);
      diveWarningAudio.pause();
    }
  }

  function tickDive() {
    const now = Date.now();
    if (diveState == 1 && now - diveHoldStartTime > 2000) {
      console.log("DIVE!!!");
      setControlActive(true);
      sendCommand("EMERGENCY_DIVE");
      setDiveState(0);
      setTimeout(() => setDiveState(3), 3000); // Reset
    } else if (diveState > 1 && diveState < 3) {
      const holdTime = now - diveHoldStartTime;
      if (2 - Math.floor(holdTime / 1000) != diveState) {
        setDiveState(2 - Math.floor(holdTime / 1000));
      }
    }
  }

  useEffect(() => {
    console.log("Dive state is now", diveState);
    let diveInterval: NodeJS.Timer | undefined = undefined;
    if (diveState > 0 && diveState < 3) {
      diveInterval = setInterval(tickDive, 200);
    } else {
      clearInterval(diveInterval);
    }
    return () => {
      clearInterval(diveInterval);
    };
  }, [diveState, diveHoldStartTime]);

  useEffect(() => {
    if (diveState < 3 && diveState > 0) {
      if (diveWarningAudio.paused) {
        diveWarningAudio.currentTime = 0;
        diveWarningAudio.play();
      }
    } else {
      diveWarningAudio.pause();
    }
    if (diveState == 0) {
      new Audio("/assets/dive_alarm.mp3").play();
    }
  }, [diveState]);

  async function sendCommand(command: string) {
    if (!orgId || !droneId) return;
    await MQTT?.publish(
      `${orgId}/drones/${droneId}/commands`,
      {
        command: command,
      },
      QOS.AtLeastOnce
    );
    toast({
      description: `Sent ${command} command.`,
      status: "info",
      duration: 2000,
    });
  }

  function handleKeyDown(e: KeyboardEvent) {
    if (!currentKeyCodes.has(e.code)) {
      currentKeyCodes.add(e.code);
      setCurrentKeyCodes(new Set([...currentKeyCodes]));
    }
  }

  function handleKeyUp(e: KeyboardEvent) {
    currentKeyCodes.delete(e.code);
    setCurrentKeyCodes(new Set([...currentKeyCodes]));
  }

  function doTick() {
    // W (forward) and S (backwards) are mutually exclusive.
    if (currentKeyCodes.has("KeyW") && !currentKeyCodes.has("KeyS")) {
      setRightVertical(
        Math.min(
          (rightVertical < 0 ? 0 : rightVertical) + // Immediately reset to zero on reverse
            RIGHT_VERTICAL_RANGE[1] / (STICK_MS_TO_FULL / TICK_RATE), // Ramp up to maximum in STICK_MS_TO_MAX milliseconds
          RIGHT_VERTICAL_RANGE[1] // Never exceed maximum
        )
      );
    } else if (currentKeyCodes.has("KeyS") && !currentKeyCodes.has("KeyW")) {
      setRightVertical(
        Math.max(
          (rightVertical > 0 ? 0 : rightVertical) + // Immediately reset to zero on reverse
            RIGHT_VERTICAL_RANGE[0] / (STICK_MS_TO_FULL / TICK_RATE), // Ramp up to maximum in STICK_MS_TO_MAX milliseconds
          RIGHT_VERTICAL_RANGE[0] // Never exceed minimum
        )
      );
    } else if (rightVertical != 0) {
      // Immediately reset to zero on release
      setRightVertical(0);
    }

    // A (pan left) and D (pan right) are mutually exclusive.
    if (currentKeyCodes.has("KeyD") && !currentKeyCodes.has("KeyA")) {
      setLeftHorizontal(
        Math.min(
          (leftHorizontal < 0 ? 0 : leftHorizontal) + // Immediately reset to zero on reverse
            LEFT_HORIZONTAL_RANGE[1] / (STICK_MS_TO_FULL / TICK_RATE), // Ramp up to maximum in STICK_MS_TO_MAX milliseconds
          LEFT_HORIZONTAL_RANGE[1] // Never exceed maximum
        )
      );
    } else if (currentKeyCodes.has("KeyA") && !currentKeyCodes.has("KeyD")) {
      setLeftHorizontal(
        Math.max(
          (leftHorizontal > 0 ? 0 : leftHorizontal) + // Immediately reset to zero on reverse
            LEFT_HORIZONTAL_RANGE[0] / (STICK_MS_TO_FULL / TICK_RATE), // Ramp up to maximum in STICK_MS_TO_MAX milliseconds
          LEFT_HORIZONTAL_RANGE[0] // Never exceed minimum
        )
      );
    } else if (leftHorizontal != 0) {
      // Immediately reset to zero on release
      setLeftHorizontal(0);
    }

    // ShiftLeft (up) and Control Left (down) are mutually exclusive.
    if (
      currentKeyCodes.has("ShiftLeft") &&
      !currentKeyCodes.has("ControlLeft")
    ) {
      setLeftVertical(
        Math.min(
          (leftVertical < 0 ? 0 : leftVertical) + // Immediately reset to zero on reverse
            LEFT_VERTICAL_RANGE[1] / (STICK_MS_TO_FULL / TICK_RATE), // Ramp up to maximum in STICK_MS_TO_MAX milliseconds
          LEFT_VERTICAL_RANGE[1] // Never exceed maximum
        )
      );
    } else if (
      currentKeyCodes.has("ControlLeft") &&
      !currentKeyCodes.has("ShiftLeft")
    ) {
      setLeftVertical(
        Math.max(
          (leftVertical > 0 ? 0 : leftVertical) + // Immediately reset to zero on reverse
            LEFT_VERTICAL_RANGE[0] / (STICK_MS_TO_FULL / TICK_RATE), // Ramp up to maximum in STICK_MS_TO_MAX milliseconds
          LEFT_VERTICAL_RANGE[0] // Never exceed minimum
        )
      );
    } else if (leftVertical != 0) {
      // Immediately reset to zero on release
      setLeftVertical(0);
    }

    // Q (strafe left) and E (strafe right) are mutually exclusive.
    if (currentKeyCodes.has("KeyE") && !currentKeyCodes.has("KeyQ")) {
      setRightHorizontal(
        Math.min(
          (rightHorizontal < 0 ? 0 : rightHorizontal) + // Immediately reset to zero on reverse
            RIGHT_HORIZONTAL_RANGE[1] / (STICK_MS_TO_FULL / TICK_RATE), // Ramp up to maximum in STICK_MS_TO_MAX milliseconds
          RIGHT_HORIZONTAL_RANGE[1] // Never exceed maximum
        )
      );
    } else if (currentKeyCodes.has("KeyQ") && !currentKeyCodes.has("KeyE")) {
      setRightHorizontal(
        Math.max(
          (rightHorizontal > 0 ? 0 : rightHorizontal) + // Immediately reset to zero on reverse
            RIGHT_HORIZONTAL_RANGE[0] / (STICK_MS_TO_FULL / TICK_RATE), // Ramp up to maximum in STICK_MS_TO_MAX milliseconds
          RIGHT_HORIZONTAL_RANGE[0] // Never exceed minimum
        )
      );
    } else if (rightHorizontal != 0) {
      // Immediately reset to zero on release
      setRightHorizontal(0);
    }

    // R (gimbal up), F (gimbal down), G (gimbal forward), V (gimbal nadir) are mutually exclusive.
    if (
      currentKeyCodes.has("KeyR") &&
      !currentKeyCodes.has("KeyF") &&
      !currentKeyCodes.has("KeyG") &&
      !currentKeyCodes.has("KeyV")
    ) {
      setGimbalPitch(
        Math.min(
          gimbalPitch + GIMBAL_DELTA_PER_SECOND * (TICK_RATE / 1000),
          GIMBAL_RANGE[1] // Never exceed maximum
        )
      );
    } else if (
      currentKeyCodes.has("KeyF") &&
      !currentKeyCodes.has("KeyR") &&
      !currentKeyCodes.has("KeyG") &&
      !currentKeyCodes.has("KeyV")
    ) {
      setGimbalPitch(
        Math.max(
          gimbalPitch - GIMBAL_DELTA_PER_SECOND * (TICK_RATE / 1000),
          GIMBAL_RANGE[0] // Never exceed minimum
        )
      );
    } else if (
      currentKeyCodes.has("KeyG") &&
      !currentKeyCodes.has("KeyV") &&
      !currentKeyCodes.has("KeyF") &&
      !currentKeyCodes.has("KeyR")
    ) {
      setGimbalPitch(0);
    } else if (
      currentKeyCodes.has("KeyV") &&
      !currentKeyCodes.has("KeyG") &&
      !currentKeyCodes.has("KeyF") &&
      !currentKeyCodes.has("KeyR")
    ) {
      setGimbalPitch(-90);
    }

    if (
      (!controlActive && leftHorizontal) ||
      leftVertical ||
      rightHorizontal ||
      rightVertical
    ) {
      setControlActive(true);
    }

    if (!controlActive) return; // Don't send command if not active

    if (orgId) {
      const command: any = {
        command: "STICK_COMMAND",
        lh: leftHorizontal,
        lv: leftVertical,
        rh: rightHorizontal,
        rv: rightVertical,
        g: gimbalPitch,
        r: false,
        ts: Date.now(),
      };

      // // Set gimbal field only upon change
      // if (
      //   lastCommand != null &&
      //   lastGimbalPitch != null &&
      //   gimbalPitch != lastGimbalPitch
      // ) {
      //   command.g = gimbalPitch;
      //   setLastGimbalPitch(gimbalPitch);
      // }

      if (
        lastCommand != null &&
        command.lh == lastCommand.lh &&
        command.lv == lastCommand.lv &&
        command.rh == lastCommand.rh &&
        command.rv == lastCommand.rv &&
        command.g == lastCommand.g &&
        command.ts - lastCommand.ts < MIN_PUBLISH_RATE
      ) {
        // Command has not changed and is not stale. Skip publish.
      } else {
        MQTT?.publish(
          `${orgId}/drones/${droneId}/commands`,
          command,
          QOS.AtMostOnce
        );
        lastCommand = command;
      }
    }
  }

  function endFlight() {
    setControlActive(false);
    setCurrentKeyCodes(new Set()); // Clear keys
    if (orgId) {
      MQTT?.publish(
        `${orgId}/drones/${droneId}/commands`,
        {
          command: "STICK_COMMAND",
          lh: 0,
          lv: 0,
          rh: 0,
          rv: 0,
          r: true,
          ts: Date.now(),
        },
        QOS.AtLeastOnce
      ).then(() => {
        toast({
          description: "Released control.",
          status: "info",
          duration: 2000,
        });
      });
    }
  }

  function handleFocus() {
    setHasFocus(document.hasFocus());
    if (!document.hasFocus()) {
      // Reset key codes when focus is lost
      setCurrentKeyCodes(new Set());
    }
  }

  function updateCurrentTime() {
    setCurrentTime(Date.now());
  }

  useEffect(() => {
    const newClientId = Math.floor(Math.random() * 1_000_000_000);
    console.log("Client ID is ", newClientId);
    setClientId(newClientId);
  }, []);

  useEffect(() => {
    if (checkAudioAlertOpen) {
      new Audio("/assets/checkpoint_chime.mp3").play();
    }
  }, [checkAudioAlertOpen]);

  let [controllerLatency, setControllerLatency] = useState(-1);

  let lastPingResTime = 0;
  let pingsWithoutRes = 0;

  function sendPing() {
    const now = Date.now();
    if (now - lastPingResTime > 10000) {
      setControllerLatency(-1);
      pingsWithoutRes++;
    } else {
      pingsWithoutRes = 0;
    }
    if (pingsWithoutRes > 5) {
      // Possibly a bad subscription. Try to resubscribe.
      setPingToggle(!pingToggle);
      pingsWithoutRes = 0;
    }
    MQTT?.publish(
      `${orgId}/drones/${droneId}/ping`,
      {
        timestamp: now,
        clientId: clientId,
      },
      QOS.AtLeastOnce
    );
  }

  let lastReturnedReceiveTimestamp = 0;

  function handlePingRes(payload: string) {
    const data = JSON.parse(payload);
    if (data.clientId != clientId) return;
    const receivedTimestamp = data.receivedTimestamp;
    if (receivedTimestamp <= lastReturnedReceiveTimestamp) {
      // There has been a more recent latency measurement, so ignore;
      // this ping is very delayed but the connection has since improved.
      return;
    }
    const now = Date.now();
    lastPingResTime = now;
    const latency = Math.round((now - receivedTimestamp) / 2);
    setControllerLatency(latency);
    lastReturnedReceiveTimestamp = receivedTimestamp;
    MQTT?.publish(
      `${orgId}/drones/${droneId}/pingFin`,
      {
        timestamp: now,
        receivedTimestamp: data.timestamp,
      },
      QOS.AtMostOnce
    );
  }

  useEffect(() => {
    if (orgId) {
      if (!MQTT) {
        // MQTT is not yet connected. Try again later.
        setTimeout(() => setRetry(retry + 1), 1_000);
      } else {
        console.log("Setting ping interval.", orgId);
        const interval = setInterval(sendPing, 1000);
        MQTT?.subscribe(
          `${orgId}/drones/${droneId}/pingRes`,
          QOS.AtMostOnce,
          handleMessage(({ payload }) => handlePingRes(payload))
        );
        // .then(() => console.log("Subscribed to PingRes"))
        // .catch((e) => console.error("Could not subscribe to PingRes:", e))
        return () => {
          clearInterval(interval);
          MQTT?.unsubscribe(`${orgId}/drones/${droneId}/pingRes`);
          // .then(() => console.log("Unsubscribed from PingRes."))
          // .catch((e) =>
          //   console.error("Could not unsubscribe from PingRes:", e)
          // )
        };
      }
    } else {
      console.log("Did not set interval.", orgId);
    }
  }, [orgId, pingToggle, retry]);

  useEffect(() => {
    // Setup event loop
    const tickInterval = setInterval(doTick, TICK_RATE);
    return () => {
      clearInterval(tickInterval);
    };
  });

  useEffect(() => {
    handleFocus();

    // Set up key listeners
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleFocus);
    const clockInterval = setInterval(updateCurrentTime, 200);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleFocus);
      clearInterval(clockInterval);
    };
  }, []);

  useEffect(() => {
    setUsername(userAttributes.email);
    setOrgId(userAttributes["custom:org_id"]);
  }, [userAttributes]);

  useEffect(() => {
    if (droneId) fetchDrone(droneId);
  }, [droneId]);

  useEffect(() => {
    if (droneId) {
      const now = Math.round(Date.now() / 1000);
      fetchMissions({
        before: now,
        after: now - 3_600, // 1 hour ago
      });
      if (drones?.[droneId]?.currentDock) {
        fetchDock(drones[droneId].currentDock);
      }
      if (drones?.[droneId]?.parentSite) {
        setSiteId(drones[droneId].parentSite);
        fetchSite(drones[droneId].parentSite);
      }

      setIsFault(drones?.[droneId]?.droneStatus === "FAULT");

      const statusReasons = drones?.[droneId]?.droneStatusReasons?.split(" ");
      if (statusReasons) {
        setIsInSimulation(statusReasons.includes("WARNING_IN_SIMULATION"));
        setIsTakeoffImminent(
          statusReasons.includes("WARNING_TAKEOFF_IMMINENT")
        );
        setIsLanding(statusReasons.includes("WARNING_LANDING"));
        if (statusReasons.includes("WARNING_REMOTE_CONTROL")) {
          setControlActive(true);
        }
      }
    }
  }, [drones]);

  useEffect(() => {
    if (isFault) {
      new Audio("/assets/fault.mp3").play();
    }
  }, [isFault]);

  useEffect(() => {
    if (droneId && droneTelemetry?.[droneId]) {
      setTelemetry(droneTelemetry[droneId]);
    }
  }, [droneTelemetry]);

  function respondToCheckpoint(pass: boolean) {
    MQTT?.publish(
      `${orgId}/missions/${checkpointRequest?.missionId}/checkpointResponse`,
      {
        checkpointId: checkpointRequest?.checkpointId,
        action: pass ? "PASS" : "ABORT",
        authority: username,
        timestamp: Date.now(),
      },
      QOS.AtLeastOnce
    );
    setCheckpointRequest(null);
    // Don't reset checkpointId in order to debounce chime. Will reset when DB responds.
  }

  useEffect(() => {
    if (missions && droneId) {
      let noMission = true;
      for (let mission of Object.values(missions)) {
        if (mission?.assignedDrone == droneId) {
          if (
            ["STARTING", "PREFLIGHT", "INFLIGHT", "POSTFLIGHT"].includes(
              mission.missionStatus
            )
          ) {
            setCurrentMission(mission);
            noMission = false;
            break;
          }
        }
      }
      if (noMission) setCurrentMission(null);
    }
  }, [missions]);

  useEffect(() => {
    if (currentMission) {
      // Get waiting checkpoints
      const waitingCheckpoints: CheckpointRequest[] = [];
      if (currentMission.checkpoints) {
        for (let checkpointData of currentMission.checkpoints) {
          if (checkpointData) {
            const checkpoint: CheckpointRequest = JSON.parse(checkpointData);
            if (checkpoint.checkpointStatus == "WAITING") {
              waitingCheckpoints.push(checkpoint);
            }
          }
        }
      }

      if (waitingCheckpoints.length > 0) {
        // There are waiting checkpoints
        // Sort the checkpoints so the oldest is first
        waitingCheckpoints.sort((a, b) => a.timestamp - b.timestamp);
        console.log(waitingCheckpoints);
        setCheckpointRequest({
          missionId: currentMission.missionId,
          checkpointId: waitingCheckpoints[0].checkpointId,
          checkpointName: waitingCheckpoints[0].checkpointName,
          checkpointStatus: waitingCheckpoints[0].checkpointStatus,
          timestamp: waitingCheckpoints[0].timestamp,
        });
        setCheckpointId(waitingCheckpoints[0].checkpointId);
      } else {
        setCheckpointRequest(null);
        setCheckpointId("");
      }
    }
  }, [currentMission]);

  useEffect(() => {
    // Play checkpoint chime
    if (checkpointId != "") {
      console.log(checkpointId);
      new Audio("/assets/checkpoint_chime.mp3").play();
    }
  }, [checkpointId]);

  useEffect(() => {
    if (isTakeoffImminent) {
      new Audio("/assets/takeoff_alarm.mp3").play();
    }
  }, [isTakeoffImminent]);

  useEffect(() => {
    if (isLanding) {
      landingWarning.loop = true;
      landingWarning.play();
    } else {
      landingWarning.pause();
      landingWarning.currentTime = 0;
    }
  });

  useEffect(() => {
    console.log("CHECKPOINT:", checkpointRequest);
  }, [checkpointRequest]);

  useEffect(() => {
    if (droneId && drones?.[droneId]?.currentDock) {
      const dockId = drones[droneId].currentDock;
      // Subscribe to weather
      const topic = `${orgId}/docks/${dockId}/weather`;
      MQTT?.subscribe(
        topic,
        QOS.AtMostOnce,
        handleMessage(({ payload }) => {
          const data = JSON.parse(payload);
          setWeather(data);
        })
      );
      // .then(() => console.log(`Subscribed to Dock ${props.dockId} Weather`))
      // .catch((e) =>
      //   console.error(
      //     `Could not subscribe to Dock ${props.dockId} Weather:`,
      //     e
      //   )
      // )

      function getWeather() {
        return MQTT?.publish(
          `${orgId}/docks/${dockId}/commands`,
          "REPORT_WEATHER",
          QOS.AtMostOnce
        );
      }

      const interval = setInterval(getWeather, 60_000);
      getWeather();
      return () => {
        clearInterval(interval);
      };
    }
  }, [drones, droneId]);

  useEffect(() => {
    if (controlActive) {
      new Audio("/assets/remote_control_chime.mp3").play();
    }
  }, [controlActive]);

  return (
    <Flex flex={1} flexDir={"column"} overflow={"hidden"} zIndex={0}>
      <AuthChangeRedirector />
      <MqttController />
      <DataController />

      {/* Simulation Banner */}
      {isInSimulation && (
        <Box bg={"yellow"} color={"black"} textAlign={"center"}>
          <Text>
            <b>DRONE IS IN SIMULATION</b>
          </Text>
        </Box>
      )}

      <Flex flex={1} flexDir={"row"} overflow={"hidden"}>
        <Flex
          fontSize={"sm"}
          textAlign={"left"}
          bg={hasFocus ? "transparent" : "darkred"}
          w={200}
          flexDir={"column"}
          overflow={"hidden"}
        >
          {/* Clock */}
          <Code p={2} bg={"black"} fontSize={"2xl"} textAlign={"center"}>
            <b>{new Date(currentTime).toLocaleTimeString()}</b>
          </Code>

          {/* Drone Title */}
          <Box bg={"whiteAlpha.300"} p={2}>
            <Heading as={"h1"} size={"md"}>
              Drone{" "}
              {droneId && drones?.[droneId]?.droneName
                ? drones[droneId].droneName
                : droneId}
            </Heading>
            <Text>
              {siteId && (
                <span>{sites?.[siteId].siteName || `Site ${siteId}`}</span>
              )}
            </Text>
          </Box>

          {/* Drone Status */}
          <Box
            p={2}
            bg={selectStatusColor(droneId && drones?.[droneId]?.droneStatus)}
            position={"relative"}
          >
            <IconButton
              position={"absolute"}
              top={0}
              right={0}
              size={"sm"}
              aria-label={"Report Status"}
              variant={"ghost"}
              icon={<RepeatIcon />}
              onClick={() => sendCommand("REPORT_STATUS")}
            />
            <Text>
              <b>
                Status:{" "}
                {(droneId && drones?.[droneId]?.droneStatus) || "No status."}
              </b>
              <br />
              {droneId &&
                drones?.[droneId]?.statusTimestamp &&
                new Date(
                  drones?.[droneId]?.statusTimestamp
                ).toLocaleString()}{" "}
            </Text>
          </Box>
          <Text p={2} fontSize={"xs"}>
            {droneId && drones?.[droneId]?.droneStatusReasons}
          </Text>

          {/* Telemetry */}
          <Divider borderColor={"whiteAlpha.500"} my={4} />
          <Text
            px={2}
            bg={
              controllerLatency < 0 || controllerLatency > 500
                ? "red"
                : "transparent"
            }
          >
            <b>Latency:</b> {controllerLatency} ms
          </Text>
          {telemetry ? (
            <>
              <Text px={2}>
                <b>Last update:</b>{" "}
                {new Date(telemetry.timestamp).toLocaleTimeString()}
              </Text>

              <Box flex={1} overflowY={"auto"}>
                <Text>
                  <br />
                </Text>
                <Text
                  px={2}
                  background={telemetry.motorsOn ? "red" : "darkgreen"}
                >
                  <b>Motors {telemetry.motorsOn ? "ON" : "OFF"}</b>
                </Text>
                <Text
                  px={2}
                  background={telemetry.isFlying ? "red" : "darkgreen"}
                >
                  <b>{telemetry.isFlying ? "AIRBORNE" : "Not flying"}</b>
                </Text>
                <Text
                  px={2}
                  background={selectBatteryColor(telemetry.batteryPercent)}
                >
                  <b>Battery Level: {telemetry.batteryPercent ?? "??"}%</b>
                </Text>
                {telemetry.rcBatteryPercent != null && ( // NB: Using weak inequality != to test undefined and null
                  <Text
                    px={2}
                    background={selectBatteryColor(telemetry.rcBatteryPercent)}
                  >
                    <b>RC Battery Level: {telemetry.rcBatteryPercent}%</b>
                  </Text>
                )}
                <Text px={2} bg={selectGpsColor(telemetry.gpsSignalLevel)}>
                  <b>GPS:</b> {telemetry.gpsSignalLevel ?? "??"} (
                  {telemetry.satelliteCount ?? "??"} sat){" "}
                </Text>
                <Text>
                  <br />
                </Text>
                <Text px={2}>
                  <b>Location:</b>
                  <br />
                  {telemetry.location?.latitude ?? "??"},
                  <br />
                  {telemetry.location?.longitude ?? "??"}
                  <br />
                  {telemetry.location?.altitude != null
                    ? (telemetry.location.altitude * 3.28084).toFixed(1)
                    : "??"}{" "}
                  ft
                </Text>
                <Text>
                  <br />
                </Text>
                <Text px={2}>
                  <b>Attitude:</b>
                </Text>
                <Text px={2}>
                  Heading: {telemetry.yaw?.toFixed(1) ?? "??"}&deg;
                </Text>
                <Text
                  px={2}
                  bg={
                    Math.abs(telemetry.pitch || 0) > 15 ? "red" : "transparent"
                  }
                >
                  Pitch: {telemetry.pitch?.toFixed(1) ?? "??"}&deg;
                </Text>
                <Text
                  px={2}
                  bg={
                    Math.abs(telemetry.roll || 0) > 15 ? "red" : "transparent"
                  }
                >
                  Roll: {telemetry.roll?.toFixed(1) ?? "??"}&deg;
                </Text>
                <Text>
                  <br />
                </Text>
                <Text px={2}>
                  <b>Ground Speed:</b>{" "}
                  {(() => {
                    const kts = computeGroundSpeedKts(telemetry);
                    return (
                      <span
                        style={{
                          background:
                            kts === null
                              ? "transparent"
                              : kts > 20
                              ? "red"
                              : kts > 10
                              ? "darkorange"
                              : "transparent",
                        }}
                      >
                        {kts?.toFixed(1) ?? "??"}kt
                      </span>
                    );
                  })()}
                </Text>
                <Text px={2}>
                  <b>Climb:</b>{" "}
                  <span>
                    {telemetry.vZ == null
                      ? "??"
                      : (-telemetry.vZ * 3.28084).toFixed(1)}
                    ft/s
                  </span>
                </Text>
                <Text px={2}>
                  <b>Air Speed:</b>{" "}
                  {telemetry.windSpeed == null
                    ? "??"
                    : (telemetry.windSpeed * 1.943844).toFixed(1)}
                  kt
                </Text>
                <Text px={2}>
                  <b>Air Dir:</b> {telemetry.windDirection ?? "??"}
                </Text>
              </Box>
            </>
          ) : (
            <Box flex={1} />
          )}

          {/* Current Mission */}
          {currentMission && (
            <>
              <Divider my={4} borderColor={"whiteAlpha.500"} />
              <Box p={2} mb={4}>
                <Text>
                  <b>Current Mission:</b>
                </Text>
                <Text fontSize={"xs"}>{currentMission.missionId}</Text>
              </Box>
            </>
          )}
        </Flex>

        <Flex flex={1} flexDir={"column"}>
          {/* Checkpoint Request */}
          <Box bg={hasFocus ? "transparent" : "darkred"} textAlign={"center"}>
            {checkpointRequest && (
              <Box m={2} p={2} background={"whiteAlpha.300"}>
                <Button
                  mx={4}
                  colorScheme={"green"}
                  onClick={() => respondToCheckpoint(true)}
                >
                  PASS
                </Button>
                <b>Checkpoint {checkpointRequest.checkpointName}</b>
                <Button
                  mx={4}
                  colorScheme={"red"}
                  onClick={() => respondToCheckpoint(false)}
                >
                  ABORT
                </Button>
              </Box>
            )}
          </Box>

          <MapErrorBoundary>
            {/*  TODO: Diagnose Map "get() on null" error */}
            <Map
              initialViewState={{
                longitude: -96,
                latitude: 38.5,
                zoom: 0,
              }}
              minZoom={2}
              maxZoom={23}
              style={{ flex: 1, zIndex: 0 }}
              mapStyle={"mapbox://styles/mapbox/satellite-streets-v12"}
              // mapStyle={"mapbox://styles/mapbox/streets-v12"}
              projection={"globe"} // Globe doesn't seem to play well with Drawing
              attributionControl={false}
              pitchWithRotate={false}
            >
              <ImageLoader
                images={{
                  home: "/assets/home_icon.png",
                  site: "/assets/site_icon.png",
                  obstacleFill: "/assets/obstacle_fill.png",
                  lineArrow: "/assets/arrow_line.png",
                  zigzagLine: "/assets/zigzag_line.png",
                  startInterval: "/assets/start_interval_capture_icon.png",
                  stopInterval: "/assets/stop_interval_capture_icon.png",
                  startVideo: "/assets/start_video_icon.png",
                  stopVideo: "/assets/stop_video_icon.png",
                  plane: "/assets/plane.png",
                }}
              />
              <MapDashParamsController />
              <NavigationControl
                position={"top-right"}
                showZoom={true}
                showCompass={true}
                visualizePitch={true}
              />
              <AttributionControl
                customAttribution={"&copy; Fliteworks"}
                compact={true}
                position={"bottom-left"}
                style={{ color: "black" }}
              />
              {/*<TrackCenterController*/}
              {/*  lat={telemetry?.location?.latitude}*/}
              {/*  lng={telemetry?.location?.longitude}*/}
              {/*/>*/}
              <SiteLayers />
              <MissionLayers />
              <DroneLayers />
              <ADSBLayers droneIds={droneId ? [droneId] : []} />
            </Map>
          </MapErrorBoundary>
        </Flex>

        <Box
          textAlign={"center"}
          bg={hasFocus ? "transparent" : "darkred"}
          overflowY={"auto"}
          w={200}
        >
          {/* Weather */}
          {weather && (
            <Box
              textAlign={"left"}
              fontSize={"sm"}
              bg={"whiteAlpha.300"}
              py={2}
            >
              <Text px={2}>
                <b>
                  Weather @ {new Date(weather.timestamp).toLocaleTimeString()}
                </b>
              </Text>
              <Text px={2}>
                <b>Wind:</b> {Math.round(weather.windSpeed * 1.943844)}|
                {Math.round(weather.windGustSpeed * 1.943844)} kt{" "}
                {weather.windDirection}&deg;
              </Text>
              <Text px={2}>
                <b>T:</b> {Math.round((weather.temperature * 9) / 5 + 32)}
                &deg;F <b>Pre:</b> {(weather.rainLevel * 0.03937008).toFixed(1)}
                "/hr
              </Text>
              <Text px={2}>
                <b>Hum:</b> {weather.humidity}% <b>Bar:</b>{" "}
                {(weather.barometer * 0.02953).toFixed(2)}"Hg
              </Text>
              <Text px={2}>
                <b>Est. Irr:</b> {Math.round(weather.brightness * 0.0079)} W/m2
              </Text>
            </Box>
          )}

          {/* Actions */}
          <Box>
            {!controlActive && (
              <Box m={2} my={4}>
                <Button
                  w={"full"}
                  colorScheme={"yellow"}
                  onClick={() => setControlActive(true)}
                >
                  Pause Mission
                </Button>
              </Box>
            )}
            {controlActive && (
              <Box m={2} my={4}>
                <Button colorScheme={"silicon"} onClick={endFlight} w={"full"}>
                  Release Control
                </Button>
              </Box>
            )}
            <Box m={2} my={4}>
              <Button w={"full"} onClick={() => setAbortMissionAlertOpen(true)}>
                Abort Mission
              </Button>
            </Box>
            <Box m={2} my={4}>
              <Button
                w={"full"}
                py={8}
                colorScheme={"red"}
                onMouseDown={startDive}
                onMouseUp={cancelDive}
              >
                {diveState == 3 ? (
                  <>&#8681; HOLD TO DIVE &#8681;</>
                ) : diveState == 2 ? (
                  <>HOLD TO DIVE (2)</>
                ) : diveState == 1 ? (
                  <>HOLD TO DIVE (1)</>
                ) : (
                  <>&#8681;&#8681;&#8681; DIVING!!! &#8681;&#8681;&#8681;</>
                )}
              </Button>
            </Box>
            {/*<Box>*/}
            {/*<Button*/}
            {/*  colorScheme={"red"}*/}
            {/*  onClick={() => sendCommand("EMERGENCY_STOP_MOTORS")}*/}
            {/*>*/}
            {/*  &#9762; STOP MOTORS &#9762;*/}
            {/*</Button>*/}
            {/*</Box>*/}
          </Box>

          {/* Controls */}
          <Box
            borderWidth={4}
            borderColor={controlActive ? "yellow" : "transparent"}
          >
            <SimpleGrid columns={3} spacing={2} p={2}>
              {/* First Row */}
              {/* === Strafe Left === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyQ")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&larr;</b>
                  <br />
                  [Q]
                </Text>
                <Text>
                  {rightHorizontal < 0 ? (
                    Math.round(
                      (rightHorizontal / RIGHT_VERTICAL_RANGE[0]) * 100
                    )
                  ) : (
                    <>&nbsp;</>
                  )}
                </Text>
              </Box>

              {/* === Forward === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyW")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&uarr;</b>
                  <br />
                  [W]
                </Text>
                <Text>
                  {rightVertical > 0 ? (
                    Math.round((rightVertical / RIGHT_VERTICAL_RANGE[1]) * 100)
                  ) : (
                    <>&nbsp;</>
                  )}
                </Text>
              </Box>

              {/* === Strafe Right === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyE")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&rarr;</b>
                  <br />
                  [E]
                </Text>
                <Text>
                  {rightHorizontal > 0 ? (
                    Math.round(
                      (rightHorizontal / RIGHT_VERTICAL_RANGE[1]) * 100
                    )
                  ) : (
                    <>&nbsp;</>
                  )}
                </Text>
              </Box>

              {/* Second Row */}
              {/* === Left Turn === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyA")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&#8634;</b>
                  <br />
                  [A]
                </Text>
                <Text>
                  {leftHorizontal < 0 ? (
                    Math.round(
                      (leftHorizontal / LEFT_HORIZONTAL_RANGE[0]) * 100
                    )
                  ) : (
                    <>&nbsp;</>
                  )}
                </Text>
              </Box>

              {/* === Back === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyS")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&darr;</b>
                  <br />
                  [S]
                </Text>
                <Text>
                  {rightVertical < 0 ? (
                    Math.round((rightVertical / RIGHT_VERTICAL_RANGE[0]) * 100)
                  ) : (
                    <>&nbsp;</>
                  )}
                </Text>
              </Box>

              {/* === Right Turn === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyD")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&#8635;</b>
                  <br />
                  [D]
                </Text>
                <Text>
                  {leftHorizontal > 0 ? (
                    Math.round(
                      (leftHorizontal / LEFT_HORIZONTAL_RANGE[1]) * 100
                    )
                  ) : (
                    <>&nbsp;</>
                  )}
                </Text>
              </Box>

              {/* Third Row */}
              {/* === Up === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("ShiftLeft")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&#8613;</b>
                  <br />
                  [LS]
                </Text>
                <Text>
                  {leftVertical > 0 ? (
                    Math.round((leftVertical / LEFT_VERTICAL_RANGE[1]) * 100)
                  ) : (
                    <>&nbsp;</>
                  )}
                </Text>
              </Box>

              {/* === Raise Gimbal === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyR")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&#x27B6;</b>
                  <br />
                  [R]
                </Text>
              </Box>

              {/*  === Gimbal Forward === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyG")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&#x27b5;</b>
                  <br />
                  [G]
                </Text>
              </Box>

              {/*  Fourth Row */}
              {/* === Down === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("ControlLeft")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&#8615;</b>
                  <br />
                  [LC]
                </Text>
                <Text>
                  {leftVertical < 0 ? (
                    Math.round((leftVertical / LEFT_VERTICAL_RANGE[0]) * 100)
                  ) : (
                    <>&nbsp;</>
                  )}
                </Text>
              </Box>

              {/* === Lower Gimbal === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyF")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>&#x27b4;</b>
                  <br />
                  [F]
                </Text>
              </Box>

              {/* === Gimbal Down === */}
              <Box
                p={2}
                borderWidth={1}
                borderColor={"white"}
                textAlign={"center"}
                bg={
                  currentKeyCodes.has("KeyV")
                    ? "rgba(255,255,255,0.3)"
                    : "transparent"
                }
              >
                <Text>
                  <b>
                    <span
                      style={{
                        transform: "rotate(90deg)",
                        display: "inline-block",
                      }}
                    >
                      &#x27b5;
                    </span>
                  </b>
                  <br />
                  [V]
                </Text>
              </Box>
            </SimpleGrid>
            {/* Control Actions */}
            {controlActive && (
              <Box>
                <Box m={2} my={4}>
                  <Button w={"full"} colorScheme={"red"}>
                    Force Takeoff
                  </Button>
                </Box>
                <Box m={2} my={4}>
                  <Button w={"full"} colorScheme={"silicon"}>
                    Start Prec Land
                  </Button>
                </Box>
                <Box m={2} my={4}>
                  <Button w={"full"} colorScheme={"red"}>
                    Force Land
                  </Button>
                </Box>
                {droneId &&
                drones?.[droneId].droneStatusReasons
                  ?.split(" ")
                  .includes("WARNING_NO_GEOFENCE") ? (
                  <Box m={2} my={4}>
                    <Button
                      w={"full"}
                      colorScheme={"green"}
                      onClick={() => setGeofenceSetting(true)}
                    >
                      Enable Geofence
                    </Button>
                  </Box>
                ) : (
                  <Box m={2} my={4}>
                    <Button
                      w={"full"}
                      colorScheme={"red"}
                      onClick={() => setGeofenceSetting(false)}
                    >
                      Disable Geofence
                    </Button>
                  </Box>
                )}
                {droneId &&
                drones?.[droneId].droneStatusReasons
                  ?.split(" ")
                  .includes("WARNING_NO_AVOIDANCE") ? (
                  <Box m={2} my={4}>
                    <Button
                      w={"full"}
                      colorScheme={"green"}
                      onClick={() => setObstacleAvoidanceSetting(true)}
                    >
                      Enable Avoidance
                    </Button>
                  </Box>
                ) : (
                  <Box m={2} my={4}>
                    <Button
                      w={"full"}
                      colorScheme={"red"}
                      onClick={() => setObstacleAvoidanceSetting(false)}
                    >
                      Disable Avoidance
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
      <AlertDialog
        isOpen={abortMissionAlertOpen}
        leastDestructiveRef={cancelAbortMissionRef}
        onClose={() => setAbortMissionAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent background={"steel.800"}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Abort Mission
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelAbortMissionRef}
                onClick={() => setAbortMissionAlertOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setAbortMissionAlertOpen(false);
                  sendCommand("ABORT_MISSION");
                }}
                ml={3}
              >
                Abort
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={checkAudioAlertOpen}
        leastDestructiveRef={checkAudioRef}
        onClose={
          () => null // Do not allow clickout
        }
      >
        <AlertDialogOverlay>
          <AlertDialogContent background={"steel.800"}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Check Audio
            </AlertDialogHeader>

            <AlertDialogBody>
              Confirm that sound is on and alerts are audible.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={checkAudioRef}
                onClick={() => new Audio("/assets/checkpoint_chime.mp3").play()}
              >
                Test audio
              </Button>
              <Button
                colorScheme="silicon"
                onClick={() => setCheckAudioAlertOpen(false)}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}
