// A minimal in-house mapbox geocoder implementation
// For a more featureful example: https://github.com/visgl/react-map-gl/blob/7.0-release/examples/geocoder/src/geocoder-control.tsx

import { ControlPosition, useControl } from "react-map-gl";
import MapboxGeocoder, {
  GeocoderOptions,
  Result,
} from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl";

/* Given a query in the form "lng, lat" or "lat, lng"
 * returns the matching geographic coordinate(s)
 * as search results in carmen geojson format,
 * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
function coordinatesGeocoder(query: string): Result[] {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
    /^\s*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)\s*$/i
  );
  if (!matches) {
    return [];
  }

  function coordinateFeature(lng: number, lat: number): Result {
    return {
      center: [lng, lat],
      geometry: {
        type: "Point",
        coordinates: [lng, lat],
      },
      place_name: "Lat: " + lat + " Lng: " + lng,
      place_type: ["coordinate"],
      properties: {},
      type: "Feature",
      bbox: [lng, lat, lng, lat],
      relevance: 0,
      text: "",
      address: "",
      context: [],
    };
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];

  // Create location search results
  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }
  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }
  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
}

interface GeocoderControlProps {
  options?: Partial<GeocoderOptions>;
  position?: ControlPosition;
}

export default function GeocoderControl(props: GeocoderControlProps) {
  useControl<MapboxGeocoder>(
    () =>
      new MapboxGeocoder({
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "",
        localGeocoder: coordinatesGeocoder,
        flyTo: { duration: 0 },
        // mapboxgl: mapboxgl,
        marker: false,
        ...props.options,
      }),
    { position: props.position }
  );

  return null;
}

GeocoderControl.defaultProps = {
  position: "top-left",
};
