import { API } from "aws-amplify";
import create from "zustand";
import createVanilla from "zustand/vanilla";
import { CONSTANTS } from "../common/constants";

interface OrgState {
  org: Organization | null;
  // setOrg: (org: Organization) => void;
  fetchOrg: () => Promise<void>;
}

export const orgStore = createVanilla<OrgState>((set) => ({
  org: null,
  // setOrg: (org) => set({ org }),
  fetchOrg: async () => {
    const org = await API.get(CONSTANTS.API_NAME, "/org", {}).then(
      (r) => r.org
    );
    set({ org });
  },
}));
export const useOrgStore = create(orgStore);
