import { Amplify } from "aws-amplify";
import { CONSTANTS } from "./constants";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: CONSTANTS.API_NAME,
        endpoint: process.env.REACT_APP_AWS_API_ENDPOINT,
      },
    ],
  },
});
